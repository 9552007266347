<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
        <!-- first modal -->
        <div class="modal-list-third-party-app">
            <div v-if="showLinkForm == false"
                class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                <div v-if="isEditScreen" class="d-flex justify-content-between align-items-center">
                    <span class="label-app-list-header">Edit Module</span>
                </div>

                <div v-else class="d-flex justify-content-between align-items-center">
                    <div v-if="!showAppListing" @click="showPage('FUNCTION_LISTING')">
                        <button class="text-button no-bg pointer">
                            <span class="material-icons-outlined">arrow_back</span>
                        </button>
                    </div>
                    <span class="label-app-list-header">Add New Module</span>
                </div>

                <div v-if="this.clickedProfileFunction == 'Dashboard Page' && !isEditSubModule"
                    class="d-flex justify-content-between align-items-center">
                    <div @click="showPage('ADD_SUBMODULE')">
                        <button v-if="isEditScreen" type="button" class="no-bg text-button btn-add-new-app"
                            style="margin-right: 2rem">
                            Add Sub Module
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-list-third-party-app" v-if="showLinkForm == true">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <div @click="showPage('FUNCTION_LISTING')">
                            <button class="text-button no-bg pointer">
                                <span class="material-icons-outlined">arrow_back</span>
                            </button>
                        </div>
                        <span class="label-app-list-header">Add link in Sub-Module</span>
                    </div>
                </div>
            </div>
            <template v-if="!isEditSubModule && !showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="subModulesList.length > 0">
                        <div v-for="subModule in subModulesList" :key="subModule.id"
                            class="d-flex justify-content-between align-items-center">
                            <span class="label-app-list-header"
                                style="text-overflow: ellipsis; text-wrap: nowrap; overflow: hidden">{{ subModule.title
                                }}</span>
                            <div class="d-flex">
                                <ejs-tooltip v-if="!subModule.allow3rdParty" class="tooltipcontainer" content="AddLink"
                                    target="#target" cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-edit me-2" role="button"
                                        @click="addSubModuleLink(subModule.id)">
                                        add_link
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-edit me-2" role="button"
                                        @click="editSubModule(subModule, subModule.id)">
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-delete" role="button"
                                        @click="deleteSubModule(subModule.id)">
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-if="!showMainForm && showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="subModuleLinksList.length > 0">
                        <div v-for="subModulelink in subModuleLinksList" :key="subModulelink.id"
                            class="d-flex justify-content-between align-items-center">
                            <span class="label-app-list-header"
                                style="text-overflow: ellipsis; text-wrap: nowrap; overflow: hidden">{{
        subModulelink.title }}</span>
                            <div class="d-flex">
                                <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-edit me-2" role="button"
                                        @click="editSubModuleLink(subModulelink, subModulelink.id)">
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-delete" role="button"
                                        @click="deleteSubModuleLink(subModulelink.id)">
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <hr v-if="!showLoader && subModulesList.length && !isEditSubModule" />
        </div>
        <!-- second modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showMainForm == true">
                        <div class="section-body add-new-app-form mx-0">
                            <div class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3">
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>

                                <div class="d-flex align-items-center">
                                    <button type="button" class="no-bg text-button btn-add-new-app"
                                        style="margin-right: 3px; width: 20rem" @click="chooseFile">
                                        Choose file
                                    </button>
                                    <!-- <span v-if="isFileAvailable" class="errorClass">Please upload file</span> -->
                                    <input id="fileid" type="file" name="upload" ref="upload" size="100000"
                                        @change="uploadFile" hidden required
                                        :accept="applicationFrame == 'kiosk' ? '.svg' : '.png, .jpg, .jpeg'" />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <svg v-if="!uploadedImg && !showImgLoader" width="20" height="35"
                                            viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.19815 8.79779H59.9507C60.833 8.79779 61.6211 9.15091 62.1943 9.71533C62.7663 10.2798 63.1251 11.08 63.1251 11.9263V51.8709C63.1251 52.7412 62.7663 53.5175 62.1943 54.0825C62.1469 54.1292 62.0984 54.1764 62.0273 54.2231C61.4778 54.7175 60.7388 54.9994 59.927 54.9994H3.17446C2.29157 54.9994 1.50345 54.6469 0.930843 54.0825C0.358239 53.518 0 52.7184 0 51.8715V11.9269C0 11.0566 0.358239 10.2803 0.930843 9.7159C1.50345 9.15148 2.31526 8.79836 3.17446 8.79836H3.19815V8.79779ZM16.3045 19.6431C18.8202 19.6431 20.8587 21.6525 20.8587 24.1323C20.8587 26.6121 18.8202 28.622 16.3045 28.622C13.7887 28.622 11.7502 26.6121 11.7502 24.1323C11.7496 21.653 13.7887 19.6431 16.3045 19.6431ZM35.4708 38.9092L44.5805 23.3845L54.2575 47.5122L9.09348 47.5116V44.5192L12.8897 44.333L16.6841 35.1672L18.5805 41.7148H24.2747L29.2074 29.1824L35.4708 38.9092ZM12.6487 3.71629C11.5988 3.71629 10.7633 2.86995 10.7633 1.85786C10.7633 0.822995 11.6225 0 12.6487 0H69.1152C70.1645 0 71 0.846916 71 1.85843V41.0974C71 42.1328 70.1414 42.9564 69.1152 42.9564C68.0648 42.9564 67.2292 42.1094 67.2292 41.0974V3.74021H12.6487V3.71629ZM59.3538 12.5152H3.79444V51.2598H59.3532L59.3538 12.5152Z"
                                                fill="black" />
                                        </svg>

                                        <img v-if="uploadedImg && !showImgLoader && applicationFrame === 'kiosk'"
                                            :src="svgToBase64" alt="image not found" />
                                        <img v-if="uploadedImg && !showImgLoader && applicationFrame !== 'kiosk'"
                                            :src="uploadedImg" alt="image not uploaded" />

                                        <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main">
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                            cssClass="customtooltip" position="top">
                                            <span id="target" class="material-symbols-outlined img-delete-small"
                                                role="button" @click="resetFileUpload()">
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div class="scroll-wrapper">
                                <div style="gap: 25px" class="scroll-container">
                                    <div v-for="bg in genericModulePhotos" :key="bg.image">
                                        <input type="radio" hidden />
                                        <label>
                                            <img :src="bg.image"
                                                style="width: 50px; height: 50px; max-width: 100px; cursor: pointer"
                                                :class="bg.file_id == backgroundPhoto.file_id ? 'active-bg' : ''"
                                                @click="selectBackground(bg)" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="applicationFrame != 'kiosk'" class="mx-5">
                                <div class="mb-4 d-flex w-100">
                                    <Input label="Select Button Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color" class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1" name="custom-color"
                                        @change="selectCustomColor($event)" v-model="functionInfo.color" />

                                    <Input label="Select Text Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color" class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1" name="custom-text-color"
                                        @change="selectCustomTextColor($event)" v-model="functionInfo.textColor" />
                                </div>
                            </div>
                            <div v-if="applicationFrame == 'kiosk'" class="d-flex flex-wrap px-4 my-4"
                                style="flex-direction: column; gap: 5px">
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input type="radio" name="color_type_kiosk" id="primary_colors"
                                                value="primary_colors" style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)" />
                                            <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div v-for="(value, index) in colorScheme.primary_colors"
                                                class="d-flex align-items-center px-2 w-25" :key="index" :style="{
        height: '30px',
        width: '50%',
        background: value,
        color: '#FFFFFF',
        'font-size': '12px',
        border: '2px solid black',
        'text-transform': 'uppercase',
    }">
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input type="radio" name="color_type_kiosk" id="secondary_colors"
                                                value="secondary_colors" style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)" />
                                            <label for="option2" style="font-weight: bold">Secondary Color
                                                Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div v-for="(value, index) in colorScheme.secondary_colors"
                                                class="d-flex align-items-center px-2 w-25" :key="index" :style="{
        height: '30px',
        width: '50%',
        background: value,
        color: '#FFFFFF',
        'font-size': '12px',
        border: '2px solid black',
        'text-transform': 'uppercase',
    }">
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input type="radio" name="color_type_kiosk" id="customized_colors"
                                                value="customized_colors" style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)" />
                                            <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div v-for="(value, index) in colorScheme.customized_colors"
                                                class="d-flex align-items-center px-2 w-25" :key="index" :style="{
        height: '30px',
        width: '50%',
        background: value,
        color: '#FFFFFF',
        'font-size': '12px',
        border: '2px solid black',
        'text-transform': 'uppercase',
    }">
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input type="radio" name="color_type_kiosk" id="module_customized_colors"
                                                value="module_customized_colors" style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event)" />
                                            <label for="option2" style="font-weight: bold">Custom Module Color
                                                Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div v-for="(value, index) in colorScheme.module_customized_colors"
                                                class="d-flex align-items-center px-2 w-25" :key="index">
                                                <input v-if="functionInfo.selectedSchemeOption == 'module_customized_colors'
        " type="color" name="" id="" v-model="colorScheme.module_customized_colors[index]"
                                                    @change="selectCustomColorScheme($event, index)"
                                                    class="w-100 p-0" />
                                                <div v-else class="default-color"> </div>

                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.clickedProfileFunction != 'Lumeca'" class="mx-5 px-3">
                                <Input label="Title" labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text" class="field-container d-flex align-items-center mb-4" name="title"
                                    placeholder="Enter Title" v-model="functionInfo.title" required />

                                <div v-if="clickedProfileFunction == 'Call Button'" class="lumeca-form">
                                    <Input label="Url"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                        class="field-container d-flex align-items-center mt-2 mb-4" name="url"
                                        placeholder="Enter URL" v-model="functionInfo.url" required />

                                    <Input label="Phone number"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="number" class="w-100 field-container d-flex align-items-center mb-4"
                                        name="Phone number" placeholder="Enter Phone number" rules="mobile"
                                        v-model="phoneNumber" required />

                                    <span class="d-flex align-items-center my-4">
                                        <input type="checkbox" id="enabledtms"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="functionInfo.enabledtms" @input="handleDTMS($event)" />
                                        <label class="margin-0 font-16 d-flex align-items-center"
                                            style="font-weight: 600" for="enabledtms">Enable DTMS Settings</label>
                                    </span>

                                    <template v-if="functionInfo.enabledtms">
                                        <div class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                            <Input label="" type="number" rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay" placeholder="Enter Delay*" v-model="dtmsDelay" />
                                            <Input label="" type="number" rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit" placeholder="Enter Digit*" v-model="dtmsDigit" />
                                            <ejs-tooltip class="tooltipcontainer" content="Add" target="#target"
                                                cssClass="customtooltip" position="BottomRight">
                                                <span id="target" class="material-symbols-outlined" style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    " @click="addDtmsSettings">
                                                    add
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                        <div v-for="(value, index) in functionInfo.dtmsSettings" :key="index"
                                            class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                            <Input label="" type="number" rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay" placeholder="Enter Delay*" v-model="value.delay"
                                                required />
                                            <Input label="" type="number" rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit" placeholder="Enter Digit*" v-model="value.digit"
                                                required />
                                            <ejs-tooltip class="tooltipcontainer" content="Remove" target="#target"
                                                cssClass="customtooltip" position="BottomRight">
                                                <span id="target" class="material-symbols-outlined" style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    " @click="removeDtmsSettings(index)">
                                                    remove
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                    </template>
                                </div>

                                <Input v-if="this.clickedProfileFunction == 'Web Link'" label="Web Url"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4" name="url"
                                    placeholder="Enter URL" v-model="functionInfo.url" />
                                <Input v-if="this.clickedProfileFunction == 'Video Link'" label="Video Link"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4" name="url"
                                    placeholder="Enter URL" v-model="functionInfo.url" />

                                <span v-if="this.clickedProfileFunction == 'Dashboard'"
                                    class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end">Select Company*</label>
                                    <Multiselect id="company" track-by="id" label="embeddedCompanyName"
                                        class="position-relative w-70" placeholder="Search Company..."
                                        :value="functionInfo" :options="companyList" :searchable="true"
                                        :allow-empty="false" :show-labels="false"
                                        @input="handleCompanySelection($event)" />
                                </span>
                                <span v-if="this.clickedProfileFunction == 'Dashboard'"
                                    class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end">Select Dashboard*</label>
                                    <Multiselect :options="companyDashboardList" class="position-relative w-70"
                                        :allow-empty="false" :searchable="true" :show-labels="false"
                                        :placeholder="'Dashboards *'" label="embeddedDashboardName" track-by="id"
                                        :value="functionInfo" :disabled="!functionInfo.embeddedCompanyName"
                                        @input="handleDashboardSelection($event)" />
                                </span>
                                <Input v-if="this.clickedProfileFunction == 'PDF Link'" label="PDF Link"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4" name="url"
                                    placeholder="Enter URL" v-model="functionInfo.url" />
                                <div class="d-flex justify-content-end mb-4" v-if="this.clickedProfileFunction == 'PDF Link' &&
        applicationFrame != 'mobex-health-hub'
        ">
                                    <label class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end; align-items: center">Upload
                                        PDF</label>
                                    <input type="file" name="upload_pdf" id="upload_pdf" hidden accept=".pdf"
                                        @change="uploadPDF($event)" />
                                    <label for="upload_pdf"
                                        class="w-70 m-0 d-flex justify-content-between align-items-center upload-section">
                                        <span style="font-size: 14px; margin: 0 0 0 5%">Upload PDF</span>
                                        <span v-if="pdfLoading" class="upload-btn px-5 py-3" style="
                                                display: flex;
                                                gap: 10px;
                                                padding-left: 15px !important;
                                                padding-right: 15px !important;
                                                align-items: center;
                                            ">
                                            <SpinLoader />Uploading
                                        </span>
                                        <span v-else class="upload-btn px-5 py-3">Upload</span>
                                    </label>
                                </div>
                                <div class="d-flex justify-content-end mb-4" v-if="this.clickedProfileFunction == 'Video Link' &&
        applicationFrame != 'mobex-health-hub'
        ">
                                    <label class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end; align-items: center">Upload
                                        Video</label>
                                    <input type="file" name="upload_video" id="upload_video" hidden accept=".mp4, .webm"
                                        @change="uploadVideo($event)" />
                                    <label for="upload_video"
                                        class="w-70 m-0 d-flex justify-content-between align-items-center upload-section">
                                        <span style="font-size: 14px; margin: 0 0 0 5%">Upload Video</span>
                                        <span v-if="videoLoading" class="upload-btn px-5 py-3" style="
                                                display: flex;
                                                gap: 10px;
                                                padding-left: 15px !important;
                                                padding-right: 15px !important;
                                                align-items: center;
                                            ">
                                            <SpinLoader />Uploading
                                        </span>
                                        <span v-else class="upload-btn px-5 py-3">Upload</span>
                                    </label>
                                </div>
                                <div class="checkbox-container" v-if="this.applicationFrame != 'star-kid'">
                                    <span v-if="this.clickedProfileFunction != 'Dashboard Page' &&
        this.clickedProfileFunction != 'Dashboard' &&
        this.applicationFrame == 'kiosk'
        " class="d-flex align-items-center" style="color: grey">
                                        <input type="checkbox" id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!functionInfo.enableQR"
                                            @input="functionInfo.enableQR = $event.target.checked" />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR">Show QR
                                            Code</label>
                                    </span>
                                    <span v-if="this.clickedProfileFunction != 'Dashboard Page' &&
        this.clickedProfileFunction != 'Dashboard' &&
        this.clickedProfileFunction != 'Call Button'
        " class="d-flex align-items-center" style="color: grey">
                                        <input type="checkbox" id="hideSensitiveInformation"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!functionInfo.isSensitive"
                                            @input="functionInfo.isSensitive = $event.target.checked" />
                                        <label class="margin-0 font-14 d-flex align-items-center"
                                            for="hideSensitiveInformation">Information is
                                            sensitive</label>
                                    </span>
                                </div>
                            </div>
                            <div v-if="this.clickedProfileFunction == 'Lumeca'"
                                class="section-body add-new-app-form lumeca-form mx-0">
                                <div class="mx-5 px-3">
                                    <Input label="Meeting Title"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                        class="field-container d-flex align-items-center mb-4" name="title"
                                        placeholder="Enter Title here" v-model="functionInfo.lumecatitle" required />
                                    <Input label="Location"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                        class="field-container d-flex align-items-center mb-4" name="location"
                                        placeholder="Enter location here" v-model="functionInfo.location" required />
                                    <div class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                        <Input label="Phone number"
                                            labelClass="w-45 me-3 d-flex justify-content-end add-new-app-label"
                                            type="number" class="w-100 field-container d-flex align-items-center mb-4"
                                            name="Phone" rules="phone" placeholder="Enter Phone number"
                                            v-model="phoneNumber" :required="!functionInfo.phoneNumbers.length" />
                                        <ejs-tooltip class="tooltipcontainer" content="Add" target="#target"
                                            cssClass="customtooltip" position="BottomRight">
                                            <span id="target" class="material-symbols-outlined" style="
                                                    background: #5057c3;
                                                    height: fit-content;
                                                    border-radius: 50%;
                                                    color: white;
                                                    margin-top: 8px;
                                                    cursor: pointer;
                                                " @click="addPhoneNumber">
                                                add
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                    <hr />
                                    <template>
                                        <div class="section-body content-checkbox-list">
                                            <h2>Phone Numbers</h2>
                                            <div v-if="!functionInfo.phoneNumbers.length" style="height: 100px"
                                                class="d-flex justify-content-center align-items-center">
                                                <h3>No phone numbers Added</h3>
                                            </div>
                                            <div class="show-phone-number-list" v-if="functionInfo.phoneNumbers.length">
                                                <div v-for="(phoneNumber, index) in functionInfo.phoneNumbers"
                                                    :key="index"
                                                    class="d-flex justify-content-between align-items-center mx-2">
                                                    <span style="
                                                            text-overflow: ellipsis;
                                                            text-wrap: nowrap;
                                                            overflow: hidden;
                                                        ">{{ phoneNumber }}</span>
                                                    <div class="d-flex">
                                                        <ejs-tooltip class="tooltipcontainer" content="Edit"
                                                            target="#target" cssClass="customtooltip"
                                                            position="BottomRight">
                                                            <span id="target"
                                                                class="material-symbols-outlined icon-edit me-2"
                                                                role="button" @click="editPhoneNumber(index)">
                                                                edit_square
                                                            </span>
                                                        </ejs-tooltip>
                                                        <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                            target="#target" cssClass="customtooltip"
                                                            position="BottomRight">
                                                            <span id="target"
                                                                class="material-symbols-outlined icon-delete"
                                                                role="button" @click="deletePhoneNumber(index)">
                                                                delete
                                                            </span>
                                                        </ejs-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button v-if="!isEditScreen" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="addNewApplication">
                                    Save
                                </button>
                                <button v-if="isEditScreen" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="updateApplication(currentModuleId)">
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4" @click="resetAll()">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
        <!-- third modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formlink">
                <form style="box-shadow: none">
                    <template v-if="showLinkForm == true && !showMainForm">
                        <div class="section-body add-new-app-form">
                            <Input label="Title" labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                type="text" class="field-container d-flex align-items-center mb-4" name="title"
                                placeholder="Enter Title here" v-model="submodulelink.title" required />
                            <Input label="Url" labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                type="text" class="field-container d-flex align-items-center mt-2 mb-4" name="url"
                                placeholder="Enter URL" v-model="submodulelink.url" />
                            <div class="checkbox-container" v-if="this.applicationFrame != 'star-kid'">
                                <span v-if="this.applicationFrame == 'kiosk'" class="d-flex align-items-center"
                                    style="color: grey">
                                    <input type="checkbox" id="enableQR"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        :checked="!!submodulelink.enableQR"
                                        @input="submodulelink.enableQR = $event.target.checked" />
                                    <label class="margin-0 font-14 d-flex align-items-center" for="enableQR">Show QR
                                        Code</label>
                                </span>
                                <span class="d-flex align-items-center" style="color: grey">
                                    <input type="checkbox" id="hideSensitiveInformation"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        :checked="!!submodulelink.isSensitive"
                                        @input="submodulelink.isSensitive = $event.target.checked" />
                                    <label class="margin-0 font-14 d-flex align-items-center"
                                        for="hideSensitiveInformation">Information is sensitive</label>
                                </span>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button v-if="!isEditSubModuleLink" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="addNewSubModuleLink">
                                    Save
                                </button>
                                <button v-if="isEditSubModuleLink" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="updateSubModuleLink(currentSubModuleLinkId)">
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
        <!-- fourth modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formsubmodule">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="showMainForm == false && showLinkForm == false">
                        <div class="section-body add-new-app-form mx-0">
                            <span class="field-container d-flex align-items-center mt-2 mb-4"
                                style="margin-left: 3.7rem">
                                <label class="me-3 d-flex add-new-app-label" style="max-width: 28%; text-align: end">3rd
                                    Party Applications</label>
                                <Toggle id="thirdPartyApp" class="toggle-switch" labelClass="switch" spanClass="slider"
                                    v-model="submodule.allow3rdParty" style="margin-left: 0"
                                    @input="(value) => handleToggle3rdParty(value)" />
                            </span>
                            <div v-if="!submodule.allow3rdParty"
                                class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3">
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>
                                <div class="d-flex align-items-center">
                                    <button type="button" class="no-bg text-button btn-add-new-app"
                                        style="margin-right: 3px; width: 20rem" @click="chooseFile">
                                        Choose file
                                    </button>
                                    <!-- <span v-if="isFileAvailable" class="errorClass">Please upload file</span> -->
                                    <input id="submodule" type="file" name="upload" ref="upload" size="100000"
                                        @change="uploadFilesubModule" hidden required />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <svg v-if="!uploadedImgSubModule && !showImgLoader" width="20" height="35"
                                            viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.19815 8.79779H59.9507C60.833 8.79779 61.6211 9.15091 62.1943 9.71533C62.7663 10.2798 63.1251 11.08 63.1251 11.9263V51.8709C63.1251 52.7412 62.7663 53.5175 62.1943 54.0825C62.1469 54.1292 62.0984 54.1764 62.0273 54.2231C61.4778 54.7175 60.7388 54.9994 59.927 54.9994H3.17446C2.29157 54.9994 1.50345 54.6469 0.930843 54.0825C0.358239 53.518 0 52.7184 0 51.8715V11.9269C0 11.0566 0.358239 10.2803 0.930843 9.7159C1.50345 9.15148 2.31526 8.79836 3.17446 8.79836H3.19815V8.79779ZM16.3045 19.6431C18.8202 19.6431 20.8587 21.6525 20.8587 24.1323C20.8587 26.6121 18.8202 28.622 16.3045 28.622C13.7887 28.622 11.7502 26.6121 11.7502 24.1323C11.7496 21.653 13.7887 19.6431 16.3045 19.6431ZM35.4708 38.9092L44.5805 23.3845L54.2575 47.5122L9.09348 47.5116V44.5192L12.8897 44.333L16.6841 35.1672L18.5805 41.7148H24.2747L29.2074 29.1824L35.4708 38.9092ZM12.6487 3.71629C11.5988 3.71629 10.7633 2.86995 10.7633 1.85786C10.7633 0.822995 11.6225 0 12.6487 0H69.1152C70.1645 0 71 0.846916 71 1.85843V41.0974C71 42.1328 70.1414 42.9564 69.1152 42.9564C68.0648 42.9564 67.2292 42.1094 67.2292 41.0974V3.74021H12.6487V3.71629ZM59.3538 12.5152H3.79444V51.2598H59.3532L59.3538 12.5152Z"
                                                fill="black" />
                                        </svg>
                                        <img v-if="uploadedImgSubModule && !showImgLoader" :src="uploadedImgSubModule"
                                            alt="image not uploaded" />
                                        <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main">
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                            cssClass="customtooltip" position="top">
                                            <span id="target" class="material-symbols-outlined img-delete-small"
                                                role="button" @click="resetFileUpload()">
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!submodule.allow3rdParty" class="scroll-wrapper">
                                <div style="gap: 25px" class="scroll-container">
                                    <div v-for="bg in subModulesPhotos" :key="bg.image">
                                        <input type="radio" hidden />
                                        <label>
                                            <img :src="bg.image"
                                                style="width: 50px; height: 50px; max-width: 100px; cursor: pointer"
                                                :class="bg.file_id == submodulePhoto.file_id ? 'active-bg' : ''"
                                                @click="selectBackgroundForSubModule(bg)" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-5 px-3">
                                <Input v-if="!submodule.allow3rdParty" label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                    class="field-container d-flex align-items-center mb-4" name="title"
                                    placeholder="Enter Title here" v-model="submodule.title" required />
                                <span v-if="submodule.allow3rdParty"
                                    class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label class="me-3 d-flex add-new-app-label" style="width: 28%; text-align: end">3rd
                                        Party Application</label>
                                    <Dropdown name="ThirdPartyoption" id="ThirdPartyoption" label=""
                                        v-model="submodule.thirdPartyValue.id" :options="this.thirdPartyFunctionWidgets"
                                        @change="(value) => handleSelect3rdParty(value)" placeholder="---- Select ----"
                                        style="width: 70%" />
                                </span>
                                <Input v-if="!submodule.allow3rdParty" label="Link"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label" type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4" name="link"
                                    placeholder="Enter Link" v-model="submodule.url" />
                                <span class="field-container d-flex align-items-center mt-2 mb-4"
                                    style="margin-left: 4rem">
                                    <label for="" style="padding-right: 5%">Description </label>
                                    <span>
                                        <textarea placeholder="Write Description" name="description" id="description"
                                            cols="50" rows="3" class="input-text" style="border-radius: 8px !important"
                                            v-model="submodule.description"></textarea>
                                    </span>
                                </span>
                                <div class="checkbox-container" v-if="this.applicationFrame != 'star-kid'">
                                    <span v-if="this.applicationFrame == 'kiosk'" class="d-flex align-items-center"
                                        style="color: grey">
                                        <input type="checkbox" id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!submodule.enableQR"
                                            @input="submodule.enableQR = $event.target.checked" />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR">Show QR
                                            Code</label>
                                    </span>
                                    <span class="d-flex align-items-center" style="color: grey">
                                        <input type="checkbox" id="hideSensitiveInformation"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!submodule.isSensitive"
                                            @input="submodule.isSensitive = $event.target.checked" />
                                        <label class="margin-0 font-14 d-flex align-items-center"
                                            for="hideSensitiveInformation">Information is sensitive</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button v-if="!isEditSubModule" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="addSubModule">
                                    Save
                                </button>
                                <button v-if="isEditSubModule" type="button" class="primary btn-save-application"
                                    :disabled="showImgLoader" @click="updateSubModule(currentSubModuleId)">
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import SpinLoader from '@/components/general/loading/SpinLoader.vue';
import { ValidationObserver } from 'vee-validate';
import { memberProfile } from '@/util/apiRequests';
import { dashboardBuilder, companies } from '@/util/apiRequests';

const initialFunctionInfoState = {
    title: '',
    url: '',
    color: '',
    textColor: '',
    primaryColor: '',
    secondaryColor: '',
    isSensitive: false,
    enableQR: false,
    embeddedDashboardId: '',
    embeddedDashboardName: '',
    embeddedCompanyId: null,
    embeddedCompanyName: '',
    lumecatitle: '',
    location: '',
    phoneNumbers: [],
    file_id: null,
    selectedSchemeOption: 'primary_colors',
    enabledtms: false,
    dtmsSettings: [],
};

const subModuleState = {
    title: '',
    image: '',
    url: '',
    description: '',
    allow3rdParty: false,
    isSensitive: false,
    enableQR: false,
    thirdPartyValue: {
        active: 1,
        android_id: '',
        deleted: 0,
        file_id: '',
        function_name: '',
        function_type: '',
        height: '',
        width: '',
        image: '',
        ios_id: '',
        id: null,
    },
};

const subModuleLinkState = {
    title: '',
    url: '',
    isSensitive: false,
    enableQR: false,
};

export default {
    name: 'AddDetailModal',
    components: { NoButtonModal, ValidationObserver, SpinLoader },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        showSaveSetting: {
            type: Boolean,
            required: true,
        },
        clickedProfileFunction: {
            type: String,
            required: true,
        },
        position: {
            type: String,
            required: true,
        },
        pageData: {
            type: Object,
        },
        applicationFrame: {
            type: String,
            required: true,
        },
        current_template: {
            type: Number,
        },
    },
    data() {
        return {
            showAppListing: true,
            showLinkListing: true,
            showLoader: true,
            isEditScreen: false,
            isEditSubModule: false,
            isEditSubModuleLink: false,
            subModulesList: [],
            subModuleLinksList: [],
            functionInfo: { ...initialFunctionInfoState },
            submodule: { ...subModuleState },
            submodulelink: { ...subModuleLinkState },
            uploadedImg: '',
            uploadedImgSubModule: '',
            showImgLoader: false,
            showMainForm: true,
            showLinkForm: false,
            currentModuleId: '',
            currentSubModuleId: '',
            currentSubModuleLinkId: '',
            genericModulePhotos: [],
            backgroundPhoto: {},
            subModulesPhotos: [],
            submodulePhoto: {},
            Position: null,
            thirdPartyFunctionWidgets: [],
            thirdPartyCompleteData: [],
            companyList: [],
            dashboardList: [],
            companyDashboardList: [],
            pdfLoading: false,
            videoLoading: false,
            phoneNumber: '',
            phoneNumberIndex: null,
            colorScheme: {
                selectedOption: 'primary_colors',
                primary_colors: ['#FFFFFF', '#5155C3', '#FFFFFF', '#5155C3'],
                secondary_colors: ['#FFFFFF', '#5155C3', '#FFFFFF', '#5155C3'],
                customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                module_customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
            },
            isCustosmizedThemeSelected: false,
            svgImg: '',
            svgElement: '',
            dtmsDelay: '',
            dtmsDigit: '',
            isModuleLogoUpdated: false,
        };
    },
    computed: {
        svgToBase64() {
            if (!this.svgImg) {
                return;
            }

            // Get the outer HTML representation of the SVG element
            const svgHtml = this.svgImg.outerHTML;

            // Encode the SVG HTML string to base64
            const base64Svg = btoa(svgHtml);

            // Return the base64 encoded SVG data
            return 'data:image/svg+xml;base64,' + base64Svg;
        },
    },
    methods: {
        async handleSelect3rdParty(val) {
            let temp = this.thirdPartyCompleteData.filter((item) => item.id == val);
            this.submodule.thirdPartyValue = temp[0];
            this.submodule.title = temp[0].function_name;
        },
        async handleToggle3rdParty(val) {
            this.submodule.thirdPartyValue.id = null;
        },
        selectBackground(bg) {
            this.backgroundPhoto = {
                file_id: bg.file_id,
                id: bg.id,
                image: bg.image,
            };
            if (this.applicationFrame !== 'kiosk') {
                this.functionInfo.image = this.backgroundPhoto.file_id;
            }
            this.$toasted.success('Module image uploaded successfully !');
        },
        selectBackgroundForSubModule(bg) {
            this.submodulePhoto = {
                file_id: bg.file_id,
                id: bg.id,
                image: bg.image,
            };

            this.submodule.image = this.submodulePhoto.file_id;
            this.$toasted.success('SubModule image uploaded successfully !');
        },
        selectCustomColor(event) {
            let color = event.target.value;
            this.functionInfo.color = color;
            this.errors.color = null;
        },
        selectCustomTextColor(event) {
            let textColor = event.target.value;
            this.functionInfo.textColor = textColor;
            this.errors.textColor = null;
        },
        chooseFile() {
            if (this.$refs.upload != undefined) {
                this.$refs.upload.click();
            }
        },

        isFileAvailable() {
            return this.$refs.upload.value === '' ? false : true;
        },
        async geturl(fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [fileId],
            };
            let response = await this.$api.post(endpoint, requestData);
            return response?.data?.data[0].image;
        },
        async addNewApplication() {
            try {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                if (this.clickedProfileFunction == 'Lumeca' && this.functionInfo.phoneNumbers.length == 0) {
                    this.$toasted.error('Please add atleast one phone number');
                    return;
                }

                if (
                    this.clickedProfileFunction == 'Call Button' &&
                    this.functionInfo.enabledtms &&
                    this.functionInfo.dtmsSettings.length == 0
                ) {
                    this.$toasted.error('Please add atleast one DTMS setting');
                    return;
                }

                if (!this.isFileAvailable() && this.functionInfo.image.length == 0) {
                    this.$toasted.error('Please upload or select Image');
                    return;
                }

                if (this.clickedProfileFunction == 'Call Button' && this.phoneNumber != '') {
                    this.functionInfo.phoneNumbers.unshift(this.phoneNumber);
                }

                this.showLoader = true;
                let templateid;
                if (this.applicationFrame == 'kiosk') {
                    let isImageUploaded = await this.uploadModuleLogo();

                    if (!isImageUploaded) {
                        return;
                    }
                }

                if (this.$route.query.id) {
                    templateid = this.$route.query.id;

                    // const { colorScheme } = this.pageData.page3;
                    //  this.functionInfo.color = colorScheme.colors[0];
                    this.functionInfo = {
                        ...this.functionInfo,
                        assigned_to: this.clickedProfileFunction,
                        position: this.Position,
                        dashboard_assigned: this.$route.query.id,
                        issaved: 0,
                        application: this.applicationFrame,
                    };
                } else {
                    const lastidendpoint = dashboardBuilder.getLastTemplateId();
                    const res = await this.$api.get(lastidendpoint);
                    if (res.data.success) {
                        templateid = res.data.response.AUTO_INCREMENT;
                        // const { colorScheme } = this.pageData.page3;
                        // this.functionInfo.color = colorScheme.colors[0];
                        this.functionInfo = {
                            ...this.functionInfo,
                            assigned_to: this.clickedProfileFunction,
                            position: this.Position,
                            dashboard_assigned: res.data.response.AUTO_INCREMENT,
                            issaved: 0,
                            application: this.applicationFrame,
                        };
                    }
                }
                const { template, templateData } = this.pageData.page4;
                this.template = template;

                if (this.applicationFrame == 'kiosk') {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.color != '') {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /background-color:[^;]*/,
                                            `background-color:${this.functionInfo.color};`
                                        );
                                    } else {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /background-color:[^;]*/,
                                            `background-color:${this.pageData.page3.colorScheme.colors[0]};`
                                        );
                                    }
                                    this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                    this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                }
                            });
                        }
                    });
                }

                Object.keys(this.template).forEach((group) => {
                    if (this.applicationFrame == 'kiosk') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo.title;
                                    if (this.clickedProfileFunction == 'Lumeca') {
                                        this.template[group][li].title = this.functionInfo.lumecatitle;
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo?.title;
                                    this.template[group][li].color = this.functionInfo?.color;
                                    this.template[group][li].textColor = this.functionInfo?.textColor;
                                    templateData[group][li].title = this.functionInfo?.title;
                                    templateData[group][li].url = this.functionInfo?.url;
                                    templateData[group][li].color = this.functionInfo?.color;
                                    templateData[group][li].textColor = this.functionInfo?.textColor;
                                    templateData[group][li].company_id = this.functionInfo?.embeddedCompanyId;
                                    templateData[group][li].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                    if (this.clickedProfileFunction == 'Call Button') {
                                        templateData[group][li].phoneNumber = this.phoneNumber;
                                        templateData[group][li].enabledtms = this.functionInfo?.enabledtms;
                                        templateData[group][li].dtmsSettings = JSON.stringify(
                                            this.functionInfo?.dtmsSettings
                                        );
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'star-kid') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo.title;
                                    this.template[group][li].url = this.functionInfo.url;
                                    this.template[group][li].color = this.functionInfo.color;
                                    this.template[group][li].textColor = this.functionInfo.textColor;
                                }
                            });
                        }
                    } else {
                        if (group === 'GSI') {
                            Object.keys(this.template[group]).forEach(async (gsi) => {
                                if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                    this.template[group][gsi].title = this.functionInfo.title;
                                    this.template[group][gsi].url = this.functionInfo.url;
                                    this.template[group][gsi].color = this.functionInfo.color;
                                    this.template[group][gsi].textColor = this.functionInfo.textColor;
                                    this.template[group][gsi].isSensitive = Number(this.functionInfo.isSensitive);
                                    // this.template[group][gsi].enableQR = Number(this.functionInfo.enableQR);
                                    let textcolor, bgcolor;

                                    const { colorScheme } = this.pageData.page3;

                                    if (this.functionInfo.color != '') {
                                        bgcolor = this.functionInfo.color;
                                    } else {
                                        bgcolor = colorScheme.colors[0][0];
                                    }

                                    if (this.functionInfo.textColor !== '') {
                                        textcolor = this.functionInfo.textColor;
                                    } else {
                                        // textcolor = '#FFFFFF';
                                        textcolor = colorScheme.colors[0][1];
                                    }

                                    this.template[group][
                                        gsi
                                    ].style = `background-color:${bgcolor}; color:${textcolor};`;
                                }
                            });
                        }
                    }
                });

                if (this.applicationFrame == 'kiosk') {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.textColor != '') {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /(?:^|;)\s*color\s*:[^;]*/,
                                            `color:${this.functionInfo.textColor}`
                                        );
                                    } else {
                                        //primary color of text
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /(?:^|;)\s*color\s*:[^;]*/,
                                            `color:${this.pageData.page3.colorScheme.colors[1]}`
                                        );
                                    }
                                }
                            });
                        }
                    });
                }

                const endpoint = memberProfile.createGenericModule();
                const saveFunctionResponse = await this.$api.post(endpoint, this.functionInfo);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                if (this.applicationFrame == 'kiosk') {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                    }
                                    this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                }
                            });
                        }
                    });
                } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        templateData[group][li].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                        templateData[group][li].image = res;
                                    }
                                    this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                }
                            });
                        }
                    });
                } else if (this.applicationFrame == 'star-kid') {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                    }
                                    this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                }
                            });
                        }
                    });
                } else {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'GSI') {
                            Object.keys(this.template[group]).forEach(async (gsi) => {
                                if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][gsi].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][gsi].elements[0].image = res;
                                    }
                                    this.template[group][gsi].elements[0].id =
                                        saveFunctionResponse.data.response[0];
                                }
                            });
                        }
                    });
                }
                this.$toasted.success('Application added successfully !');
                this.showPage('FUNCTION_LISTING');
                this.resetForm();
                this.$emit('close');
            } catch (e) {
                const errorMesage = 'Please enter all required fields';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        async getGenericImages() {
            const endpoint = dashboardBuilder.getAssets('cde_generic_modules');
            const assetsResponse = await this.$api.get(endpoint);

            if (!assetsResponse.data.success) {
                throw assetsResponse.data.error;
            }

            this.genericModulePhotos = assetsResponse.data.list;
            return true;
        },
        async getSubModulemages() {
            const endpoint = dashboardBuilder.getAssets('cde_sub_modules');
            const assetsResponse = await this.$api.get(endpoint);

            if (!assetsResponse.data.success) {
                throw assetsResponse.data.error;
            }

            this.subModulesPhotos = assetsResponse.data.list;
            return true;
        },
        async updateApplication(id) {
            try {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                if (this.clickedProfileFunction == 'Lumeca' && this.functionInfo.phoneNumbers.length == 0) {
                    this.$toasted.error('Please add atleast one phone number');
                    return;
                }

                if (
                    this.clickedProfileFunction == 'Call Button' &&
                    this.functionInfo.enabledtms &&
                    this.functionInfo.dtmsSettings.length == 0
                ) {
                    this.$toasted.error('Please add atleast one DTMS setting');
                    return;
                }

                if (!this.isFileAvailable() && this.functionInfo.image.length == 0) {
                    this.$toasted.error('Please upload or select Image');
                    return;
                }

                if (this.clickedProfileFunction == 'Call Button' && this.phoneNumber != '') {
                    if (this.functionInfo?.phoneNumbers.length) {
                        this.functionInfo.phoneNumbers[0] = this.phoneNumber;
                    } else {
                        this.functionInfo.phoneNumbers.unshift(this.phoneNumber);
                    }
                }

                this.showLoader = true;
                const { template, templateData } = this.pageData.page4;
                this.template = template;

                if (this.applicationFrame == 'kiosk') {
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.color != '') {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /background-color:[^;]*/,
                                            `background-color:${this.functionInfo.color};`
                                        );
                                    } else {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /background-color:[^;]*/,
                                            `background-color:${this.pageData.page3.colorScheme.colors[0]};`
                                        );
                                    }
                                    this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                    this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                }
                            });
                        }
                    });
                }

                Object.keys(this.template).forEach((group) => {
                    if (this.applicationFrame == 'kiosk') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo.title;
                                    if (this.clickedProfileFunction == 'Lumeca') {
                                        this.template[group][li].title = this.functionInfo.lumecatitle;
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo?.title;
                                    this.template[group][li].color = this.functionInfo?.color;
                                    this.template[group][li].textColor = this.functionInfo?.textColor;
                                    templateData[group][li].title = this.functionInfo?.title;
                                    templateData[group][li].url = this.functionInfo?.url;
                                    templateData[group][li].color = this.functionInfo?.color;
                                    templateData[group][li].textColor = this.functionInfo?.textColor;
                                    templateData[group][li].company_id = this.functionInfo?.embeddedCompanyId;
                                    templateData[group][li].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                    if (this.clickedProfileFunction == 'Call Button') {
                                        templateData[group][li].phoneNumber = this.phoneNumber;
                                        templateData[group][li].enabledtms = this.functionInfo?.enabledtms;
                                        templateData[group][li].dtmsSettings = JSON.stringify(
                                            this.functionInfo?.dtmsSettings
                                        );
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'star-kid') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    this.template[group][li].title = this.functionInfo.title;
                                    this.template[group][li].url = this.functionInfo.url;
                                    this.template[group][li].color = this.functionInfo.color;
                                    this.template[group][li].textColor = this.functionInfo.textColor;
                                }
                            });
                        }
                    } else {
                        if (group === 'GSI') {
                            Object.keys(this.template[group]).forEach(async (gsi) => {
                                if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                    this.template[group][gsi].title = this.functionInfo.title;
                                    this.template[group][gsi].url = this.functionInfo.url;
                                    this.template[group][gsi].color = this.functionInfo.color;
                                    this.template[group][gsi].textColor = this.functionInfo.textColor;
                                    this.template[group][gsi].isSensitive = Number(this.functionInfo.isSensitive);
                                    // this.template[group][gsi].enableQR = Number(this.functionInfo.enableQR);
                                    let textcolor, bgcolor;

                                    const { colorScheme } = this.pageData.page3;

                                    if (this.functionInfo.color.length != 0) {
                                        bgcolor = this.functionInfo.color;
                                    } else {
                                        // bgcolor = 'rgba(255, 255, 255, 0.85)';
                                        bgcolor = colorScheme.colors[0][0];
                                    }

                                    if (this.functionInfo.textColor.length != 0) {
                                        textcolor = this.functionInfo.textColor;
                                    } else {
                                        // textcolor = '#FFFFFF';
                                        textcolor = colorScheme.colors[0][1];
                                    }

                                    this.template[group][
                                        gsi
                                    ].style = `background-color:${bgcolor}; color:${textcolor};`;
                                }
                            });
                        }
                    }
                });

                if (this.applicationFrame == 'kiosk') {
                    // Text color
                    Object.keys(this.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach((li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.textColor != '') {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /(?:^|;)\s*color\s*:[^;]*/,
                                            `color:${this.functionInfo.textColor}`
                                        );
                                    } else {
                                        this.template[group][li].style = this.template[group][li].style.replace(
                                            /(?:^|;)\s*color\s*:[^;]*/,
                                            `color:${this.pageData.page3.colorScheme.colors[1]}`
                                        );
                                    }
                                }
                            });
                        }
                    });
                }
                if (this.applicationFrame == 'kiosk') {
                    if (this.isModuleLogoUpdated) {
                        let isImageUploaded = await this.uploadModuleLogo();

                        if (!isImageUploaded) {
                            return;
                        }
                    }
                }

                const endpoint = memberProfile.updateGenericModule(id);

                const saveFunctionResponse = await this.$api.put(endpoint, {
                    ...this.functionInfo,
                    application: this.applicationFrame,
                    generic_function: this.clickedProfileFunction,
                });
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                Object.keys(this.template).forEach((group) => {
                    if (this.applicationFrame == 'kiosk') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        templateData[group][li].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                        templateData[group][li].image = res;
                                    }
                                }
                            });
                        }
                    } else if (this.applicationFrame == 'star-kid') {
                        if (group === 'LI') {
                            Object.keys(this.template[group]).forEach(async (li) => {
                                if (li == 'LI ' + this.Position) {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][li].elements[0].image = res;
                                    }
                                }
                            });
                        }
                    } else {
                        if (group === 'GSI') {
                            Object.keys(this.template[group]).forEach(async (gsi) => {
                                if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                    if (this.functionInfo.image != '') {
                                        this.template[group][gsi].elements[0].image_id = this.functionInfo.image;
                                        let res = await this.geturl(this.functionInfo.image);
                                        this.template[group][gsi].elements[0].image = res;
                                    }
                                }
                            });
                        }
                    }
                });

                //   this.$emit('captureValues', { 'page4': { template: this.template } });
                this.$toasted.success('Module updated successfully !');
                this.showPage('FUNCTION_LISTING');
                this.resetForm();
                this.$emit('close');
            } catch (e) {
                const errorMesage = e ? e : 'Failed to update module !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        async updateSubModule(id) {
            try {
                const form = this.$refs.formsubmodule;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                if (!this.submodule.allow3rdParty && this.submodule.image.length == 0) {
                    this.$toasted.error('Please upload or select image');
                    return;
                }
                this.showLoader = true;
                const endpoint = memberProfile.updateSubModule(id);
                const saveFunctionResponse = await this.$api.put(endpoint, this.submodule);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Sub Module updated successfully !');
                this.showPage('FUNCTION_LISTING');
                this.resetSubModuleForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to update sub module !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        // async deleteApplication() {

        //     try {

        //         let id=this.currentModuleId;

        //         if(this.currentModuleId.length!=0)
        //         {

        //             this.showLoader = true;
        //         const endpoint = memberProfile.deleteGenericModule(id);
        //         const saveFunctionResponse = await this.$api.delete(endpoint);
        //         if (!saveFunctionResponse.data.success) {
        //             throw saveFunctionResponse.data.error;
        //         }

        //         this.$toasted.success('Application deleted successfully !');
        //         this.showPage('FUNCTION_LISTING');
        //         this.resetForm();

        //         }

        //     } catch (e) {
        //         const errorMesage = e ? e : 'Failed to delete application !';
        //         this.$toasted.error(errorMesage);
        //     } finally {
        //         this.showLoader = false;
        //     }
        // },
        async addSubModule() {
            try {
                const form = this.$refs.formsubmodule;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                if (!this.submodule.allow3rdParty && !this.isFileAvailable() && this.submodule.image.length == 0) {
                    this.$toasted.error('Please upload or select Image');
                    return;
                }
                this.showLoader = true;
                this.submodule = { ...this.submodule, assigned_to: this.currentModuleId };
                const endpoint = memberProfile.createSubModule();
                const saveFunctionResponse = await this.$api.post(endpoint, this.submodule);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Sub Module added successfully !');
                this.showPage('FUNCTION_LISTING');
                this.resetSubModuleForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to add submodule !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        async fetchApplications() {
            this.showLoader = true;
            // HTTP call for get specific 3rd party application detail
            if (this.$route.query.id) {
                const endpoint = memberProfile.getSpecificGenericFunction(
                    this.clickedProfileFunction,
                    this.Position,
                    this.$route.query.id
                );
                const functionResponse = await this.$api.get(endpoint);
                if (!functionResponse) {
                    throw 'e';
                }

                let { info } = functionResponse.data;
                if (info.length) {
                    this.functionInfo.title = info[0].title;
                    this.functionInfo.url = info[0].url;
                    this.functionInfo.image = info[0].image;
                    this.functionInfo.color = info[0].color;
                    this.functionInfo.textColor = info[0].textColor;
                    this.functionInfo.primaryColor = info[0].primaryColor;
                    this.functionInfo.secondaryColor = info[0].secondaryColor;
                    this.functionInfo.isSensitive = info[0].isSensitive;
                    this.functionInfo.enableQR = info[0].enableQR;
                    this.functionInfo.embeddedDashboardId = info[0]?.embedded_dashboard_id ?? '';
                    this.functionInfo.embeddedDashboardName = info[0]?.embedded_dashboard_name ?? '';
                    this.functionInfo.embeddedCompanyId = info[0]?.embedded_company_id;
                    this.functionInfo.embeddedCompanyName = info[0]?.embedded_company_name ?? '';
                    this.functionInfo.lumecatitle = info[0].lumecatitle;
                    this.functionInfo.location = info[0].location;
                    this.functionInfo.phoneNumbers = info[0]?.phoneNumbers ? JSON.parse(info[0]?.phoneNumbers) : [];
                    this.functionInfo.selectedSchemeOption =
                        info[0]?.selectedSchemeOption ?? this.functionInfo?.selectedSchemeOption;
                    (this.functionInfo.enabledtms = info[0]?.enabledtms),
                        (this.functionInfo.dtmsSettings = info[0]?.dtmsSettings
                            ? JSON.parse(info[0]?.dtmsSettings)
                            : []),
                        (this.backgroundPhoto.file_id = info[0].image);
                    this.uploadedImg = info[0].imageUrl ?? '';
                    this.currentModuleId = info[0].id;
                    this.isEditScreen = true;
                    if (this.clickedProfileFunction == 'Call Button') {
                        this.phoneNumber = this.functionInfo?.phoneNumbers?.length
                            ? this.functionInfo.phoneNumbers[0]
                            : '';
                    }
                    if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                        this.colorScheme.module_customized_colors = [
                            info[0].primaryColor,
                            info[0].secondaryColor,
                            info[0].color,
                            info[0].testColor,
                        ];
                    }
                    const submodules = memberProfile.getSubModuleList(this.currentModuleId);
                    const response = await this.$api.get(submodules);
                    this.functionInfo.file_id = info[0].file_id;
                    if (this.functionInfo.file_id != null) {
                        let file_url = await this.geturl(this.functionInfo.file_id);
                        this.functionInfo.url = file_url;
                    }
                    if (!response) {
                        throw 'e';
                    }
                    this.subModulesList = response.data;
                    if (this.applicationFrame === 'kiosk') {
                        await this.urlToSvg(info[0].imageUrl);
                    }
                }
                this.showLoader = false;
            } else {
                const lastidendpoint = dashboardBuilder.getLastTemplateId();
                const res = await this.$api.get(lastidendpoint);
                if (res.data.success) {
                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.Position,
                        res.data.response.AUTO_INCREMENT
                    );
                    const functionResponse = await this.$api.get(endpoint);
                    if (!functionResponse) {
                        throw 'e';
                    }

                    let { info } = functionResponse.data;
                    if (info.length) {
                        this.functionInfo.title = info[0].title;
                        this.functionInfo.url = info[0].url;
                        this.functionInfo.image = info[0].image;
                        this.functionInfo.color = info[0].color;
                        this.functionInfo.textColor = info[0].textColor;
                        this.functionInfo.primaryColor = info[0].primaryColor;
                        this.functionInfo.secondaryColor = info[0].secondaryColor;
                        this.functionInfo.isSensitive = info[0].isSensitive;
                        this.functionInfo.enableQR = info[0].enableQR;
                        this.functionInfo.lumecatitle = info[0].lumecatitle;
                        this.functionInfo.location = info[0].location;
                        this.functionInfo.phoneNumbers = info[0]?.phoneNumbers
                            ? JSON.parse(info[0]?.phoneNumbers)
                            : [];
                        this.functionInfo.selectedSchemeOption =
                            info[0]?.selectedSchemeOption ?? this.functionInfo?.selectedSchemeOption;
                        (this.functionInfo.enabledtms = info[0]?.enabledtms),
                            (this.functionInfo.dtmsSettings = info[0]?.dtmsSettings
                                ? JSON.parse(info[0]?.dtmsSettings)
                                : []),
                            (this.backgroundPhoto.file_id = info[0].image);
                        this.uploadedImg = info[0].imageUrl ?? '';
                        this.currentModuleId = info[0].id;
                        this.isEditScreen = true;
                        this.functionInfo.embeddedDashboardId = info[0]?.embedded_dashboard_id ?? '';
                        this.functionInfo.embeddedCompanyId = info[0]?.embedded_company_id;
                        this.functionInfo.embeddedDashboardName = info[0]?.embedded_dashboard_name ?? '';
                        this.functionInfo.embeddedCompanyName = info[0]?.embedded_company_name ?? '';
                        if (this.clickedProfileFunction == 'Call Button') {
                            this.phoneNumber = this.functionInfo?.phoneNumbers?.length
                                ? this.functionInfo.phoneNumbers[0]
                                : '';
                        }
                        if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                            this.colorScheme.module_customized_colors = [
                                info[0].primaryColor,
                                info[0].secondaryColor,
                                info[0].color,
                                info[0].testColor,
                            ];
                        }
                        const submodules = memberProfile.getSubModuleList(this.currentModuleId);
                        const response = await this.$api.get(submodules);
                        if (!response) {
                            throw 'e';
                        }
                        this.subModulesList = response.data;
                        if (this.applicationFrame === 'kiosk') {
                            await this.urlToSvg(info[0].imageUrl);
                        }
                    }
                    this.showLoader = false;
                }
            }

            // To fetch dashboard-list for fetched company
            this.companyDashboardList = this.dashboardList.filter((dashboard) => {
                return dashboard.company_id == this.functionInfo?.embeddedCompanyId;
            });
        },

        async editSubModule(data, id) {
            this.showMainForm = false;
            this.showLoader = true;
            if (data && !this.submodule.allow3rdParty) {
                await this.getSubModulemages();
                this.submodule.title = data.title;
                this.submodule.url = data.url;
                this.submodule.image = data.image;
                this.submodulePhoto.file_id = data.image;
                this.submodule.description = data.description;
                this.submodule.allow3rdParty = data.allow3rdParty;
                this.submodule.isSensitive = data.isSensitive;
                this.submodule.enableQR = data.enableQR;
                this.submodule.thirdPartyValue = JSON.parse(data.thirdPartyValue);
                this.uploadedImgSubModule = data.imageUrl ?? '';
                this.currentSubModuleId = id;
                this.isEditSubModule = true;
            } else {
                this.submodule.title = data.title;
                this.submodulePhoto.file_id = data.image;
                this.submodule.description = data.description;
                this.submodule.allow3rdParty = data.allow3rdParty;
                this.submodule.isSensitive = data.isSensitive;
                this.submodule.enableQR = data.enableQR;
                this.submodule.thirdPartyValue = JSON.parse(data.thirdPartyValue);
                this.currentSubModuleId = id;
                this.isEditSubModule = true;
            }
            this.showLoader = false;
        },
        async editSubModuleLink(data, id) {
            this.showMainForm = false;
            this.showLoader = true;

            if (data) {
                this.submodulelink.title = data.title;
                this.submodulelink.url = data.url;
                this.submodulelink.isSensitive = data.isSensitive;
                this.submodulelink.enableQR = data.enableQR;
                this.currentSubModuleLinkId = id;
                this.isEditSubModuleLink = true;
            }
            this.showLoader = false;
        },
        async deleteSubModule(id) {
            try {
                this.showLoader = true;
                const endpoint = memberProfile.deleteSubModule(id);
                const saveFunctionResponse = await this.$api.delete(endpoint);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Submodule deleted successfully !');
                this.showPage('FUNCTION_LISTING');
                this.resetForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to delete submodule !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },

        async uploadFile(event) {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                const fileName = file.name;
                const fileSize = file.size;
                let fileNameSplit = fileName.split('.');
                let fileExtension = fileNameSplit[1];
                if (
                    !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension) &&
                    this.applicationFrame !== 'kiosk'
                ) {
                    this.$toasted.error('Only png, jpg/jpeg files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (fileExtension != 'svg' && this.applicationFrame === 'kiosk') {
                    this.$toasted.error('Only svg files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (fileSize / 1024 / 1024 > 10) {
                    this.$toasted.error('Image size should be less than 10MB');
                    this.$refs.upload.value = '';
                    return;
                }

                //HTTP trigger for File upload starts
                try {
                    this.showImgLoader = true;
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async (event) => {
                        this.uploadedImg = event.target.result;
                        if (this.applicationFrame === 'kiosk') {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, this.functionInfo.secondaryColor);
                        } else {
                            const formData = new FormData();
                            formData.append('file', file);

                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.functionInfo.image = uploadImgResponse.data.s3_link;
                        }
                        this.isModuleLogoUpdated = true;
                    };

                    //Coverts into base64 for rendering

                    this.$toasted.success('Application image uploaded successfully !');
                } catch (e) {
                    const errorMessage = e ? e : 'Image upload failed !';
                    this.$toasted.error(errorMessage);
                    this.$refs.upload.value = '';
                } finally {
                    this.showImgLoader = false;
                }
            }
        },
        base64ToSvg(base64String) {
            const base64Data = base64String.replace(/^data:image\/svg\+xml;base64,/, '');
            const svgXml = atob(base64Data);
            return svgXml;
        },
        renderSvg(svgXml, fillColor) {
            // Parse SVG XML string
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

            // Get the SVG element
            const svgElement = svgDoc.documentElement;

            const allPaths = svgElement.querySelectorAll('path');

            let colors = [];

            allPaths.forEach((path) => {
                if (path.hasAttribute('fill')) {
                    let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                    let shouldBeChanged = false;
                    if (
                        (color[0] === 'a' ||
                            color[0] === 'b' ||
                            color[0] === 'c' ||
                            color[0] === 'd' ||
                            color[0] === 'e' ||
                            color[0] === 'f') &&
                        (color[2] === 'a' ||
                            color[2] === 'b' ||
                            color[2] === 'c' ||
                            color[2] === 'd' ||
                            color[2] === 'e' ||
                            color[2] === 'f') &&
                        (color[4] === 'a' ||
                            color[4] === 'b' ||
                            color[4] === 'c' ||
                            color[4] === 'd' ||
                            color[4] === 'e' ||
                            color[4] === 'f')
                    ) {
                        shouldBeChanged = false;
                    } else {
                        shouldBeChanged = true;
                        path.setAttribute('fill', fillColor);
                    }

                    colors.push(path.getAttribute('fill'));
                }
            });

            this.svgImg = svgElement;
        },
        async urlToSvg(url) {
            const reader = new FileReader();
            let img = await fetch(url);
            let imgBlob = await img.blob();
            reader.readAsDataURL(imgBlob);

            reader.onload = () => {
                const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                this.svgImg = atob(base64Data);
                this.svgElement = this.svgImg;
                this.renderSvg(this.svgImg, this.functionInfo.secondaryColor);
            };
        },
        async uploadFilesubModule(event) {
            if (event.target.files.length > 0 && !this.submodule.allow3rdParty) {
                const file = event.target.files[0];
                const fileName = file.name;
                const fileSize = file.size;
                let fileNameSplit = fileName.split('.');
                let fileExtension = fileNameSplit[1];
                if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                    this.$toasted.error('Only png, jpg/jpeg files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (fileSize / 1024 / 1024 > 10) {
                    this.$toasted.error('Image size should be less than 10MB');
                    this.$refs.upload.value = '';
                    return;
                }

                //HTTP trigger for File upload starts
                try {
                    this.showImgLoader = true;
                    const formData = new FormData();
                    formData.append('file', file);

                    const endpoint = memberProfile.uploadProfileFunctionsImage();
                    const uploadImgResponse = await this.$api.post(endpoint, formData);
                    if (!uploadImgResponse.data.success) {
                        throw uploadImgResponse.data.error;
                    }

                    //Coverts into base64 for rendering
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.uploadedImgSubModule = event.target.result;
                    };

                    reader.readAsDataURL(file);

                    this.submodule.image = uploadImgResponse.data.s3_link;

                    this.$toasted.success('Application image uploaded successfully !');
                } catch (e) {
                    const errorMessage = e ? e : 'Image upload failed !';
                    this.$toasted.error(errorMessage);
                    this.$refs.upload.value = '';
                } finally {
                    this.showImgLoader = false;
                }
            }
        },
        async updateSubModuleLink(id) {
            try {
                const form = this.$refs.formlink;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                this.showLoader = true;
                const endpoint = memberProfile.updateSubModuleLink(id);
                const saveFunctionResponse = await this.$api.put(endpoint, this.submodulelink);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Sub Module link updated successfully !');
                this.showPage('LINK_LISTING');
                this.resetSubModuleLinkForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to update sub module link !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        resetFileUpload() {
            this.uploadedImg = '';
            this.uploadedImgSubModule = '';
            this.$refs.upload.value = '';
            this.functionInfo.image = '';
            this.submodule.image = '';
        },
        async showPage(pageType) {
            if (pageType == 'ADD_SUBMODULE') {
                await this.getSubModulemages();
                this.showAppListing = false;
                this.resetSubModuleForm();
                this.uploadedImg = '';
                this.uploadedImgSubModule = '';
                this.showMainForm = false;
            }
            if (pageType === 'FUNCTION_LISTING') {
                this.showAppListing = true;
                this.showMainForm = true;
                this.isEditScreen = false;
                this.showLinkForm = false;
                this.isEditSubModule = false;
                this.resetSubModuleLinkForm();
                this.fetchApplications();
            }
            if (pageType === 'LINK_LISTING') {
                this.showLinkListing = true;
                this.showAppListing = false;
                this.showMainForm = false;
                this.isEditSubModuleLink = false;
                this.addSubModuleLink(this.currentSubModuleId);
            } else {
                this.showAppListing = false;
                this.showLinkListing = false;
                this.isEditScreen = false;
                this.resetForm();
                this.resetSubModuleForm();
                this.uploadedImg = '';
                this.uploadedImgSubModule = '';
            }
        },
        cancel() {
            // this.showAppListing = true;
            // this.isEditScreen = false;
            // this.resetForm();
            // this.$emit('close');
        },
        async addSubModuleLink(submoduleid) {
            this.showLinkForm = true;
            this.showMainForm = false;
            this.showLoader = true;
            this.currentSubModuleId = submoduleid;
            const endpoint = memberProfile.getSubModuleLinkList(submoduleid);
            const response = await this.$api.get(endpoint);
            if (!response) {
                throw 'e';
            }
            this.subModuleLinksList = response.data;
            this.showLoader = false;
        },

        async addNewSubModuleLink() {
            try {
                const form = this.$refs.formlink;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                this.showLoader = true;
                this.submodulelink = { ...this.submodulelink, assigned_to: this.currentSubModuleId };
                const endpoint = memberProfile.createSubModuleLink();
                const saveFunctionResponse = await this.$api.post(endpoint, this.submodulelink);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Sub Module Link added successfully !');
                this.showPage('LINK_LISTING');
                this.resetSubModuleLinkForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to add submodulelink !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        async deleteSubModuleLink(id) {
            try {
                this.showLoader = true;
                const endpoint = memberProfile.deleteSubModuleLink(id);
                const saveFunctionResponse = await this.$api.delete(endpoint);
                if (!saveFunctionResponse.data.success) {
                    throw saveFunctionResponse.data.error;
                }

                this.$toasted.success('Submodule link deleted successfully !');
                this.showPage('LINK_LISTING');
                this.resetForm();
            } catch (e) {
                const errorMesage = e ? e : 'Failed to delete submodulelink !';
                this.$toasted.error(errorMesage);
            } finally {
                this.showLoader = false;
            }
        },
        resetForm() {
            this.functionInfo = { ...initialFunctionInfoState };
            this.uploadedImg = '';
            if (!this.showAppListing) {
                requestAnimationFrame(() => {
                    this.$refs.form?.reset();
                });
            }
        },
        resetSubModuleForm() {
            this.submodule = { ...subModuleState };
            this.uploadedImgSubModule = '';
            if (!this.showAppListing) {
                requestAnimationFrame(() => {
                    this.$refs.formsubmodule?.reset();
                });
            }
        },
        resetSubModuleLinkForm() {
            this.submodulelink = { ...subModuleLinkState };
            if (!this.showAppListing) {
                requestAnimationFrame(() => {
                    this.$refs.formlink?.reset();
                });
            }
        },
        resetAll() {
            this.resetForm();
            this.resetSubModuleForm();
            this.resetFileUpload();
            this.resetSubModuleLinkForm();
            this.$emit('close');
        },
        async get3rdPartyFunctionWidgets() {
            this.showLoader = true;
            if (this.pageData.page2.thirdPartyFunctions) {
                const requestData = {
                    functionIds: this.pageData.page2.thirdPartyFunctions.map((el) => el.id),
                };
                const endpoint = memberProfile.getFunctionDetailsByIds();
                let response = await this.$api.post(endpoint, requestData);
                let thirdPartyWidgets = response.data.data.map((el) => {
                    let data = {
                        ...el,
                        height: 'a',
                        width: 'b',
                    };
                    return data;
                });

                let options = thirdPartyWidgets
                    .filter((item) => item.active === 1)
                    .map((item) => ({
                        text: item.function_name,
                        value: item.id,
                    }));

                let option1 = thirdPartyWidgets.filter((item) => item.active === 1);

                this.thirdPartyFunctionWidgets = options;
                this.thirdPartyCompleteData = option1;
            }
            this.showLoader = false;
        },
        handleThirdPartyDropdown(e) {
            this.submodule.thirdPartyValue.id = e;
        },
        async handleCompanySelection(e) {
            this.functionInfo.embeddedCompanyName = e.company_name;
            this.functionInfo.embeddedCompanyId = e.id;
            this.functionInfo.embeddedDashboardName = null;
            this.companyDashboardList = this.dashboardList.filter((dashboard) => {
                return dashboard.company_id == e.id;
            });

            if (!this.companyDashboardList.length) {
                this.$toasted.error('No kiosk dashboard available with this company');
            }
        },
        async getAllDashboards() {
            try {
                let response = await this.$api.get(dashboardBuilder.getAllTemplateList('completed'));
                const { templateMetaList } = response.data;

                this.dashboardList = templateMetaList.filter((dashboard) => {
                    return dashboard.application == 'kiosk';
                });

                this.dashboardList.forEach((dashboard) => {
                    dashboard.embeddedDashboardName = dashboard.dashboardName;
                });
            } catch (err) {
                this.$toasted.error('Failed to retrieve Dashboards');
            }
        },
        async getAllCompanies() {
            try {
                const endpoint = companies.getAllCompanies();
                const response = await this.$api.get(endpoint);
                this.companyList = response.data.info;
                response.data.info?.forEach((company) => {
                    company.embeddedCompanyName = company?.company_name;
                });
            } catch (err) {
                this.$toasted.error('Failed to retrieve Companies');
            }
        },
        async handleDashboardSelection(e) {
            this.functionInfo.embeddedDashboardName = e.dashboardName;
            this.functionInfo.embeddedDashboardId = e.id;
        },
        async uploadPDF(event) {
            let file = event.target.files[0];
            let file_extension = file.name.split('.').pop();
            if (file_extension != 'pdf') {
                this.$toasted.error('Only PDF is allowed');
                return;
            }
            if (file.size / 1024 / 1024 > 20) {
                this.$toasted.error('PDF size should be less than 20MB');
                return;
            }
            this.pdfLoading = true;
            try {
                if (file != null) {
                    let that = this;
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async function () {
                        let formData = new FormData();
                        formData.append('file', file);
                        formData.append('type', 'generic_module_pdf');

                        const endpoint = dashboardBuilder.uploadAssets();
                        let response = await that.$api.post(endpoint, formData);

                        if (response.data.success) {
                            that.functionInfo.file_id = response.data.s3_link;
                            let res = await that.geturl(response.data.s3_link);
                            that.functionInfo.url = res;
                            that.pdfLoading = false;
                        }
                    };
                    reader.onerror = function (error) {
                        alert('Failed to upload PDF!');
                        that.pdfLoading = false;
                    };
                }
            } catch (err) {
                this.$toasted.error('Failed to upload PDF');
                this.pdfLoading = false;
            }
        },
        async uploadVideo(event) {
            let file = event.target.files[0];
            let file_extension = file.name.split('.').pop();
            if (file_extension != 'mp4' && file_extension != 'webm') {
                this.$toasted.error('Only video file formats(.mp4 & .webm) are allowed');
                return;
            }
            if (file.size / 1024 / 1024 > 20) {
                this.$toasted.error('Video size should be less than 20MB');
                return;
            }
            this.videoLoading = true;
            try {
                if (file != null) {
                    let that = this;
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async function () {
                        let formData = new FormData();
                        formData.append('file', file);
                        formData.append('type', 'generic_module_video');

                        const endpoint = dashboardBuilder.uploadAssets();
                        let response = await that.$api.post(endpoint, formData);

                        if (response.data.success) {
                            that.functionInfo.file_id = response.data.s3_link;
                            let res = await that.geturl(response.data.s3_link);
                            that.functionInfo.url = res;
                            that.videoLoading = false;
                        }
                    };
                    reader.onerror = function (error) {
                        alert('Failed to upload logo !');
                        that.videoLoading = false;
                    };
                }
            } catch (err) {
                this.$toasted.error('Failed to upload PDF');
                this.videoLoading = false;
            }
        },
        addPhoneNumber() {
            if (this.phoneNumber == '' || this.phoneNumber.length != 10) {
                return this.$toasted.error('Phone number should contains only 10 digits');
            }
            if (this.isEditPhoneNumber) {
                this.functionInfo.phoneNumbers[this.phoneNumberIndex] = this.phoneNumber;
            } else {
                let isPhoneNumberExists = this.functionInfo.phoneNumbers.filter(
                    (phoneNumber) => phoneNumber == this.phoneNumber
                ).length;
                if (isPhoneNumberExists) {
                    return this.$toasted.error('Phone number already exist');
                }
                this.functionInfo.phoneNumbers.push(this.phoneNumber);
            }
            this.phoneNumber = '';
            this.phoneNumberIndex = null;
            this.isEditPhoneNumber = false;
        },
        editPhoneNumber(index) {
            this.phoneNumber = this.functionInfo.phoneNumbers[index];
            this.phoneNumberIndex = index;
            this.isEditPhoneNumber = true;
        },
        deletePhoneNumber(index) {
            this.functionInfo.phoneNumbers.splice(index, 1);
            if (this.isEditPhoneNumber) {
                this.phoneNumber = '';
                this.isEditPhoneNumber = false;
            }
        },
        handleDTMS(event) {
            this.functionInfo.enabledtms = event.target.checked;
        },
        addDtmsSettings() {
            if (this.dtmsDigit == '' || this.dtmsDelay == '') {
                return this.$toasted.error('Please fill required fields');
            }
            this.functionInfo.dtmsSettings.unshift({
                delay: this.dtmsDelay,
                digit: this.dtmsDigit,
            });
            this.dtmsDelay = '';
            this.dtmsDigit = '';
        },
        removeDtmsSettings(index) {
            this.functionInfo.dtmsSettings.splice(index, 1);
        },
        selectColorScheme(event) {
            let colors;
            if (this.applicationFrame == 'kiosk') {
                switch (event.target.value) {
                    case 'primary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                            customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                    case 'secondary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'secondary_colors',
                            customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                        };
                        colors = this.colorScheme.secondary_colors;
                        break;
                    case 'customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'customized_colors',
                        };
                        colors = this.colorScheme.customized_colors;
                        break;
                    case 'module_customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'module_customized_colors',
                        };
                        colors = this.colorScheme.module_customized_colors;
                        break;
                    default:
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                }

                this.functionInfo.primaryColor = colors[0];
                this.functionInfo.secondaryColor = colors[1];
                this.functionInfo.color = colors[2];
                this.functionInfo.textColor = colors[3];

                this.renderSvg(this.svgElement, this.functionInfo.secondaryColor);
                this.isModuleLogoUpdated = true;
            }
        },
        selectCustomColorScheme(event, colorNo) {
            let color = event.target.value;
            if (this.applicationFrame == 'kiosk') {
                switch (colorNo) {
                    case 0:
                        this.functionInfo.primaryColor = color;
                        break;
                    case 1:
                        this.functionInfo.secondaryColor = color;
                        this.isModuleLogoUpdated = true;
                        this.renderSvg(this.svgElement, this.functionInfo.secondaryColor);
                        break;
                    case 2:
                        this.functionInfo.color = color;
                        break;
                    case 3:
                        this.functionInfo.textColor = color;
                        break;
                }
            }
        },
        async uploadModuleLogo() {
            try {
                let filename = 'module-logo.svg';
                let svgElement = this.svgImg;

                const svgData = new XMLSerializer().serializeToString(svgElement);

                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                const formData = new FormData();
                formData.append('file', svgFile);

                const endpoint1 = memberProfile.uploadProfileFunctionsImage();
                const uploadImgResponse = await this.$api.post(endpoint1, formData);
                if (!uploadImgResponse.data.success) {
                    throw uploadImgResponse.data.error;
                }

                this.functionInfo.image = uploadImgResponse.data.s3_link;
                return true;
            } catch (err) {
                this.$toasted.error('Failed to upload Image');
            }
            return false;
        },
    },

    async created() {
        this.Position = this.position;
        this.functionInfo.phoneNumbers = []; // Empty Phone Number List
        if (this.showAppListing) {
            if (this.applicationFrame == 'mobex-health-hub') {
                // this.functionInfo.color = '#5155C3';
                // this.functionInfo.textColor = '#FFFFFF';

                const { colorScheme } = this.pageData.page3;

                this.functionInfo.color = colorScheme.colors[0][0];
                this.functionInfo.textColor = colorScheme.colors[0][1];
            } else if (this.applicationFrame == 'kiosk') {
                console.log('page data on ceated', this.pageData);
                const { colorScheme } = this.pageData.page3;
                const { selectedOption } = colorScheme;
                this.colorScheme = { ...this.colorScheme, ...colorScheme };
                this.functionInfo.selectedSchemeOption = this.colorScheme.selectedOption;
                this.isCustosmizedThemeSelected = this.functionInfo.selectedSchemeOption == 'customized_colors';
                this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                this.functionInfo.color = colorScheme[selectedOption][2];
                this.functionInfo.textColor = colorScheme[selectedOption][3];
            } else {
                this.functionInfo.color = this.pageData.page3.colorScheme.colors[0];
                this.functionInfo.textColor = this.pageData.page3.colorScheme.colors[1];
            }

            if (this.clickedProfileFunction == 'Dashboard') {
                await this.getAllCompanies();
                await this.getAllDashboards();
            }
            await this.get3rdPartyFunctionWidgets();
            await this.fetchApplications();
            await this.getGenericImages();
        }
    },
};
</script>
<style scoped>
.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.img-loader-main {
    min-height: 30px;
}

.pp-5 {
    padding: 5% !important;
}

.img-loader {
    height: 0.9em;
    width: 0.9em;
    font-weight: 600;
}

.img-loader-text {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--base-theme-color);
}

.scroll-wrapper {
    background-color: #b4cff1f6;
    margin-bottom: 5%;
    width: 100%;
    text-align: -webkit-center;
}

.scroll-container {
    width: 80%;
    overflow: auto;
    white-space: nowrap;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.active-bg {
    width: 50px !important;
    height: 50px !important;
    border: 2px solid lime;
    padding: 2px;
}

.color {
    accent-color: var(--base-theme-tab-color);
}

.checkbox-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 30%;
    gap: 10px;
}

.upload-section {
    margin: 0 15% 0 0;
    border-radius: 10px;
    border: 2px solid var(--grey, #7d7d7d);
    background: #fff;
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    cursor: pointer;
}

.upload-btn {
    border-radius: 6px;
    background: #5155c3;
    color: #fff;
    font-family: 'Roboto';
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
}

.show-phone-number-list {
    max-height: 100px;
    height: 100px;
    overflow: auto;
}

.show-phone-number-list::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

.show-phone-number-list::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
.show-phone-number-list::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

.color-box {
    width: 45%;
    border-radius: 10px;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.12);
    /*box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);*/
}

/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>

<style>
.lumeca-form input[type='number'] {
    border-radius: 8px !important;
}

.lumeca-form input[type='number']::-webkit-inner-spin-button,
.lumeca-form input[type='number']::-webkit-outer-spin-button {
    opacity: 0;
}

.lumeca-form input[type='number']::placeholder {
    color: #999;
}

.default-color {
    height: 1.93rem;
    width: 4.24rem;
    background-color: #5858be;
}
</style>
