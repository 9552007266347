import { render, staticRenderFns } from "./customised_theme_design.vue?vue&type=template&id=7f175606&scoped=true&"
import script from "./customised_theme_design.vue?vue&type=script&lang=js&"
export * from "./customised_theme_design.vue?vue&type=script&lang=js&"
import style0 from "./customised_theme_design.vue?vue&type=style&index=0&id=7f175606&prod&scoped=true&lang=css&"
import style1 from "./customised_theme_design.vue?vue&type=style&index=1&id=7f175606&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f175606",
  null
  
)

export default component.exports