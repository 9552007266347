<template>
    <div class="w-100 my-5">
        <div class="main-container">
            <div class="bg-white rounded-4 border mx-2 widget-list" style="height: 630px; overflow-y: auto;">
                <h1 class="px-4 py-3 heading">Selected Modules widgets</h1>
                <draggable class="p-4 widgets-container" :list="widgetsList"
                    :group="{ name: 'myGroup', pull: true, put: false }" @start="onDragStart" @end="onDragEnd">
                    <div v-for="(element, index) in widgetsList" :key="element.id" :id="index" class="col-4 "
                        style="display: flex; justify-content: center;">
                        <div v-if="element.function_type == 'generic'" class="widget">
                            <img :src="element.image" alt="" style="margin: auto;">
                        </div>
                        <div v-else>
                            <img :src="element.image" alt="" style="margin: auto; width: 9rem;">
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="bg-white rounded-4 border mx-2 dash-builder" style="width: 50%; height: 630px; overflow-y: auto;">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="px-4 py-3 heading" style="width: 100%;">Dashboard Builder {{ selectedOption }}</h1>
                </div>
                <div class='droppable-template' v-if="templateStyles">
                    <div :style="templateStyles.primeContainer">
                        <div :style="templateStyles.bgImageContainer" style="background-color: #E2E2E2;">
                            <!-- <img class="" :style="templateStyles.bgImage" :src="templateStyles.bgImageUrl" /> -->
                        </div>
                        <div
                            style="position: absolute; top: 0; left: 0; width:100%;height:10rem;background: #484CBE;border-radius: 0 0 5rem 5rem;">
                            <div
                                style="height:4.3rem; width: 100%; left: 0px;  text-align: center;display: flex;  align-items: center; ">
                                <div class="d-flex w-10 justify-content-start">
                                    <div class="d-flex justify-content-start" style="margin-left: 15px;">
                                        <img src="@/assets/px/drag-and-drop/menu-icon.svg" alt=""
                                            style="width:30px; height:30px;filter: invert(1) grayscale(100%) brightness(200%)">
                                    </div>
                                </div>
                                <div class="w-80 d-flex mx-" style="color:white;font-weight: 600;font-size: 25px;">
                                    Dashboard</div>
                                <!-- <div class="d-flex w-80 justify-content-left" style="padding-left: 10px;">
                                    <img :src="logo" class="d-flex" alt="" style=" max-height: 30px; max-width: 150px;">
                                </div> -->
                                <div class="d-flex w-10">
                                    <img src="@/assets/px/header-icons-images/notification.svg" alt=""
                                        style="height: 25px;width:25px;filter: invert(1) grayscale(100%) brightness(200%);" />
                                </div>
                            </div>
                            <div
                                style="font-weight: 700;color: white;display: flex;justify-content: center;font-size: 25px;margin-top: 10px;align-items: center;gap: 10px;">
                                Hello, Anni Sara
                                <img src="@/assets/px/header-icons-images/hello.svg" alt=""
                                    style="width: 40px;height: 40px;" />
                            </div>
                        </div>
                        <div class='t-m-c' :style="templateStyles.templateMainContainer + 'margin-top: 5rem'">
                            <div class='u-l-c' :style="templateStyles.upperLayoutContainer">
                                <div v-for="(item, index) in Object.entries(template.LI)"
                                    :class="(item[1].width == width && item[1].height == height && !item[1].elements.length) ? ' highlighted-section ' + item[1].class : '' + item[1].class"
                                    :style="templateStyles.upperLayoutContainerItemCommon + item[1].style + 'height: 10rem !important; width: 11rem !important; border-radius:2.5rem !important;height: 10rem;width: 11rem; border-radius: 2.5rem;'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list" :list="template['LI'][item[0]].elements"
                                        :group="template['LI'][item[0]].elements.length ? { name: 'myGroup', pull: true, put: false } :{ name: 'myGroup', pull: true, put: true }"
                                        @start="onMoveStart" @end="onMoveEnd"
                                        @change="manipulateWidgets($event, 'LI', item[1].name, 'add', item, index)"
                                        :options="{ accept: false }">
                                        <div :class="value.class" class="module"
                                            v-for="value in template['LI'][item[0]].elements" :key="value.name"
                                            @mouseover="hoveredElementId = 'LI' + index"
                                            @mouseleave="hoveredElementId = null"
                                            @click="addDetails(value.function_name, index)">
                                            <div class='widget-img'
                                                :style="(value.function_type == 'generic' && template['LI'][item[0]].title.length == 0) ? 'height: 100%' : 'height: 7rem'">
                                                <img :src="value.image" alt="">
                                            </div>
                                            <div class="widget-title" style="">
                                                <p v-if="value.function_type == 'generic' && template['LI'][item[0]].title">
                                                    {{ template['LI'][item[0]].title }}</p>
                                                <p v-else-if="value.function_type == '3rdParty'">{{ value.function_name }}
                                                </p>
                                            </div>
                                            <template v-if="hoveredElementId == 'LI' + index">
                                                <div class="w-100 h-100 action-buttons">
                                                    <span class="background"
                                                        @click="manipulateWidgets($event, 'LI', item[1].name, 'remove', value, index)"
                                                        id="delete">
                                                        <img src="@/assets/px/delete-icon.png" alt=""
                                                            style="height: 18px;" />
                                                    </span>
                                                    <b-tooltip target="delete" triggers="hover"
                                                        placement="bottom">Delete</b-tooltip>
                                                    <span class="background" v-if="value.function_type == 'generic'"
                                                        @click="OpenEditModal(value.function_name, index)" id="edit">
                                                        <img src="@/assets/px/edit-icon.png" alt="" style="height: 18px;" />
                                                    </span>
                                                    <b-tooltip target="edit" triggers="hover"
                                                        placement="bottom">Edit</b-tooltip>
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>

                            <div v-if="rssActive" class='g-l-c'
                                style="z-index: 20;background-color: rgb(255, 255, 255);border-radius: 2.5rem;color: rgb(81, 85, 195);margin-top: 2rem;">
                                <div v-for="(item, index) in Object.entries(this.template.GSI)"
                                    :class="(item[1].width == width && item[1].height == height) ? ' highlighted-section ' : ''"
                                    :style="templateStyles.upperLayoutContainerItemCommon + 'min-height:14rem;position: unset !important; margin: auto;border-radius:2.5rem'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list" :list="template['GSI'][item[0]].elements"
                                        :group="template['GSI'][item[0]].group ? template['GSI'][item[0]].group : { name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'GSI', item[1].name, 'add')"
                                        style="min-height:14rem" :options="{ accept: false }">
                                        <div :class="value.class" v-for="value in template['GSI'][item[0]].elements"
                                            :key="value.name"
                                            style="position: relative; text-align: -webkit-center; height: 100%;"
                                            @mouseover="hoveredElementId = 'GSI' + index"
                                            @mouseleave="hoveredElementId = null"
                                            @click="addDetails(value.function_name, index)">
                                            <img src="@/assets/photos/RSS Feed.png" alt="" style="border-radius: 2.5rem;">
                                            <template v-if="hoveredElementId == 'GSI' + index">
                                                <div class="w-100 h-100 action-buttons">
                                                    <span class="background"
                                                        @click="manipulateWidgets($event, 'GSI', item[1].name, 'remove', value, index)"
                                                        id="delete">
                                                        <img src="@/assets/px/delete-icon.png" alt=""
                                                            style="height: 15px;" />
                                                    </span>
                                                    <b-tooltip target="delete" triggers="hover"
                                                        placement="bottom">Delete</b-tooltip>
                                                    <span class="background" v-if="value.function_type == 'generic'"
                                                        id="edit" @click="openRssModal = true">
                                                        <img src="@/assets/px/edit-icon.png" alt="" style="height: 15px;" />
                                                    </span>
                                                    <b-tooltip target="edit" triggers="hover"
                                                        placement="bottom">Edit</b-tooltip>
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-delete-modal">
            <AlertChangeTemplate v-if="openConfirmModal" :open="openConfirmModal" @cancel="openConfirmModal = false"
                @handleChangeTemplate="handleChangeTemplate">
            </AlertChangeTemplate>
        </div>
        <div class="builddashboard">
            <DashboardBuilderModal v-if="openLauncherModal" :open="openLauncherModal" @close="close"
                :selected="selectedOption" @selection-changed="updateSelectedOption" :pageData="pageData"
                :applicationFrame="applicationFrame" />
        </div>
        <div class="addmoduledetail">
            <AddDetailModal v-if="openEditModal" :open="openEditModal" @close="closedetail"
                :showSaveSetting="showSaveSetting" :clickedProfileFunction="clickedProfileFunction" :position="position"
                :pageData="pageData" :applicationFrame="applicationFrame" />
        </div>
        <RssFeedEditModal v-if="openRssModal" @cancel="openRssModal = false" :rssFeedsDetails="rssFeedsDetails"
            @captureRssFeed="updateRssFeed" />

        <Loading v-if="loading" />
    </div>
</template>
<script>
import { dashboardBuilder, memberProfile } from '@/util/apiRequests';
import draggable from "vuedraggable";
import DashboardBuilderModal from '@/components/dashboard/DashboardBuilderModal.vue';
import AlertChangeTemplate from './ChangeTemplateAlert.vue';
import AddDetailModal from '../../components/company/member-profiles/AddGenericFunctionDetail.vue'
import Loading from '@/components/general/loading/loading.vue';
import RssFeedEditModal from './Rss_feed_edit_modal.vue'

const themeColor = ['#FFFFFF', '#5155C3', '#4CBC9A']

export default {
    name: "BuildStarKidDashboard",
    components: {
        draggable,
        DashboardBuilderModal,
        AlertChangeTemplate,
        AddDetailModal,
        RssFeedEditModal,
        Loading
    },
    props: {
        pageData: {
            type: Object
        },
        current_template: {
            type: Number
        },
        applicationFrame: {
            type: String
        },
        rssFeedsDetails: {
            type: Object
        }
    },
    data() {
        return {
            selectedOption: this.current_template,
            templateStyles: null,
            logo: null,
            template: null,
            widgetsList: [],
            isHighlighted: false,
            inp: this.value,
            ref_span: null,
            openLauncherModal: false,
            openEditModal: false,
            openConfirmModal: false,
            templateData: {},
            templateDataList: [],
            height: '',
            width: '',
            showSaveSetting: false,
            showButtonsLI: [],
            showButtonsGSI: [],
            hoveredElementId: null,
            allWidgets: [],
            clickedProfileFunction: "",
            position: "",
            elementDragStartId: "",
            openRssModal: false,
            rssActive: false,
            loading: true,
            isItemMoved: false,
            movedWidget: null,
            movedWidgetIndex: null,
        };
    },
    methods: {

        addDetails(val, index) {
            let i = index + 1;
            this.position = i + "";
            this.clickedProfileFunction = val;
        },
        OpenEditModal(val, index) {
            let i = index + 1;

            this.position = i + "";
            this.clickedProfileFunction = val;
            this.openEditModal = true;
        },
        closedetail() {
            this.openEditModal = false;
        },
        async geturl(fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [fileId]
            }
            let response = await this.$api.post(endpoint, requestData);
            return response?.data?.data[0].image;

        },
        async deleteApplication(element, index) {

            let i = index + 1;
            this.position = i + "";
            this.clickedProfileFunction = element.function_name;

            let info;
            if (this.$route.query.id) {

                const endpoint = memberProfile.getSpecificGenericFunction(this.clickedProfileFunction, this.position, this.$route.query.id);
                const functionResponse = await this.$api.get(endpoint);
                if (!functionResponse) {
                    throw 'e';
                }
                info = functionResponse.data;
            }
            else {

                const lastidendpoint = dashboardBuilder.getLastTemplateId();
                const res = await this.$api.get(lastidendpoint);
                if (res.data.success) {
                    const endpoint = memberProfile.getSpecificGenericFunction(this.clickedProfileFunction, this.position, res.data.response.AUTO_INCREMENT);
                    const functionResponse = await this.$api.get(endpoint);
                    if (!functionResponse) {
                        throw 'e';
                    }

                    info = functionResponse.data;
                }

            }
            if (info.info.length) {
                try {
                    this.loading = true;
                    const endpoint = memberProfile.deleteGenericModule(info.info[0].id, this.clickedProfileFunction, info.info[0].application);
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }
                    this.$toasted.success('Application deleted successfully !');

                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            }


        },
        showButton(index, label) {
            if (label == 'LI') {
                this.$set(this.showButtonsLI, index, true);
            }
            else {
                this.$set(this.showButtonsGSI, index, true);
            }
        },
        hideButton(index, label) {
            if (label == 'LI') {
                this.$set(this.showButtonsLI, index, false);
            }
            else {
                this.$set(this.showButtonsGSI, index, false);
            }
        },
        handleChangeTemplate() {
            this.openLauncherModal = true;
            this.openConfirmModal = false;
        },
        async getTemplateMetaData() {
            let metaData = await this.$api.get(dashboardBuilder.getTemplateMeta(this.selectedOption));
            let templateMeta = JSON.parse(metaData.data.templateMeta);
            let _template = templateMeta.templateItems;
            this.template = _template;

            Object.keys(this.template).forEach(group => {
                let groupElements = Object.keys(this.template[group])
                this.templateData[group] = {}
                groupElements.forEach(groupEl => {
                    this.templateData[group][groupEl] = undefined;
                })
            });


            this.templateStyles = templateMeta.templateCommonStyles;
            this.$emit('captureValues', { 'page4': { 'template': this.template, 'templateStyles': this.templateStyles } });
            return true;
        },
        manipulateWidgets(event, section, listName, operation, element, index) {

            if (operation === 'remove') {

                if (element.function_type == '3rdParty') {
                    element.class = 'list-item removed';
                    this.widgetsList.push(element);
                    this.template[section][listName].title = "";
                    this.template[section][listName].elements.pop();
                    this.templateData[section][listName] = undefined;
                    this.$set(this.template[section][listName], 'group', undefined);
                }
                else {

                    this.deleteApplication(element, index);

                    if (element.function_name == 'RSS Feed') {
                        this.$emit('captureRssFeed', null);
                    }

                    if (this.widgetsList.length >= 1) {
                        element.class = 'list-item removed';
                        this.template[section][listName].elements.pop();
                        this.template[section][listName].title = "";

                        if (this.template[section][listName].style) {

                            this.template[section][listName].style = this.template[section][listName].style.replace(/background-color:[^;]*/, `background-color:${this.pageData.page3.colorScheme.colors[0]}`);

                            const styleArray = this.template[section][listName].style?.split(';') ?? 0;
                            for (let i = 0; i < styleArray.length; i++) {
                                const propertyValue = styleArray[i].split(':');
                                if (propertyValue[0].trim() === 'color') {
                                    styleArray[i] = `color:${this.pageData.page3.colorScheme.colors[1]}`;
                                    break;
                                }
                            }
                            // Join the modified style array back into a string
                            this.template[section][listName].style = styleArray.join(';');
                        }

                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                        return;
                    }

                }

            }
            else {

                if(event.removed != undefined){
                    if(isNaN(this.movedWidgetIndex)){
                        this.template[section][listName].elements.push(event.removed.element);
                        this.templateData[section][listName] = event.removed.element;
                        return;
                    }
                    this.moveWidget(event,section, listName, index);
                    return;
                }

                if(this.isItemMoved){    
                    this.movedWidget = listName;
                    this.movedWidgetIndex = index + 1;
                }

                let newlyAdded = operation === 'add' ? event.added.element : null;

                if (!newlyAdded.function_type) {
                    delete newlyAdded.company_id;
                    delete newlyAdded.created_at;
                    delete newlyAdded.updated_at;
                }



                newlyAdded['function_type'] == !newlyAdded.function_type ? 'core' : newlyAdded.function_type;

                if (operation === 'add') {

                    if (event.added.element.function_type == 'generic' && !this.isItemMoved) {
                        this.widgetsList.splice(this.elementDragStartId, 0, {
                            ...event.added.element,
                            "id": event.added.element.id + 1
                        });
                    }


                    if (this.template[section][listName].elements.length > 1) {
                        this.template[section][listName].elements.splice(1);
                        newlyAdded.class = 'list-item removed';
                        this.widgetsList.unshift(newlyAdded);
                    }
                    else if (newlyAdded.width != this.template[section][listName].width && newlyAdded.height != this.template[section][listName].height) {
                        this.template[section][listName].elements.splice(0);
                        newlyAdded.class = 'list-item removed';
                        if (event.added.element.function_type != 'generic' && !this.isItemMoved) {
                            if (newlyAdded.function_type == 'generic') {
                                this.widgetsList.unshift(newlyAdded);
                            } else if (newlyAdded.function_type == 'core') {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                this.widgetsList.splice(idx, 0, {
                                    ...newlyAdded,
                                });
                            } else {
                                this.widgetsList.push(newlyAdded);
                            }
                        }
                    }
                    else {
                        this.$set(this.template[section][listName], 'group', { name: 'myGroup', pull: false, put: false });
                        this.templateData[section][listName] = newlyAdded;

                    }
                }

            }


            this.$emit('captureValues', { 'page4': { templateData: this.templateData } });
        },
        async updateSelectedOption(option) {
            if (this.selectedOption != option) {
                this.selectedOption = option;
                this.$emit('selection-changed', this.selectedOption);
                await this.getTemplateMetaData();
                await this.getWidgets();
                this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
            }
        },
        onDragStart(event) {

            this.elementDragStartId = event.item.id;
            let element = event.item._underlying_vm_;
            this.height = element.height;
            this.width = element.width;

        },
        onDragEnd() {

            this.height = null;
            this.width = null;
        },
        onMoveStart(event) {

            this.isItemMoved = true;
            this.onDragStart(event);
 
        },
        onMoveEnd() {
 
            this.isItemMoved = false;
            this.onDragEnd();
 
        },
        async moveWidget(event, section, listName, removedPosition) {
 
            try{
                const endpoint1 = await memberProfile.getSpecificGenericFunction(
                    event.removed.element.function_name,
                    removedPosition + 1,
                    this.$route.query.id,
                );

                const functionResponse = await this.$api.get(endpoint1);

                if(functionResponse?.data?.info?.length){

                    let moduleId = functionResponse?.data?.info[0].id;
                    const endpoint = memberProfile.updateGenericModule(moduleId);
    
                    const saveFunctionResponse = await this.$api.put(endpoint, {
                        application: this.applicationFrame,
                        generic_function: event.removed.element.function_name,
                        position: this.movedWidgetIndex + ''
                    });
    
                    this.template[section][this.movedWidget].title = this.template[section][listName].title;
                    this.template[section][listName].elements.pop();
                    this.template[section][listName].title = "";
                    this.templateData[section][listName] = undefined;
                    this.$set(this.template[section][listName], 'group', undefined);
                }

            }catch(err){
                this.$toasted.error("Failed to update Application");
            }
 
        },
        close() {
            this.openLauncherModal = false;
        },
        async getWidgets() {
            const response = await Promise.all([this.getGenericWidgets(), this.get3rdPartyFunctionWidgets()])
            this.widgetsList = this.widgetsList.concat(response[0]);
            this.widgetsList = this.widgetsList.concat(response[1]);
        },
        async getGenericWidgets() {

            const requestData = {
                functionNames: this.pageData.page2.genericFunctions.map(el => el.function_name),
                application: this.applicationFrame
            }
            this.rssActive = requestData.functionNames.includes('RSS Feed');
            const endpoint = dashboardBuilder.getWidgets();
            let response = await this.$api.post(endpoint, requestData);
            let res = response.data.data.map(el => {
                let data = {
                    ...el,
                    function_type: 'generic'
                }
                return data;
            });
            return res;
        },
        async get3rdPartyFunctionWidgets() {
            if (this.pageData.page2.thirdPartyFunctions) {
                const requestData = {
                    functionIds: this.pageData.page2.thirdPartyFunctions.map(el => el.id)
                }
                const endpoint = memberProfile.getFunctionDetailsByIds();
                let response = await this.$api.post(endpoint, requestData);
                let thirdPartyWidgets = response.data.data.map(el => {
                    let data = {
                        ...el,
                        height: '230',
                        width: '230'
                    }
                    return data;
                })
                return thirdPartyWidgets;
            }
            else {
                return [];
            }
        },
        updateRssFeed(data) {
            this.$emit('captureRssFeed', { ...data });
        }
    },
    watch: {
    },
    async created() {

        window.scrollTo(0, 0);

        await this.getWidgets();

        const widgets = this.widgetsList.map(el => el.id)
        this.allWidgets = this.widgetsList;
        const widgetsToRemoveIndexs = [];

        if (this.pageData?.page3?.logo?.fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [this.pageData?.page3?.logo?.fileId]
            }
            let response = await this.$api.post(endpoint, requestData);
            this.logo = response?.data?.data[0].image;

        }
        if (this.pageData.page4 && this.pageData.page4.template && this.pageData.page4.templateStyles && this.pageData.page4.templateData) {
            const { template, templateData, templateStyles } = this.pageData.page4;
            if (template) {
                this.template = template;
            }
            if (templateStyles) {
                this.templateStyles = templateStyles;
            }
            if (templateData) {
                this.templateData = templateData;
            }
            // this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
            Object.keys(this.template).forEach(parentEl => {
                if (!this.template[parentEl]) {
                    this.templateData[parentEl] = {};
                }
                Object.keys(this.template[parentEl]).forEach(childEl => {
                    if (!this.templateData[parentEl][childEl]) {
                        this.templateData[parentEl][childEl] = {};
                    }
                    if (this.templateData[parentEl][childEl].function_type != 'generic') {

                        if (this.templateData[parentEl][childEl]) {
                            let i = widgets.indexOf(this.templateData[parentEl][childEl].id);
                            if (i > -1) {
                                this.templateData[parentEl][childEl].image = this.widgetsList[i].image;
                                this.$set(this.template[parentEl][childEl], 'elements', [this.templateData[parentEl][childEl]]);
                                widgetsToRemoveIndexs.push(this.templateData[parentEl][childEl].id);
                                this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: false });
                            }
                            else {
                                this.$set(this.template[parentEl][childEl], 'elements', []);
                                this.templateData[parentEl][childEl] = undefined;
                                this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: true });
                            }
                        }
                        else {
                            this.$set(this.template[parentEl][childEl], 'elements', []);
                            this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: true });
                        }
                    }

                })
            });


            widgetsToRemoveIndexs.forEach(id => {
                const i = this.widgetsList.findIndex(el => el.id == id);
                this.widgetsList.splice(i, 1);
            })

            Object.entries(this.template.LI).forEach(async element => {
                let elements = this.template.LI[element[0]].elements[0];
                if (this.template.LI[element[0]].elements.length != 0) {
                    let image_link = await this.geturl(elements.image_id);
                    elements.image = image_link;
                }
            });

            // Object.keys(this.template).forEach(group => {
            //     if (group === 'LI') {
            //         Object.keys(this.template[group]).forEach(li => {

            //             const liStyle = this.template[group][li].style;

            //             if (liStyle.includes('background-color')) {
            //                 const liBackgroundColor = this.template[group][li].style.match(/background-color:([^;]*)/i)[1];

            //                 if (liBackgroundColor != themeColor[0] && liBackgroundColor != themeColor[1] && liBackgroundColor != themeColor[2]) {

            //                     this.template[group][li].style = liStyle.replace(/background-color:[^;]*/, `background-color:${liBackgroundColor}`);

            //                 }
            //                 else {
            //                     this.template[group][li].style = liStyle.replace(/background-color:[^;]*/, `background-color:${this.pageData.page3.colorScheme.colors[0]}`);

            //                 }

            //             }
            //             else {
            //                 this.template[group][li].style += `background-color:${this.pageData.page3.colorScheme.colors[0]};`;

            //             }

            //         });
            //     }

            // });

            // Object.keys(this.template).forEach(group => {
            //     if (group === 'LI') {
            //         Object.keys(this.template[group]).forEach(li => {
            //             //this.template[group][li].style = this.template[group][li].style.replace(/(;|^)\s*color\s*:[^;]*;/, `color:${this.pageData.page3.colorScheme.colors[1]}`);
            //             let colorText = 0;
            //             const liStyle = this.template[group][li].style;

            //             const styleArray = liStyle.split(';');

            //             for (let i = 0; i < styleArray.length; i++) {
            //                 const propertyValue = styleArray[i].split(':');
            //                 if (propertyValue[0].trim() === 'color') {
            //                     colorText = 1;
            //                     let textColor = propertyValue[1].trim();
            //                     if (textColor != themeColor[0] && textColor != themeColor[1] && textColor != themeColor[2]) {
            //                         styleArray[i] = `color: ${textColor}`;
            //                     }
            //                     else {
            //                         styleArray[i] = `color: ${this.pageData.page3.colorScheme.colors[1]}`;
            //                     }
            //                     break; // No need to continue the loop once the color property is found
            //                 }
            //             }

            //             // Join the modified style array back into a string
            //             this.template[group][li].style = styleArray.join(';');

            //             if (!colorText) {
            //                 this.template[group][li].style += `color:${this.pageData.page3.colorScheme.colors[1]};`;
            //                 colorText = 0;
            //             }
            //         });
            //     }

            // });


        }
        else {
            await this.getTemplateMetaData();
            // this.templateStyles.bgImageUrl = this.pageData.page3.background.image;

            // Object.keys(this.template).forEach(group => {
            //     if (group === 'LI') {
            //         Object.keys(this.template[group]).forEach(li => {

            //             const liStyle = this.template[group][li].style;


            //             // Check if background-color is already present in the style
            //             if (liStyle.includes('background-color')) {
            //                 // Background-color is already present
            //                 const liBackgroundColor = this.template[group][li].style.match(/background-color:([^;]*)/)[1];
            //                 this.template[group][li].style = liStyle.replace(/background-color:[^;]*/, `background-color:${liBackgroundColor}`);

            //                 const styleArray = liStyle.split(';');

            //                 for (let i = 0; i < styleArray.length; i++) {
            //                     const propertyValue = styleArray[i].split(':');
            //                     if (propertyValue[0].trim() === 'color') {
            //                         // Replace the color property
            //                         let textColor = propertyValue[1].trim();
            //                         styleArray[i] = `color: ${textColor}`;
            //                         break; // No need to continue the loop once the color property is found
            //                     }
            //                 }

            //                 // Join the modified style array back into a string
            //                 this.template[group][li].style = styleArray.join(';');
            //             } else {
            //                 // Background-color is not present, add it
            //                 this.template[group][li].style += `background-color:${this.pageData.page3.colorScheme.colors[0]};`;

            //                 this.template[group][li].style += `color:${this.pageData.page3.colorScheme.colors[1]};`;

            //             }

            //         });
            //     }

            // });
        }
        this.loading = false;
    },
};
</script>
<style scoped>
.main-container {
    display: flex;
    justify-content: space-between;
}

.widgets-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 35px;
}

.action-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    top: 0;
    background: rgb(89, 90, 194, 0.85);
    border-radius: 2.5rem;
}

.background {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8F0FD;
}

.module {
    position: relative;
    text-align: -webkit-center;
    height: 100%;
}

.widget-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-img>img {
    max-height: 80%;
    max-width: 80%;
}

.widget-title>p {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: medium;
    text-align: center;
}


.widget-list {
    width: 45%;
    min-width: 18rem;
    border: 1px solid rgb(193, 187, 187);
    display: flex;
    flex-direction: column;
}

.widget {
    width: 11rem;
    height: 10rem;
    background-color: white;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2.5rem;
    display: flex;
}

.heading {
    background: #F1F7FF;
    font-weight: bold;
    border-radius: 14px 14px 0 0;
    margin-bottom: 0;
}

.draggable-list {
    height: 100%;
    width: 100%;
}

.highlighted-section {
    border: 4px dotted #1fcf25;
}

.list-item {
    height: 100%;
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>