<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1 class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0">
                Theme Customization
            </h1>
            <div v-if="this.applicationFrame == 'family-connect'" class="d-flex flex-wrap p-3 mx-4">
                <div class="w-50">
                    <h3 style="font-weight: 700">Company logo*</h3>
                    <input type="file" name="company_logo" id="company_logo" placeholder="Upload Company logo" hidden
                        @change="uploadImage($event, 'dashboard_builder_logo')" accept=" .jpg, .webp, .png" />
                    <label for="company_logo" class="d-flex justify-content-between align-items-center upload-section">
                        <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company Logo</span>
                        <span v-if="companyLogoLoading" class="upload-btn px-5 py-3" style="
                                display: flex;
                                gap: 10px;
                                padding-left: 15px !important;
                                padding-right: 15px !important;
                            ">
                            <SpinLoader />Uploading
                        </span>
                        <span v-else class="upload-btn px-5 py-3">Upload</span>
                    </label>
                    <span v-if="companyLogoErr" style="color: red">{{ companyLogoErr }}</span>
                    <span style="color: red">{{ errors.logo }}</span>

                    <div class="d-flex justify-content-between align-items-center my-3">
                        <img :src="companyLogo.image" style="width: 100px; height: auto; margin: 0 15% 0 0"
                            v-if="companyLogo && companyLogo.fileId" />
                        <img src="@/assets/px/fc_logo.png" alt="FamilyConnect" title="FamilyConnect"
                            style="width: 100px; height: auto; margin: 0 15% 0 0" v-else />
                    </div>
                </div>
                <div class="w-50">
                    <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                    <div class="grid">
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Image extension should JPG, WEBP & PNG</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Resolution should not be less than 130/60px.</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Image size should be no more than 10 MB.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex flex-wrap p-3" style="gap: 0.5em">
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Select and Upload logo*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input type="file" name="company_logo" id="company_logo" placeholder="Upload Company logo"
                                hidden @change="uploadImage($event, 'dashboard_builder_logo')"
                                accept="image/,.jpg, .webp, .png" :disabled="companyLogoLoading" />
                            <label for="company_logo"
                                class="d-flex justify-content-between align-items-center upload-section">
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company Logo</span>
                                <span v-if="companyLogoLoading" class="upload-btn py-3 px-3 d-flex" style="gap: 10px">
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div v-for="logo in companyLogoImages" :key="logo.id"
                                    style="position: relative; text-align: -webkit-center">
                                    <img :src="logo.image"
                                        :class="companyLogo && logo.id == companyLogo.id ? 'active-bg' : ''"
                                        style="height: 100px; max-width: 100px; border-radius: 20px; cursor: pointer"
                                        @click="selectCompanyLogo(logo)" />
                                </div>
                            </div>
                        </div>
                        <span v-if="companyLogoErr" style="color: red">{{ companyLogoErr }}</span>
                        <span style="color: red">{{ errors.logo }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can choose only one file at a time.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extensions should JPG, WEBP and PNG.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-grid flex-wrap p-4" style="gap: 0.5em" v-if="this.applicationFrame == 'kiosk'">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Select Color Scheme*</h3>
                </div>
                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                <input type="radio" name="color_type_kiosk" id="primary_colors" value="primary_colors"
                                    style="transform: scale(0.8)" v-model="colorScheme.selectedOption"
                                    @change="selectColorScheme($event, 'kiosk')" />
                            </div>
                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                <div v-for="(value, index) in colorScheme.primary_colors"
                                    class="d-flex align-items-center px-2 w-25" :key="index" :style="{
                height: '30px',
                width: '50%',
                background: value,
                color: '#FFFFFF',
                'font-size': '12px',
                border: '2px solid black',
                'text-transform': 'uppercase',
            }">
                                    {{ value }}
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Primary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Secondary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Secondary Color Option</label>
                                <input type="radio" name="color_type_kiosk" id="secondary_colors"
                                    value="secondary_colors" style="transform: scale(0.8)"
                                    v-model="colorScheme.selectedOption" @change="selectColorScheme($event, 'kiosk')" />
                            </div>
                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                <div v-for="(value, index) in colorScheme.secondary_colors"
                                    class="d-flex align-items-center px-2 w-25" :key="index" :style="{
                height: '30px',
                width: '50%',
                background: value,
                color: '#FFFFFF',
                'font-size': '12px',
                border: '2px solid black',
                'text-transform': 'uppercase',
            }">
                                    {{ value }}
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Primary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Secondary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                <input type="radio" name="color_type_kiosk" id="customized_colors"
                                    value="customized_colors" style="transform: scale(0.8)"
                                    v-model="colorScheme.selectedOption" @change="selectColorScheme($event, 'kiosk')" />
                            </div>

                            <label class="d-flex py-2" style="gap: 1%" for="customized_colors">
                                <div v-for="(value, index) in colorScheme.customized_colors" :key="index"
                                    class="d-flex align-items-center px-2 w-25">
                                    <input v-if="colorScheme.selectedOption == 'customized_colors'" type="color" name=""
                                        id="" v-model="colorScheme.customized_colors[index]"
                                        @change="selectCustomColorScheme($event, index)" class="w-100 p-0" />
                                    <div v-else class="default-color w-100"> </div>
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Primary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Secondary Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Color
                                </div>
                                <div class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px">
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <span style="color: red">{{ errors.colorScheme }}</span>
            </div>

            <div class="d-grid flex-wrap p-4" v-if="this.applicationFrame == 'mobex-health-hub'" style="gap: 0.5em">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Color Scheme</h3>
                </div>
                <div class="d-flex flex-wrap" style="gap: 1%">
                    <div class="d-flex flex-wrap" style="gap: 1%">
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="default" style="font-weight: bold">Default Color</label>
                                    <input type="radio" name="color_type" id="default" value="default"
                                        v-model="colorScheme.selectedOption" style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)" />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="default">
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #595bc4; color: #fff; font-size: 10px">
                                        #595BC4
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #ac2734; color: #fff; font-size: 10px">
                                        #AC2734
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #4cbc9a; color: #fff; font-size: 10px">
                                        #4CBC9A
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="option1" style="font-weight: bold">Option 1</label>
                                    <input type="radio" name="color_type" id="option1" value="option1"
                                        v-model="colorScheme.selectedOption" style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)" />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="option1">
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #000; color: #fff; font-size: 10px">
                                        #000
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #3858e9; color: #fff; font-size: 10px">
                                        #3858E9
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #15ae80; color: #fff; font-size: 10px">
                                        #15AE80
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="option2" style="font-weight: bold">Option 2</label>
                                    <input type="radio" name="color_type" id="option2" value="option2"
                                        v-model="colorScheme.selectedOption" style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)" />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="option2">
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #2271b1; color: #fff; font-size: 10px">
                                        #2271B1
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #9ea476; color: #fff; font-size: 10px">
                                        #9EA476
                                    </div>
                                    <div class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #dd823b; color: #fff; font-size: 10px">
                                        #DD823B
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="customized_color" style="font-weight: bold">Customize Color
                                        Option</label>
                                    <input type="radio" name="color_type" id="customized_color" value="customized_color"
                                        v-model="colorScheme.selectedOption" style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)" />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%; flex-direction: column"
                                    for="customized_color">
                                    <div class="d-flex align-items-center py-1" style="height: 1px; font-size: 11px">
                                        Button Color
                                    </div>
                                    <div class="d-flex py-2" style="gap: 1%">
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[0][0]"
                                                @change="selectCustomColorScheme($event, 1)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[1][0]"
                                                @change="selectCustomColorScheme($event, 2)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[2][0]"
                                                @change="selectCustomColorScheme($event, 3)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-1" style="height: 1px; font-size: 11px">
                                        Button Text Color
                                    </div>
                                    <div class="d-flex py-2" style="gap: 1%">
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[0][1]"
                                                @change="selectCustomColorScheme($event, 4)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[1][1]"
                                                @change="selectCustomColorScheme($event, 5)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                        <div class="w-33">
                                            <input type="color" name="" id="" value="#5858BE" class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[2][1]"
                                                @change="selectCustomColorScheme($event, 6)" />
                                            <input type="color" name="" id="" :value="defaultColor" class="w-100"
                                                disabled v-else />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <span style="color: red">{{ errors.colorScheme }}</span>
                </div>
            </div>

            <div v-if="this.applicationFrame != 'family-connect'" class="d-flex flex-wrap p-3" style="gap: 0.5em">
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Select and Upload Background*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input type="file" name="background" id="background" placeholder="Upload Company logo"
                                hidden @change="uploadImage($event, 'dashboard_builder_background_image')"
                                accept="image/,.jpg, .webp, .png" />
                            <label for="background"
                                class="d-flex justify-content-between align-items-center upload-section">
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Background</span>
                                <span v-if="bgImageLoading" class="upload-btn py-3 px-3 d-flex" style="gap: 10px">
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div v-for="bg in backgroundImages" :key="bg.id"
                                    style="position: relative; text-align: -webkit-center">
                                    <img :src="bg.image"
                                        style="height: 100px; max-width: 100px; border-radius: 20px; cursor: pointer"
                                        :class="backgroundPhoto && bg.id == backgroundPhoto.id ? 'active-bg' : ''"
                                        @click="selectBackground(bg)" />
                                </div>
                            </div>
                        </div>
                        <span v-if="bgImageErr" style="color: red">{{ bgImageErr }}</span>
                        <span style="color: red">{{ errors.background }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can choose only one file at a time.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extensions should JPG, WEBP and PNG.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="applicationFrame == 'kiosk'" class="d-grid w-50 px-3 mx-4">
                <h3 style="font-weight: bold">Select Language*</h3>
                <Multiselect class="my-3 w-40" label="language" track-by="id" :options="languagesList"
                    :searchable="true" :placeholder="'Select Language'" :show-labels="false" :allow-empty="false"
                    :value="selectedLanguage" @input="handleSelectedLanguage($event)">
                    <template slot="noResult">
                        <div class="no-result-message">No Language found</div>
                    </template>
                </Multiselect>
            </div>
            <div v-if="applicationFrame == 'kiosk'" class="d-flex p-3 m-4">
                <span class="d-flex align-items-center">
                    <input type="checkbox" id="showScreenSaver" class="color" :checked="showScreenSaver"
                        @input="handleScreenSaver($event)" />
                    <label for="showScreenSaver">
                        <h3 style="font-weight: 700">Show Screen Saver</h3>
                    </label>
                </span>
            </div>

            <div class="d-flex flex-wrap p-3" style="gap: 0.5em"
                v-if="this.applicationFrame == 'kiosk' && showScreenSaver">
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Screen Saver Configuration*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input type="file" name="digital_signage" id="digital_signage"
                                placeholder="Upload Screen Saver Image" hidden @change="uploadDigitalSignage($event)"
                                accept=" .jpg, .webp, .png" multiple :disabled="digitalSignageLoading" />
                            <label for="digital_signage"
                                class="d-flex justify-content-between align-items-center upload-section"
                                :style="{ cursor: digitalSignageLoading ? 'progress' : '' }">
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Screen Saver Image</span>
                                <span v-if="digitalSignageLoading" class="upload-btn py-3 px-3 d-flex"
                                    style="gap: 10px">
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div v-for="ds in digitalSignageImages" :key="ds.id"
                                    style="position: relative; text-align: -webkit-center"
                                    @mouseover="hoveredElementId = ds.id" @mouseleave="hoveredElementId = null">
                                    <label>
                                        <img :src="ds.image" style="
                                                height: 100px;
                                                max-width: 100px;
                                                border-radius: 20px;
                                                border: 1px solid black;
                                            " />
                                        <template v-if="hoveredElementId == ds.id">
                                            <div class="w-100 h-100 action-buttons">
                                                <span class="background" @click="removeSignageImage(ds)" id="delete">
                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                        style="height: 15px" />
                                                </span>
                                            </div>
                                        </template>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <span v-if="digitalSignageErr" style="color: red">{{ digitalSignageErr }}</span>
                        <span style="color: red">{{ errors.digitalSignage }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">At least one image object is required</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extension should JPG, WEBP & PNG</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can add only 10 images at maximum.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex w-100" style="margin-bottom: 20px">
                    <div class="w-50 mx-4">
                        <h3 style="font-weight: 700">Image Display Time (in seconds)*</h3>
                        <input required type="number" v-model="timeText" name="timeText" id="timeText" class="w-80"
                            min="1" placeholder="Enter input in Seconds" @input="saveData($event)" />
                        <span v-if="timeTextErr" style="color: red; font-weight: normal">{{ timeTextErr }}</span>
                        <span style="color: red; font-weight: normal">{{ errors.timeText }}</span>
                    </div>
                    <div class="w-50" style="margin-left: 5rem">
                        <h3 style="font-weight: 700">Screen Timeout (in seconds)*</h3>
                        <input required type="number" v-model="timeoutText" name="timeoutText" id="timeoutText"
                            class="w-80" min="1" placeholder="Enter input in Seconds"
                            @input="saveTimeoutData($event)" />
                        <span v-if="timeoutTextErr" style="color: red; font-weight: normal">{{ timeoutTextErr }}</span>
                        <span style="color: red; font-weight: normal">{{ errors.timeoutText }}</span>
                    </div>
                </div>
            </div>
        </div>

        <Loading v-if="loading" />
    </div>
</template>
<script>
import { dashboardBuilder, memberProfile, companies } from '@/util/apiRequests';
import SpinLoader from '@/components/general/loading/SpinLoader.vue';
import Loading from '@/components/general/loading/loading.vue';

export default {
    name: 'CustomisedThemeDesign',
    props: {
        pageData: {
            type: Object,
        },
        applicationFrame: {
            type: String,
        },
        errors: {
            type: Object,
        },
        selected_language: {
            type: String,
        },
    },
    components: {
        SpinLoader,
        Loading,
    },

    data() {
        if (this.applicationFrame == 'kiosk' || this.applicationFrame == 'star-kid') {
            return {
                backgroundPhotoToUpload: null,
                colorSchemeToChoose: {
                    color1: '',
                    color2: '',
                    color3: '',
                },
                companyLogo: null,
                companyLogoErr: '',
                colorScheme: {
                    selectedOption: 'primary_colors',
                    primary_colors: ['#FFFFFF', '#5155C3', '#FFFFFF', '#5155C3'],
                    secondary_colors: ['#FFFFFF', '#5155C3', '#FFFFFF', '#5155C3'],
                    customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                },
                backgroundImages: [],
                backgroundPhoto: null,
                bgImageErr: '',
                digitalSignageErr: '',
                digitalSignageImages: [],
                companyLogoImages: [],
                kioskColorOption: 'primary_colors',
                allImages: [],
                digitalSignageImage: {},
                hoveredElementId: null,
                timeText: null,
                timeoutText: null,
                timeTextErr: '',
                timeoutTextErr: '',
                dashboardId: '',
                defaultColor: '#5858BE',
                changedColor: {},
                digitalSignageLoading: false,
                companyLogoLoading: false,
                bgImageLoading: false,
                loading: true,
                showScreenSaver: true,
                selectedLanguage: null,
                languagesList: [
                    {
                        id: 1,
                        language: 'English',
                    },
                    {
                        id: 2,
                        language: 'Spanish',
                    },
                    {
                        id: 3,
                        language: 'German',
                    },
                    {
                        id: 4,
                        language: 'Hindi',
                    },
                    {
                        id: 5,
                        language: 'Arabic',
                    },
                    {
                        id: 6,
                        language: 'French',
                    },
                    {
                        id: 7,
                        language: 'Japanese',
                    },
                    {
                        id: 8,
                        language: 'Portuguese',
                    },
                    {
                        id: 9,
                        language: 'Croatian',
                    },
                    {
                        id: 10,
                        language: 'Italian',
                    },
                ],
            };
        } else {
            return {
                backgroundPhotoToUpload: null,
                companyDetails: {},
                colorSchemeToChoose: {
                    color1: '',
                    color2: '',
                    color3: '',
                },
                companyLogo: null,
                companyLogoErr: '',
                colorScheme: {
                    selectedOption: 'default',
                    colors: [
                        ['#595BC4', '#FFFFFF'],
                        ['#AC2734', '#FFFFFF'],
                        ['#4CBC9A', '#FFFFFF'],
                    ],
                },
                backgroundImages: [],
                backgroundPhoto: null,
                changedColor: {},
                bgImageErr: '',
                dashboardId: '',
                defaultColor: '#5858BE',
                companyLogoLoading: false,
                bgImageLoading: false,
                loading: true,
                companyLogoImages: [],
                allImages: [],
            };
        }
    },
    methods: {
        async uploadImage(event, type) {
            type == 'dashboard_builder_logo' ? (this.companyLogoErr = '') : (this.bgImageErr = '');

            let file = event.target.files[0];

            if (file.size / 1024 / 1024 > 10) {
                this.bgImageErr = 'Size should not be greater than 10MB';
                return;
            }

            if (type == 'dashboard_builder_logo') {
                this.companyLogoLoading = true;
            } else {
                this.bgImageLoading = true;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            let that = this;

            reader.onload = async function () {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('type', type);

                const endpoint = dashboardBuilder.uploadImageAssets(that.dashboardId, type, 0);

                let response = await that.$api.post(endpoint, formData);

                if (response.data.success) {
                    if (type == 'dashboard_builder_logo') {
                        await that.getCompanyLogos(response.data.s3_link);
                        that.companyLogoLoading = false;
                    } else {
                        await that.getBackgroundImages(response.data.s3_link);
                        that.bgImageLoading = false;
                    }
                }
            };
            reader.onerror = function (error) {
                alert('Failed to upload background image !', error);
                if (type == 'dashboard_builder_logo') {
                    that.companyLogoLoading = false;
                } else {
                    that.bgImageLoading = false;
                }
            };
        },
        async renderSvg(svgXml, id) {
            // Parse SVG XML string
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

            // Get the SVG element
            const svgElement = svgDoc.documentElement;

            const allPaths = svgElement.querySelectorAll('path');

            let colors = [];

            allPaths.forEach((path) => {
                if (path.hasAttribute('fill')) {
                    let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                    let shouldBeChanged = false;
                    if (
                        (color[0] === 'a' ||
                            color[0] === 'b' ||
                            color[0] === 'c' ||
                            color[0] === 'd' ||
                            color[0] === 'e' ||
                            color[0] === 'f') &&
                        (color[2] === 'a' ||
                            color[2] === 'b' ||
                            color[2] === 'c' ||
                            color[2] === 'd' ||
                            color[2] === 'e' ||
                            color[2] === 'f') &&
                        (color[4] === 'a' ||
                            color[4] === 'b' ||
                            color[4] === 'c' ||
                            color[4] === 'd' ||
                            color[4] === 'e' ||
                            color[4] === 'f')
                    ) {
                        shouldBeChanged = false;
                        // path.setAttribute('fill', 'blue');
                    } else {
                        shouldBeChanged = true;

                        path.setAttribute('fill', this.colorScheme[this.colorScheme.selectedOption][1]);
                    }

                    colors.push(path.getAttribute('fill'));
                }
            });
            const svgHtml = svgElement.outerHTML;

            let filename = 'my-svg-file.svg';

            const svgData = new XMLSerializer().serializeToString(svgElement);

            const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

            // Create a File object with the Blob and desired filename
            const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

            // Encode the SVG HTML string to base64
            let base64Svg = btoa(svgHtml);
            // Return the base64 encoded SVG data
            base64Svg = 'data:image/svg+xml;base64,' + base64Svg;

            const formData = new FormData();
            formData.append('id', id);
            formData.append('file', svgFile);

            //call the updateSvgImage API to store updated image
            const endpoint = memberProfile.updateSvgColor(this.dashboardId);
            let res = await this.$api.post(endpoint, formData);
            if (res?.data?.success) {
                const { image } = res.data.response;
                if (image) {
                    Object.keys(this.pageData.page4 != null && this.pageData.page4.template['LI']).forEach((li) => {
                        if (
                            this.pageData.page4.template['LI'][li].elements.length &&
                            this.pageData.page4.template['LI'][li].elements[0].id == id
                        ) {
                            this.pageData.page4.template['LI'][li].elements[0].image_id = image;
                        }
                    });
                }
            }
        },
        async urlToSvg(url, id) {
            const reader = new FileReader();
            let img = await fetch(url);
            let imgBlob = await img.blob();
            reader.readAsDataURL(imgBlob);

            reader.onload = async () => {
                const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                const svgXml = atob(base64Data);
                await this.renderSvg(svgXml, id);
            };
        },
        async uploadDigitalSignage(event) {
            this.digitalSignageErr = '';
            let files = event.target.files;
            let totalImages = files.length + this.digitalSignageImages.length;

            // if (files.length > 10 || totalImages > 10) {
            //     this.$toasted.error('You can only upload a maximum of 10 files.');
            //     return;
            // }

            let successArray = [];
            let successCount = 0;
            this.digitalSignageLoading = true;

            for (let i = 0; i < files.length; i++) {
                let file = files[i];

                if (file.size / 1024 / 1024 > 10) {
                    this.$toasted.error('Image size should be less than 10MB');
                    continue;
                }
                successArray.push(
                    new Promise((resolve) => {
                        var reader = new FileReader();
                        let that = this;
                        reader.onload = function () {
                            let formData = new FormData();
                            formData.append('file', file);
                            formData.append('type', 'dashboard_builder_digital_signage_image');

                            const endpoint = dashboardBuilder.uploadImageAssets(
                                that.dashboardId,
                                'dashboard_builder_digital_signage_image',
                                0
                            );

                            that.$api
                                .post(endpoint, formData)
                                .then((response) => {
                                    if (response.data.success) {
                                        resolve(true);
                                        that.digitalSignageErr = null;
                                        successCount++;
                                        //leftImages--
                                    } else {
                                        resolve(false);
                                    }
                                })
                                .catch((error) => {
                                    resolve(false);
                                    console.error('Error uploading image:', error);
                                });
                        };

                        reader.readAsDataURL(file);
                    })
                );
            }

            this.digitalSignageErr = '';

            Promise.all(successArray).then(async (results) => {
                const success = results.every((result) => result === true);
                if (success) {
                    await this.getDigitalSignageImages();
                    if (successCount != 0 && (files.length == 10 || totalImages == 10)) {
                        if (successCount == 1) {
                            this.$toasted.success(
                                successCount + ' Image uploaded successfully! You have uploaded total 10 images.'
                            );
                        } else {
                            this.$toasted.success(
                                successCount + ' Images uploaded successfully! You have uploaded total 10 images.'
                            );
                        }
                    } else if (successCount == 0 && files.length > 0) {
                        this.$toasted.error('No image uploaded! Image size should be less than 10MB');
                    } else if (successCount == 1) {
                        this.$toasted.success(successCount + ' Image uploaded successfully!');
                    } else {
                        this.$toasted.success(successCount + ' Images uploaded successfully!');
                    }
                }
            });
        },
        async selectColorScheme(event, isCompany = false) {
            this.loading = true;
            if (this.applicationFrame == 'kiosk') {
                let colors;
                switch (event.target.value) {
                    case 'primary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                            customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                    case 'secondary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'secondary_colors',
                            customized_colors: ['#5858BE', '#5858BE', '#5858BE', '#5858BE'],
                        };
                        colors = this.colorScheme.secondary_colors;
                        break;
                    case 'customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'customized_colors',
                        };
                        colors = this.colorScheme.customized_colors;
                        break;
                    default:
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                }

                this.changedColor = {
                    primaryColor: colors[0],
                    secondaryColor: colors[1],
                    buttoncolor: colors[2],
                    textcolor: colors[3],
                    selectedSchemeOption: event.target.value,
                };

                let templateid = this.dashboardId;
                if (this.dashboardId) {
                    const endpoint = memberProfile.updateColor(templateid);
                    let response = await this.$api.put(endpoint, this.changedColor);
                    if (response.data?.success) {
                        let svgImgList = response.data?.response?.svgImgList;
                        for (let i = 0; i < svgImgList.length; i++) {
                            const file = svgImgList[i].image.split('.');
                            const fileExtension = file[file.length - 1];
                            if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                                console.log('inside', svgImgList[i].id);
                                await this.urlToSvg(svgImgList[i].imageUrl, svgImgList[i].id);
                            }
                        }
                    }
                }
                Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                    if (group === 'LI') {
                        Object.keys(this.pageData.page4.template[group]).forEach((li) => {
                            this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                li
                            ].style.replace(
                                /background-color:[^;]*/,
                                `background-color:${this.changedColor.buttoncolor};`
                            );
                            this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                li
                            ].style.replace(/(?:^|;)\s*color\s*:[^;]*/, `color:${this.changedColor.textcolor}`);
                        });
                    }
                });
            } else {
                if (isCompany && this.applicationFrame == 'mobex-health-hub') {
                    switch (event.target.value) {
                        case 'option1':
                            this.colorScheme = {
                                selectedOption: 'option1',
                                colors: [
                                    ['#000', '#FFFFFF'],
                                    ['#3858E9', '#FFFFFF'],
                                    ['#15AE80', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#000',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'option2':
                            this.colorScheme = {
                                selectedOption: 'option2',
                                colors: [
                                    ['#2271B1', '#FFFFFF'],
                                    ['#9EA476', '#FFFFFF'],
                                    ['#DD823B', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#2271B1',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'customized_color':
                            if (isCompany) {
                                const color = JSON.parse(this.companyDetails?.mhh_colors);
                                this.colorScheme = {
                                    selectedOption: 'customized_color',
                                    colors: color.colors,
                                };
                                this.changedColor = {
                                    buttoncolor: color.buttoncolor,
                                    textcolor: color.textcolor,
                                };
                            } else {
                                this.colorScheme = {
                                    selectedOption: 'customized_color',
                                    colors: ['#5858BE', '#5858BE'],
                                };
                                this.changedColor = {
                                    buttoncolor: '#5858BE',
                                    textcolor: '#5858BE',
                                };
                            }
                            break;
                        default:
                            this.colorScheme = {
                                selectedOption: 'default',
                                colors: [
                                    ['#595BC4', '#FFFFFF'],
                                    ['#AC2734', '#FFFFFF'],
                                    ['#4CBC9A', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#595BC4',
                                textcolor: '#FFFFFF',
                            };
                            break;
                    }

                    let templateid = this.dashboardId;
                    if (this.dashboardId) {
                        const endpoint = memberProfile.updateColor(templateid);
                        let response = await this.$api.put(endpoint, this.changedColor);
                    }

                    Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                        if (group === 'LI') {
                            Object.keys(this.pageData.page4.template[group]).forEach((li) => {
                                this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                    li
                                ].style.replace(
                                    /background-color:[^;]*/,
                                    `background-color:${this.changedColor.buttoncolor};`
                                );
                                this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                    li
                                ].style.replace(/(?:^|;)\s*color\s*:[^;]*/, `color:${this.changedColor.textcolor}`);
                                this.pageData.page4.template[group][li].color = this.changedColor.buttoncolor;
                                this.pageData.page4.template[group][li].textColor = this.changedColor.textcolor;
                            });
                        }
                    });
                } else if (!isCompany) {
                    switch (event.target.value) {
                        case 'option1':
                            this.colorScheme = {
                                selectedOption: 'option1',
                                colors: [
                                    ['#000', '#FFFFFF'],
                                    ['#3858E9', '#FFFFFF'],
                                    ['#15AE80', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#000',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'option2':
                            this.colorScheme = {
                                selectedOption: 'option2',
                                colors: [
                                    ['#2271B1', '#FFFFFF'],
                                    ['#9EA476', '#FFFFFF'],
                                    ['#DD823B', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#2271B1',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'customized_color':
                            this.colorScheme = {
                                selectedOption: 'customized_color',
                                colors: [
                                    ['#5858BE', '#5858BE'],
                                    ['#5858BE', '#5858BE'],
                                    ['#5858BE', '#5858BE'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#5858BE',
                                textcolor: '#5858BE',
                            };
                            break;
                        default:
                            this.colorScheme = {
                                selectedOption: 'default',
                                colors: [
                                    ['#595BC4', '#FFFFFF'],
                                    ['#AC2734', '#FFFFFF'],
                                    ['#4CBC9A', '#FFFFFF'],
                                ],
                            };
                            this.changedColor = {
                                buttoncolor: '#595BC4',
                                textcolor: '#FFFFFF',
                            };
                            break;
                    }

                    let templateid = this.dashboardId;

                    if (this.dashboardId) {
                        const endpoint = memberProfile.updateColor(templateid);
                        let response = await this.$api.put(endpoint, this.changedColor);
                    }

                    Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                        if (group === 'GSI') {
                            Object.keys(this.pageData.page4.template[group]).forEach((gsi) => {
                                this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                    group
                                ][gsi].style.replace(
                                    /background-color:[^;]*/,
                                    `background-color:${this.changedColor.buttoncolor};`
                                );
                                this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                    group
                                ][gsi].style.replace(
                                    /(?:^|;)\s*color\s*:[^;]*/,
                                    `color:${this.changedColor.textcolor}`
                                );
                                this.pageData.page4.template[group][gsi].color = this.changedColor.buttoncolor;
                                this.pageData.page4.template[group][gsi].textColor = this.changedColor.textcolor;
                            });
                        }
                    });
                }
            }

            this.loading = false;
            this.$emit('captureValues', { page3: { colorScheme: this.colorScheme } });
        },
        async selectCustomColorScheme(event, colorNo) {
            this.loading = true;
            let color = event.target.value;
            let _colorScheme = this.colorScheme;

            if (this.applicationFrame == 'kiosk') {
                this.changedColor = {
                    primaryColor: this.colorScheme.customized_colors[0],
                    secondaryColor: this.colorScheme.customized_colors[1],
                    buttoncolor: this.colorScheme.customized_colors[2],
                    textcolor: this.colorScheme.customized_colors[3],
                    selectedSchemeOption: this.colorScheme.selectColorScheme,
                };

                switch (colorNo) {
                    case 0:
                        this.changedColor = {
                            ...this.changedColor,
                            primaryColor: color,
                        };
                        break;
                    case 1:
                        this.changedColor = {
                            ...this.changedColor,
                            secondaryColor: color,
                        };
                        break;
                    case 2:
                        this.changedColor = {
                            ...this.changedColor,
                            buttoncolor: color,
                        };
                        break;
                    case 3:
                        this.changedColor = {
                            ...this.changedColor,
                            textcolor: color,
                        };
                        break;
                }

                let templateid = this.dashboardId;
                if (this.dashboardId) {
                    const endpoint = memberProfile.updateColor(templateid);
                    let response = await this.$api.put(endpoint, this.changedColor);
                    if (colorNo == 1 && response.data?.success) {
                        let svgImgList = response.data?.response?.svgImgList;
                        for (let i = 0; i < svgImgList.length; i++) {
                            const file = svgImgList[i].image.split('.');
                            const fileExtension = file[file.length - 1];
                            if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                                await this.urlToSvg(svgImgList[i].imageUrl, svgImgList[i].id);
                            }
                        }
                    }
                }

                Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                    if (group === 'LI') {
                        Object.keys(this.pageData.page4.template[group]).forEach((li) => {
                            this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                li
                            ].style.replace(
                                /background-color:[^;]*/,
                                `background-color:${this.changedColor.buttoncolor};`
                            );
                            this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                li
                            ].style.replace(/(?:^|;)\s*color\s*:[^;]*/, `color:${this.changedColor.textcolor}`);
                        });
                    }
                });
            } else {
                switch (colorNo) {
                    case 1:
                        _colorScheme.colors[0][0] = color;
                        this.changedColor.buttoncolor = color;
                        break;
                    case 2:
                        _colorScheme.colors[1][0] = color;
                        break;
                    case 3:
                        _colorScheme.colors[2][0] = color;
                        break;
                    case 4:
                        _colorScheme.colors[0][1] = color;
                        this.changedColor.textcolor = color;
                        break;
                    case 5:
                        _colorScheme.colors[1][1] = color;
                        break;
                    case 6:
                        _colorScheme.colors[2][1] = color;
                        break;
                }

                if (colorNo == 1 || colorNo == 4) {
                    if (this.dashboardId) {
                        const endpoint = memberProfile.updateColor(this.dashboardId);
                        let response = await this.$api.put(endpoint, this.changedColor);
                    }

                    Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                        if (group === 'GSI') {
                            Object.keys(this.pageData.page4.template[group]).forEach((gsi) => {
                                this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                    group
                                ][gsi].style.replace(
                                    /background-color:[^;]*/,
                                    `background-color:${this.changedColor.buttoncolor};`
                                );
                                this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                    group
                                ][gsi].style.replace(
                                    /(?:^|;)\s*color\s*:[^;]*/,
                                    `color:${this.changedColor.textcolor}`
                                );
                                this.pageData.page4.template[group][gsi].color = this.changedColor.buttoncolor;
                                this.pageData.page4.template[group][gsi].textColor = this.changedColor.textcolor;
                            });
                        }
                    });

                    this.colorScheme = _colorScheme;

                    this.$emit('captureValues', { page3: { colorScheme: this.colorScheme } });
                    this.errors.colorScheme = null;
                }
            }
            this.loading = false;
            this.$emit('captureValues', { page3: { colorScheme: this.colorScheme } });
        },
        selectCompanyLogo(logo) {
            this.companyLogo = {
                id: logo.id,
                image: logo.image,
                fileId: logo.file_id,
            };
            this.errors.logo = null;
            this.$emit('captureValues', { page3: { logo: this.companyLogo } });
        },
        selectBackground(bg) {
            this.backgroundPhoto = {
                fileId: bg.fileId,
                id: bg.id,
                image: bg.image,
            };
            this.$emit('captureValues', { page3: { background: bg } });
            this.errors.background = null;
        },
        async getCompanyLogos(s3_link = null) {
            try {
                const endpoint1 = dashboardBuilder.getAssets('dashboard_builder_logo');
                const assetsResponse = await this.$api.get(endpoint1);

                if (assetsResponse.data.success) {
                    let data1 = assetsResponse.data.list.filter(
                        (item) =>
                            item.company_id == this.companyDetails?.id && item.dashboard_used == this.dashboardId
                    );
                    let data2 = this.allImages.filter((item) => item.type == 'company_logo');

                    this.companyLogoImages = [...data2, ...data1];

                    this.companyLogoImages = this.companyLogoImages.slice().reverse();

                    if (s3_link) {
                        const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3_link);
                        this.companyLogo = {
                            id: selectedImage.id,
                            fileId: selectedImage.file_id,
                            image: selectedImage.image,
                        };
                        this.$emit('captureValues', { page3: { logo: selectedImage } });
                    }

                    this.$emit('captureValues', { page3: { companyLogoImages: data1 } });
                }
            } catch (err) {
                this.$toasted.error('Failed to fetch dashboard_builder_logo');
            } finally {
                this.companyLogoLoading = false;
            }
        },
        async getBackgroundImages(s3Link = null) {
            try {
                const endpoint1 = dashboardBuilder.getAssets('dashboard_builder_background_image');
                const assetsResponse = await this.$api.get(endpoint1);

                if (assetsResponse.data.success) {
                    let data1 = assetsResponse.data.list.filter(
                        (item) => item.company_id == this.companyDetails?.id
                    );
                    let data2 = this.allImages.filter((item) => item.type == 'company_background_image');

                    this.backgroundImages = [...data2, ...data1];

                    this.backgroundImages = this.backgroundImages.slice().reverse();
                    if (s3Link) {
                        const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3Link);
                        this.backgroundPhoto = {
                            fileId: selectedImage.file_id,
                            id: selectedImage.id,
                            image: selectedImage.image,
                        };
                        this.$emit('captureValues', { page3: { background: selectedImage } });
                    }

                    this.$emit('captureValues', { page3: { backgroundImages: data1 } });
                }
            } catch (err) {
                this.$toasted.error('Failed to fetch dashboard_builder_background_image');
            } finally {
                this.bgImageLoading = false;
            }
        },
        async getDigitalSignageImages() {
            try {
                const endpoint1 = dashboardBuilder.getAssets('dashboard_builder_digital_signage_image');
                const assetsResponse = await this.$api.get(endpoint1);

                if (assetsResponse.data.success) {
                    let data1 = this.allImages?.filter((item) => item.type == 'company_digital_signage_image');
                    let data2 = assetsResponse?.data?.list.filter(
                        (item) => (item.company_id == this.companyDetails?.id && item.dashboard_used == this.dashboardId)
                    );

                    this.digitalSignageImages = [...data1, ...data2];

                    this.errors.digitalSignage = null;

                    this.$emit('captureValues', { page3: { digitalSignage: data1 } });
                }
            } catch (err) {
                this.$toasted.error('Failed to fetch dashboard_builder_digital_signage_image');
            } finally {
                this.digitalSignageLoading = false;
            }
        },
        async removeSignageImage(item) {
            this.digitalSignageErr = null;
            const endpoint = dashboardBuilder.deleteAsset(this.dashboardId, item.id);
            const assetsResponse = await this.$api.delete(endpoint);
            let temp = [];
            this.digitalSignageImages.forEach((element) => {
                if (element.id != item.id) {
                    temp.push(element);
                }
            });
            this.digitalSignageImages = [...temp];
            this.$toasted.success('Image deleted successfully !');
            this.$emit('captureValues', { page3: { digitalSignage: this.digitalSignageImages } });
            return assetsResponse;
        },
        saveData(event) {
            let val = parseInt(event.target.value);
            if (event.target.value == '') {
                this.timeTextErr = 'This field is Required !';
                return;
            }
            if (val <= 0) {
                this.timeTextErr = 'Value should be positive Integer !';
                return;
            }
            this.errors.timeText = null;
            this.timeTextErr = '';
            this.timeText = val;
            this.$emit('captureValues', { page3: { timeText: this.timeText } });
        },
        saveTimeoutData(event) {
            let val = parseInt(event.target.value);
            if (event.target.value == '') {
                this.timeoutTextErr = 'This field is Required !';
                return;
            }
            if (val <= 0) {
                this.timeoutTextErr = 'Value should be positive Integer !';
                return;
            }
            this.errors.timeoutText = null;
            this.timeoutTextErr = '';
            this.timeoutText = val;
            this.$emit('captureValues', { page3: { timeoutText: this.timeoutText } });
        },
        async getCompanyColor() {
            if (this.applicationFrame == 'kiosk') {
                this.colorScheme = JSON.parse(this.companyDetails.kiosk_colors);

                this.kioskColorOption = this.colorScheme.selectedOption;
            } else if (this.applicationFrame == 'mobex-health-hub') {
                let colorScheme = JSON.parse(this.companyDetails.mhh_colors)?.selectedOption ?? 'default';

                this.selectColorScheme({ target: { value: colorScheme } }, true);
            }
        },
        async getAllImages() {
            try {
                const endpoint = companies.getAssetList(this.companyDetails?.id);
                const assetsResponse = await this.$api.get(endpoint);

                if (assetsResponse.data.success) {
                    this.allImages = assetsResponse?.data?.list;
                    this.companyLogoImages = this.allImages?.filter((item) => item.type == 'company_logo');
                    this.backgroundImages = this.allImages?.filter(
                        (item) => item.type == 'company_background_image'
                    );
                    this.digitalSignageImages = this.allImages?.filter(
                        (item) => item.type == 'company_digital_signage_image'
                    );

                    this.$emit('captureValues', { page3: { digitalSignage: this.digitalSignageImages } });
                }
            } catch (err) {
                this.$toasted.error('Failed to fetched assets');
            }
        },
        handleScreenSaver(event) {
            this.showScreenSaver = event.target.checked;
            this.$emit('captureValues', { page3: { showScreenSaver: event.target.checked } });
        },
        async geturl(fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [fileId],
            };
            let response = await this.$api.post(endpoint, requestData);
            return response?.data?.data[0].image;
        },
        async getCompanyDetails() {
            try {
                const endpoint = companies.getCompanyDetails(this.$store.state.user?.company_id);
                const response = await this.$api.get(endpoint);
                if (response?.data?.info[0]) {
                    this.companyDetails = response?.data?.info[0];
                }
            } catch (err) {
                this.$toasted.error('Failed to fetch company details');
            }
        },
    },
    async created() {
        window.scrollTo(0, 0);

        await this.getCompanyDetails();
        await this.getAllImages();

        if (this.$route.query.id) {
            this.dashboardId = this.$route.query.id;
            await this.getCompanyLogos();
            await this.getBackgroundImages();
            await this.getDigitalSignageImages();
        } else {
            const lastidendpoint = dashboardBuilder.getLastTemplateId();
            const res = await this.$api.get(lastidendpoint);
            if (res.data?.success) {
                this.dashboardId = res.data.response.AUTO_INCREMENT;
                await this.getCompanyColor();
            }
        }

        if (this.applicationFrame == 'star-kid') {
            this.colorScheme = {
                selectedOption: 'default',
                colors: ['#FFFFFF', '#5155C3'],
            };
        }

        if (this.applicationFrame == 'family-connect') {
            this.companyLogo = {
                fileId: null,
                image: '@/assets/px/fc_logo.png',
            };
            this.errors.logo = null;
            this.$emit('captureValues', { page3: { logo: this.companyLogo } });
        }

        if (this.pageData.page3) {
            const { logo, background, colorScheme, timeText, timeoutText, digitalSignage, showScreenSaver } =
                this.pageData.page3;

            if (logo) {
                this.companyLogo = logo;
                let i = this.companyLogoImages.findIndex((el) => el.id == this.companyLogo.id);
                this.companyLogo = this.companyLogoImages[i];
                this.$emit('captureValues', { page3: { logo: this.companyLogo } });
            }
            if (colorScheme) {
                this.colorScheme = colorScheme;
            }
            if (background) {
                this.backgroundPhoto = background;
                let i = this.backgroundImages.findIndex((el) => el.id == this.backgroundPhoto.id);
                this.backgroundPhoto = this.backgroundImages[i];
                this.$emit('captureValues', { page3: { background: this.backgroundPhoto } });
            }
            if (timeText) {
                this.timeText = timeText;
            }
            if (timeoutText) {
                this.timeoutText = timeoutText;
            }
            if (digitalSignage) {
                this.digitalSignageImages = digitalSignage;
            }
            if (showScreenSaver != undefined) {
                this.showScreenSaver = showScreenSaver;
            }
        }

        if (this.applicationFrame == 'kiosk') {
            this.selectedLanguage = this.languagesList.filter((item) => item.language == this.selected_language);
        }

        this.$emit('captureValues', { page3: { colorScheme: this.colorScheme } });
        this.loading = false;
    },
};
</script>

<style scoped>
.scenario-item {
    color: gray;
    font-size: 20px;
    font-weight: 600;
}

.scenario-text {
    font-size: 13px;
}

.color-box {
    width: 45%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);
}

.upload-btn {
    border-radius: 6px;
    background: #5155c3;
    color: #fff;
    font-family: 'Roboto';
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
}

.scroll-Wrapper {
    width: 32vw;
    margin-bottom: 5%;
}

.scroll-container {
    overflow: auto;
    white-space: nowrap;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
}

.scroll-container::-webkit-scrollbar {
    height: 5px;
}

/* Track */
.scroll-container::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
.scroll-container::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
.scroll-container::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}

.color {
    accent-color: var(--base-theme-tab-color);
}

.upload-section {
    margin: 0 15% 0 0;
    border-radius: 10px;
    border: 2px solid var(--grey, #7d7d7d);
    background: #fff;
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    cursor: pointer;
}

.upload-btn {
    border-radius: 6px;
    background: #5155c3;
    color: #fff;
    font-family: 'Roboto';
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
}

.active-bg {
    width: 95px !important;
    height: 95px !important;
    border: 2px solid lime;
    padding: 2px;
}

.action-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    top: 0;
    background: rgb(89, 90, 194, 0.85);
    border-radius: 20px;
}
</style>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
}

.default-color {
    height: 1.93rem;
    background-color: #5858be;
}
</style>
