<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
 
        <div class="modal-list-third-party-app" v-if="showAppListing">
 
            <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                <span id="target" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                    role="button" @click="cancel">close</span>
            </ejs-tooltip>
 
            <div class="preview-body">
                <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer" class="w-100">
                        <div :style="pageData.page4.templateStyles.bgImageContainer">
                            <img :style="pageData.page4.templateStyles.bgImage"
                                src="@/assets/px/drag-and-drop/MHH-FC-Background.png" alt="" width="100" height="100" />
                        </div>
                        <div :style="pageData.page4.templateStyles.primeContainer" class="w-100">
                            <div class="px-5 py-4">
                                <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex w-70" style="gap:1rem;align-items: center;">
                                        <img src="@/assets/photos/MHH-user.png" alt=""
                                            style="width: 80px;height: 80px;border-radius: 50%;border: 2px solid #5155C3;padding: 0.2rem" />
                                        <div>
                                            <h1 style="font-weight: 700;color:#5155C3">Hello, <span
                                                    style="color:#4CBC9A">Jaron William</span></h1>
                                            <h3 style="color: #271E4A">Tuesday, November 28, 2023, 10:23 AM</h3>
                                        </div>
                                    </div>
                                    <div class="w-30 d-flex" style="flex-direction:row-reverse;gap:2rem">
                                        <div class="d-flex icon-container">
                                            <img src="@/assets/px/header-icons-images/logout.svg" alt="" width="30"
                                                height="30" />
                                            <span>Logout</span>
                                        </div>
                                        <div class="d-flex icon-container">
                                            <img src="@/assets/px/header-icons-images/notification.svg" alt=""
                                                width="30" height="30" />
                                            <span>Notifications</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='t-m-c w-100' :style="pageData.page4.templateStyles.templateMainContainer">
                                <div class='u-l-c w-100 d-flex h-100' style="gap:10px;margin-bottom: 50px">
                                    <div class="w-70 px-2" :style="pageData.page4.templateStyles.upperLayoutContainer"
                                        style="align-items: center;place-items: center;display: grid;grid-template-columns: repeat(3, 1fr);grid-template-rows: repeat(2, 1fr);">
                                        <div v-for="(item, index) in Object.entries(pageData.page4.template.LI)"
                                            :key="item[1].name + ''"
                                            :style="{ 'border-radius': '35px', 'background-color': pageData.page4.template['LI'][item[0]].elements.length && index == 0 ? 'transparent' : 'white', 'box-shadow': pageData.page4.template['LI'][item[0]].elements.length && index == 0 ? '' : '5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset', 'height': '75%', 'width': '12rem' }">
                                            <draggable class="draggable-list" :list="item.elements" :group="{ name: 'myGroup', pull: false, put: true }"
                                                @change="manipulateWidgets($event, 'LI', item[1].name, 'add')">
                                                <div :class="value.class"
                                                    v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                                    :key="value.name" @mouseover="hoveredElementId = 'LI' + index"
                                                    @mouseleave="hoveredElementId = null" @click="
                                                    value.function_type === 'generic' ? addDetails(value.function_name, index): ''"
                                                    class="li-items-container" :style="{ 'background-color': value.function_name == 'Games2' ? '#4CBC9A' : index == 0 ? 'transparent' : 'white' }">
                                                    <img v-if="value.function_type == 'generic'" :src="value.image"
                                                        alt=""
                                                        :class="pageData.page4.template['LI'][item[0]].title ? 'w-50 h-50' : 'w-90'"
                                                        style="border-radius: 0.5rem;" />
                                                    <img v-else-if="value.function_name == 'Scheduling2'"
                                                        :src="value.image" alt=""
                                                        style="max-width: 13rem;border-radius:0.5rem" />
                                                    <img v-else :src="value.image" alt=""
                                                        :style="{ 'width': value.function_name == 'Care Team2' ? '50%' : '70%', 'border-radius': '0.5rem' }" />
                                                    <p v-if="value.function_type == 'generic' && pageData.page4.template['LI'][item[0]].title"
                                                        class="widget-title">
                                                        {{ pageData.page4.template['LI'][item[0]].title }}
                                                    </p>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
 
                                    <div class="w-20 p-2 side-container">
                                        <div class="side-container-item"
                                            v-for="(item, index) in Object.entries(pageData.page4.template.GSI)"
                                            :key="item[1].name + ''">
                                            <draggable class="draggable-list"
                                                :list="pageData.page4.template['GSI'][item[0]].elements" :group="pageData.page4.template['GSI'][item[0]].group? pageData.page4.template['GSI'][item[0]].group: { name: 'myGroup', pull: false, put: true }"
                                                @change="manipulateWidgets($event, 'GSI', item[1].name, 'add')">
                                                <div :class="value.class"
                                                    v-for="value in pageData.page4.template['GSI'][item[0]].elements"
                                                    :key="value.name" @mouseover="hoveredElementId = 'GSI' + index"
                                                    @mouseleave="hoveredElementId = null" @click="
                                                    value.function_type === 'generic'? addDetails(value.function_name, index): ''"
                                                    :style="{ 'background-color': value.function_name == 'Web Links2' ? '#5155C3' : 'white', 'border-radius': '35px', 'position': 'relative', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column', 'height': '100%' }">
                                                    <img :src="value.image" alt="" style="max-width:70%" />
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-100 align-items-center"
                                style="position: absolute;bottom: 0;left: 0;border-radius: 10px 20px;">
                                <img src="@/assets/px/drag-and-drop/footer.png" alt=""
                                    style="max-height:4rem;border-radius: 10px 20px;width: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
        </div>
 
        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>
 
<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import draggable from "vuedraggable";
 
 
export default {
    name: 'PreviewModalMHH_CallButton',
    components: { NoButtonModal, draggable },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        selected: {
            type: String,
 
        },
        pageData: {
            type: Object,
            required: true
        },
        applicationFrame: {
            type: String,
            required: true
        }
 
    },
    data() {
        return {
            showAppListing: true,
            showLoader: false,
        };
    },
    methods: {
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;
 
            this.$emit('close');
        },
    },
    created() { }
};
</script>
<style scoped>
.loader-main {
    min-height: 50vh;
}
 
.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}
 
.img-loader-main {
    min-height: 30px;
}
 
.img-loader {
    height: .9em;
    width: .9em;
    font-weight: 600;
}
 
.img-loader-text {
    font-size: .8em;
    font-weight: 600;
    color: var(--base-theme-color);
}
 
.icon-container {
    color: #271E4A;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
}
 
.side-container {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}
 
.side-container-item {
    height: 23%;
    background-color: white;
    border-radius: 35px;
    box-shadow: 5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset;
}
 
.li-items-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 35px;
    height: 100%;
}
 
.widget-title {
    height: 20px;
    width: 80%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
    color: rgb(81, 85, 195);
}
</style>