<template>
    <div class="bg-white rounded-5">
        <div class="d-flex justify-content-center align-items-center py-3">
            <div class="w-75 position-absolute" style="background: lightgray;">
                <div class="position-absolute w-100" style="height: 3px; background: #e3e3e3;"></div>
                <div class="position-absolute" style="height: 3px; background: green;" v-bind:class="progress"></div>
            </div>
            <div class="d-flex justify-content-between align-items-center" style="width: 78%;">
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(1)">
                    <span class="material-symbols-outlined icon" v-if="current_step > 1"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 1"> fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(2)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 2"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 2"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 2"> fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(3)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 3"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 3"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 3"> fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(4)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 4"> </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 4"> fiber_manual_record
                    </span>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center w-100">
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0;">Select Company & Application</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0;">Select Modules</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0;">Customize Theme</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0;">Build Dashboard</h6>
                </div>
            </div>

        </div>
        <div class="px-5 mx-4">
            <div class="d-flex justify-content-start">
                <SelectCompanyApplication v-if="current_step === 1 && dataFetched == true" @captureValues="captureValues"
                    :errors="page1Errors" :pageData="pageData" :healthCareProviderData="healthCareProvider"
                    :applicationFrame="applicationFrame"  :templateType="templateType" :dashboardName="dashboardName" />
                <SelectModules v-if="current_step === 2" @captureValues="captureValues" :errors="page2Errors"
                    :pageData="pageData" :applicationFrame="applicationFrame" :current_template="current_template" />
                <CustomisedThemeDesign v-if="current_step === 3 " @captureValues="captureValues" :errors="page3Errors"
                    :pageData="pageData" :applicationFrame="applicationFrame" :selected_language="selected_language" />
                <BuildDashboard v-if="current_step === 4 && this.applicationFrame == 'mobex-health-hub' && current_template == 1" @captureValues="captureValues"
                    :pageData="pageData" @selection-changed="getCurrentTemplate" :current_template="current_template" :applicationFrame="applicationFrame"
                />
                <Build_MHH_FC_Dashboard v-if="current_step === 4 && this.applicationFrame == 'mobex-health-hub' && current_template == 4" @captureValues="captureValues"
                    :pageData="pageData" @selection-changed="getCurrentTemplate" :current_template="current_template" :applicationFrame="applicationFrame"
                />
                <Build_MHH_Call_button_Dashboard v-if="current_step === 4 && this.applicationFrame == 'mobex-health-hub' && current_template == 6" @captureValues="captureValues"
                    :pageData="pageData" @selection-changed="getCurrentTemplate" :current_template="current_template" :applicationFrame="applicationFrame"
                />
                <BuildKioskDashboard v-if="current_step === 4 && this.applicationFrame == 'kiosk'" :rssFeedsDetails="rssFeedsDetails" @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues" :pageData="pageData" @selection-changed="getCurrentTemplate"
                    :current_template="current_template" :applicationFrame="applicationFrame" />
                 <BuildFamilyConnectDashboard v-if="current_step === 4 && this.applicationFrame == 'family-connect'" @captureValues="captureValues"
                    :pageData="pageData" @selection-changed="getCurrentTemplate" :current_template="current_template" :applicationFrame="applicationFrame"
                />
                <BuildStarKidDashboard v-if="current_step === 4 && this.applicationFrame == 'star-kid'" :rssFeedsDetails="rssFeedsDetails" @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues" :pageData="pageData" @selection-changed="getCurrentTemplate"
                    :current_template="current_template" :applicationFrame="applicationFrame" />
            </div>
        </div>

        <div class="mx-5">
            <div class="py-5 d-flex justify-content-end">
                <button class="other-btn" @click="onClickBack" v-if="current_step > 1">Back to Previous</button>
                <button v-if='current_step != 4 && pageData.status != "completed"' class="other-btn" @click="saveDraft">Save
                    as Draft</button>
                <button v-if='current_step == 4' class="other-btn" @click="openLauncherModal = true">Preview</button>
                <button v-if="current_step < max_step" class="save-btn" @click="onClickNext">Next</button>
                <button v-if="current_step == max_step" class="save-btn" @click="completeDashboard">Save</button>
            </div>
        </div>
        <div class="previewdashboard">
            <PreviewModal v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub'&& current_template == 1" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                :applicationFrame="applicationFrame" />
           
        </div>
        <div class="previewdashboard">
            <PreviewModalMHH2 v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 4" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                :applicationFrame="applicationFrame" />
           
        </div>
        <div class="previewdashboard">
            <PreviewModalMHH_CallButton v-if="openLauncherModal && this.applicationFrame == 'mobex-health-hub' && current_template == 6" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                :applicationFrame="applicationFrame" />
        </div>
        <div class="previewdashboardkiosk">
        <PreviewModalKiosk v-if="openLauncherModal && this.applicationFrame == 'kiosk'" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                :applicationFrame="applicationFrame" />
        </div>
        <div class="previewdashboardkiosk">
            <PreviewModalStarKid v-if="openLauncherModal && this.applicationFrame == 'star-kid'" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                    :applicationFrame="applicationFrame" />
            </div>
        <div class="previewdashboard">
            <PreviewModalFC v-if="openLauncherModal && this.applicationFrame == 'family-connect'" :open="openLauncherModal" @close="close" v-bind:pageData="pageData"
                :applicationFrame="applicationFrame" />
           
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
import SelectCompanyApplication from './select_company_application.vue';
import SelectModules from './select_modules.vue';
import CustomisedThemeDesign from './customised_theme_design.vue';
import BuildDashboard from './build_dashboard.vue';
import Build_MHH_FC_Dashboard from './build_Mhh_fc_dashboard.vue';
import Build_MHH_Call_button_Dashboard from './build_Mhh_call_button_dashboard.vue';
import BuildKioskDashboard from './build_kiosk_dashboard.vue'
import BuildStarKidDashboard from './build_starkid_dashboard.vue'
import BuildFamilyConnectDashboard from './build_familyconnect_dashboard.vue'
import PreviewModal from './preview_modal.vue';
import PreviewModalMHH2 from './preview_modal_mhh2.vue';
import PreviewModalMHH_CallButton from './preview_modal_mhh_call_button.vue';
import PreviewModalKiosk from './preview_modal_kiosk.vue';
import PreviewModalStarKid from './preview_modal_starkid.vue';
import PreviewModalFC from './preview_modal_fc.vue';
import Loading from '@/components/general/loading/loading.vue';
import { dashboardBuilder, clients , memberProfile} from '@/util/apiRequests';
import { dashboardBuildNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';


export default {
    name: "DashboardBuilder",
    components: {
        SelectCompanyApplication,
        SelectModules,
        CustomisedThemeDesign,
        BuildDashboard,
        Build_MHH_FC_Dashboard,
        Build_MHH_Call_button_Dashboard,
        BuildKioskDashboard,
        BuildStarKidDashboard,
        PreviewModal,
        PreviewModalMHH2,
        PreviewModalMHH_CallButton,
        PreviewModalKiosk,
        PreviewModalStarKid,
        BuildFamilyConnectDashboard,
        PreviewModalFC,
        Loading
    },
    props: {
        isNextButtonClicked: Boolean
    },
    data() {
        return {
            current_step: 1,
            current_template: 1,
            max_step: 4,
            pageData: {
                page1: null,
                page2: null,
                page3: null,
                page4: null,
                status: 'draft'
            },
            openLauncherModal: false,
            healthCareProvider: null,
            applicationFrame: null,
            templateType:null,
            clientEmails: null,
            dashboardName:null,
            page1Errors: {
                healthCareProvider: null,
                applicationFrame: null,
                templateType:null,
                dashboardName:null
            },
            page2Errors: {
                modules: null
            },
            page3Errors: {
                logo: null,
                color: null,
                background: null,
                digitalSignage: null,
                timeText : null,
                timeoutText: null
            },
            dashboardId: this.$route?.query?.id || null,
            widgetsList: [],
            dataFetched: false,
            loading : true,
            rssFeedsDetails: null,
            selected_language: 'English',
        };
    },
    computed: {
        progress: function () {
            let _class = '';
            if (this.current_step === 1) {
                _class = '';
            }
            else if (this.current_step === 2) {
                _class = 'w-33';
            }
            else if (this.current_step === 3) {
                _class = 'w-67';
            }
            else if (this.current_step === 4) {
                _class = 'w-100';
            }
            return _class;
        },
    },
    methods: {
        async fetchDashboardData(id) {
            let template = await this.$api.get(dashboardBuilder.fetchDashboardData(id));
            let data;
            if (template.data?.templateMeta?.data) {
                data = JSON.parse(template.data.templateMeta.data);
            }

            if (template.data?.templateMeta?.status) {
                this.pageData.status = template.data.templateMeta.status;
            }

            if (template.data?.templateMeta?.health_care_provider) {
                this.healthCareProvider = JSON.parse(template.data.templateMeta.health_care_provider)
            }
            if(template.data?.templateMeta?.selected_template){
                this.templateType=JSON.parse(template.data?.templateMeta?.selected_template);
            }
            if(template.data?.templateMeta?.dashboardName)
            {
                this.dashboardName=(template.data.templateMeta.dashboardName)
            }
            if(template.data?.templateMeta?.selected_language){
                this.selected_language = template.data?.templateMeta?.selected_language;
            }

            this.applicationFrame = template.data?.templateMeta?.application;

            if (this.applicationFrame == 'kiosk') {
                this.current_template = 2;
            }
            else if(this.applicationFrame == 'star-kid'){
                this.current_template = 5;
            }
            else if(this.applicationFrame == 'family-connect'){
                this.current_template = 3;
            }
            else{
                this.current_template = +this.templateType?.id;
            }
           
            this.pageData.page2 = {
                coreFunctions: data?.page2?.coreFunctions,
                thirdPartyFunctions: data?.page2?.thirdPartyFunctions,
                genericFunctions: data?.page2?.genericFunctions,
                enableQR: data?.page2?.enableQR,
            }


            this.pageData.page3 = {
                logo: data?.page3?.logo,
                colorScheme: data?.page3?.colorScheme,
                background: data?.page3?.background,
                companyLogoImages: data?.page3?.companyLogoImages ?? [],
                backgroundImages: data?.page3?.backgroundImages ?? [],
                digitalSignage:data?.page3?.digitalSignage,
                timeText:data?.page3?.timeText,
                timeoutText:data?.page3?.timeoutText,
                showScreenSaver: data?.page3?.showScreenSaver,
            }



            this.pageData.page4 = {
                template: data?.page4?.template,
                templateData: data?.page4?.templateData,
                templateStyles: data?.page4?.templateStyles
            }

            if(this.applicationFrame == 'kiosk' || this.applicationFrame == 'star-kid'){
                let rssinfo = JSON.parse(template.data.templateMeta?.rssinfo)   
                this.rssFeedsDetails = rssinfo;
            }

            return true;
        },
        onClickNext() {
            if (this.current_step === 1) {


                if (!this.healthCareProvider) {
                    this.page1Errors.healthCareProvider = "Please select health care provider !";
                    return;
                }
                else if (!this.applicationFrame) {
                    this.page1Errors.healthCareProvider = null;
                    this.page1Errors.applicationFrame = "Please select deliver application !";
                    return;
                }
                else if (!this.templateType) {
                    this.page1Errors.templateType = null;
                    this.page1Errors.templateType = "Please select a dashboard !";
                    return;
                }
                else if(!this.dashboardName)
                {
                    this.page1Errors.dashboardName=null;
                    this.page1Errors.dashboardName='Please enter dashboard Name';
                    return;
                }

            if (this.applicationFrame == 'kiosk') {
                this.current_template = 2;
            }
            else if(this.applicationFrame == 'star-kid'){
                this.current_template = 5;
            }
            else if(this.applicationFrame == 'family-connect'){
                this.current_template = 3;
            }
            else{
                this.current_template = +this.templateType?.id;
            }

                this.page1Errors.healthCareProvider = null;
                this.page1Errors.applicationFrame = null;
                this.page1Errors.templateType=null;
                this.page1Errors.dashboardName=null;
            }
            else if (this.current_step === 2) {
                this.page1Errors = {
                    healthCareProvider: null,
                    applicationFrame: null,
                    templateType:null,
                    dashboardName:null
                }
                // if( !this.pageData.page2 || (this.pageData.page2?.coreFunctions?.length == 0 && this.pageData.page2?.thirdPartyFunctions?.length == 0)) {
                //     this.page2Errors.modules = "Please select atleast one module !"
                //     return;
                // }

                if (!this.pageData.page2) {
                    this.page2Errors.modules = "Please select atleast one module !"
                    return;
                }
                else if (!this.pageData.page2 || (this.pageData.page2 && this.pageData.page2.coreFunctions && this.pageData.page2.coreFunctions.length === 0 && this.applicationFrame === 'mobex-health-hub')) {
                    this.page2Errors.modules = "Please select atleast one module !"
                    return;
                }
            }
            else if (this.current_step === 3) {
                this.page2Errors = {
                    modules: null
                }

                let err = 0;

                if(!this.pageData.page3.logo){
                    this.page3Errors = {
                        ...this.page3Errors,
                        logo: "Please upload company logo !"
                    }
                    err++;
                }

                if(this.applicationFrame != "family-connect"){
                    
                    if(!this.pageData.page3.colorScheme){
                        this.page3Errors = {
                            ...this.page3Errors,
                            color : "Please select any colour scheme !"
                        }
                        err++;
                    }
                    
                    if(!this.pageData.page3.background){
                        this.page3Errors = {
                            ...this.page3Errors,
                            background: "Please select background image !",
                        }
                        err++;
                    }

                }

                if(this.applicationFrame == "kiosk" && this.pageData.page3.showScreenSaver){
                    if (!this.pageData.page3.digitalSignage || this.pageData.page3.digitalSignage.length == 0){
                        this.page3Errors = {
                            ...this.page3Errors,
                            digitalSignage: "Please upload atleast one Image !",
                        }
                        err++;
                    } 
                    if(!this.pageData.page3.timeText){
                        this.page3Errors = {
                            ...this.page3Errors,
                            timeText: "This field is Required !"
                        }
                        err++;
                    }
                    if(!this.pageData.page3.timeoutText){
                        this.page3Errors = {
                            ...this.page3Errors,
                            timeoutText: "This field is Required !"
                        }
                        err++;
                    }
                }

                if(err){
                    return;
                }
               
                if (!this.pageData.page3) {

                    if(this.applicationFrame=='family-connect')
                    {
                        this.page3Errors = {
                        logo: "Please upload company logo !"
                    }

                    }
                    else
                    {
                        this.page3Errors = {
                        background: "Please select background image !",
                        logo: "Please upload company logo !"
                    }

                    }
                    
                    return;
                }
                if ((!this.pageData.page3.background && this.applicationFrame!='family-connect')) {
                    Object.assign(this.page3Errors, { background: "Please select background image !" });
                    return;
                }
                // if (!this.pageData.page3.colorScheme) {
                //     Object.assign(this.page3Errors, { colorScheme: "Please select color scheme !" });
                //     return;
                // }
                if (!this.pageData.page3.logo) {
                    Object.assign(this.page3Errors, { logo: "Please upload company logo !" });
                    return;
                }
            }
            else if (this.current_step === 4) {
                this.page3Errors = {
                    logo: null,
                    color: null,
                    background: null,
                    digitalSignage: null,
                    timeText: null,
                    timeoutText: null
                }
            }


            (this.current_step < this.max_step) ? this.current_step++ : this.current_step;


        },
        onClickBack() {
            (this.current_step > 1) ? this.current_step-- : this.current_step;
        },
        onClickFirst() {
            this.current_step = 1;
        },
        close() {
            this.openLauncherModal = false;
        },
        getStatusClass(section) {
            let _class = '';
            if (section === this.current_step) {
                _class = 'current';
            }
            else if (section < this.current_step) {
                _class = "completed";
            }
            else if (section > this.current_step) {
                _class = "upcoming";
            }
            return _class;
        },
        async updateTemplate(status, data) {
            let templateData, template = 1;

            if(this.applicationFrame == 'mobex-health-hub'){
                template = this.current_template == 1 ? 1 : this.current_template == 4 ? 2 : 3;
            }

            if(this.templateType==null)
            {
                templateData = {
                health_care_provider: this.healthCareProvider,
                application: this.applicationFrame,
                template: template,
                dashboardName:this.dashboardName,
                data: data,
                template_meta_id: 1,
                status,
                rssinfo: this.rssFeedsDetails ?? {},
                selected_language: this.selected_language,
            }
        }
            else {

                templateData = {
                health_care_provider: this.healthCareProvider,
                application: this.applicationFrame,
                template: template,
                selected_template:this.templateType,
                dashboardName:this.dashboardName,
                data: data,
                template_meta_id: 1,
                status,
                rssinfo: this.rssFeedsDetails ?? {},
                selected_language: this.selected_language,
            }
        
            }


            const endpoint = dashboardBuilder.updateTemplate(this.$route.query.id);
            const templateCreatedResponse = await this.$api.put(endpoint, templateData);
            if (!templateCreatedResponse.data.success) {
                throw templateCreatedResponse.data.error;
            }

            let temp = [];
            let temp1 = [];

            if(data?.page4?.template){
                Object.keys(data.page4.template).forEach(group => {
                    if (group === 'LI') {
                        Object.keys(data.page4.template[group]).forEach(li => {
                            if ( data.page4.template[group][li].elements.length && data.page4.template[group][li].elements[0].id != null) {
                                if(data.page4.template[group][li].elements[0].function_name == 'Dashboard'){
                                    temp1.push(data.page4.template[group][li].elements[0].id)
                                }
                                else{
                                    temp.push(data.page4.template[group][li].elements[0].id)
                                }
                            }
                        });
                    }
                    if (group === 'GSI') {
                        Object.keys(data.page4.template[group]).forEach(gsi => {
                            if ( data.page4.template[group][gsi].elements.length && data.page4.template[group][gsi].elements[0].id != null) {
                                if(data.page4.template[group][gsi].elements[0].function_type == "Dashboard"){
                                    temp1.push(data.page4.template[group][gsi].elements[0].id)
                                }
                                else{
                                    temp.push(data.page4.template[group][gsi].elements[0].id)
                                }
                            }
                        });
                    }
                });
            }

            const endpoint1 = memberProfile.changeActiveStatus();
            const res = await this.$api.post(endpoint1,{ids:temp, embeddedids:temp1})
            if (!res.data.success) {
                throw res.data.error;
            }

          
            let ids = [];

            data.page3.companyLogoImages.forEach((item)=>{
                ids.push(item.id)
            })

            data.page3.backgroundImages.forEach((item)=>{
                ids.push(item.id)
            })

            if(this.applicationFrame=='kiosk'){

                data.page3.digitalSignage.forEach((item)=>{
                    if(item.type=='dashboard_builder_digital_signage_image'){
                        ids.push(item.id)
                    }
                })

            }
            
            const endpoint2 = dashboardBuilder.changeActiveStatus();
            const result = await this.$api.post(endpoint2,{ids});

            if (!result.data.success) {
                throw result.data.error;
            }

            this.$toasted.success('Template updated successfully');


            if(this.applicationFrame != 'family-connect')
            {
                await this.sendDashboardBuildNotification();
            }

            this.loading = false;
    
            this.$router.push('/dashboard-builder');

        },
        getCurrentTemplate(option) {
            this.current_template = option;
        },
        async getDashboardRelatedClientsEmail() {
            try {
                const endpoint = clients.getClientsEmail(this.$route.query.id);
                const clientEmailsResponse = await this.$api.get(endpoint);

                const { data: { emails, message } } = clientEmailsResponse;
                if (emails.length != 0) {
                    this.clientEmails = emails;
                }
                else {
                    throw new Error(message);
                }

                return true;
            }
            catch (err) {
                this.$toasted.success(`This dashboard is not assigned to any member !`);
            }
        },
        async sendDashboardBuildNotification() {
            if (!this.clientEmails) {
                await this.getDashboardRelatedClientsEmail();
            }
            if (this.clientEmails != null && this.clientEmails.length) {

                try{
                    await dashboardBuildNotification(this.clientEmails,this.applicationFrame);
                }
                catch(err){
                    return this.$toasted.error("Failed to send notifications to members");
                }

                return true;
            }
            else {
                return false;
            }

        },

        async captureValues(event) {
            const { page1, page2, page3, page4, pageData } = event;
            if (page1) {
               
                const { healthCareProvider, applicationFrame , templateType,dashboardName} = page1;
                healthCareProvider ? this.healthCareProvider = healthCareProvider : false;
                applicationFrame ? this.applicationFrame = applicationFrame : false;
                this.templateType = templateType;
                this.dashboardName = dashboardName;
              


                if (this.applicationFrame == 'kiosk') {
                    this.current_template = 2;
                }
                else if(this.applicationFrame == 'star-kid'){
                    this.current_template = 5;
                }
                else if(this.applicationFrame == 'family-connect'){
                    this.current_template = 3;
                }
                else{
                    this.current_template = +this.templateType?.id;
                }

            }
            else if (page2) {
                const { coreFunctions, thirdPartyFunctions, genericFunctions, enableQR } = page2;
                this.pageData.page2 = {
                    coreFunctions: coreFunctions ? coreFunctions : this.pageData.page2?.coreFunctions,
                    thirdPartyFunctions: thirdPartyFunctions ? thirdPartyFunctions : this.pageData.page2?.thirdPartyFunctions,
                    genericFunctions: genericFunctions ? genericFunctions : this.pageData.page2?.genericFunctions,
                    enableQR: enableQR === undefined ? 0 : enableQR,
                }
            }
            else if (page3) {

                if (this.applicationFrame == 'kiosk'){

                    const { logo, colorScheme, background, companyLogoImages, backgroundImages, digitalSignage, timeText, timeoutText, showScreenSaver, selected_language } = page3;

                    this.pageData.page3 = {
                            logo: logo ? logo : this.pageData.page3?.logo,
                            colorScheme: colorScheme ? colorScheme : this.pageData.page3?.colorScheme,
                            background: background ? background : this.pageData.page3?.background,
                            companyLogoImages: companyLogoImages ? companyLogoImages : this.pageData.page3?.companyLogoImages ?? [],
                            backgroundImages: backgroundImages ? backgroundImages : this.pageData.page3?.backgroundImages ?? [],
                            digitalSignage: digitalSignage ? digitalSignage : this.pageData.page3?.digitalSignage,
                            timeText: timeText ? timeText : this.pageData.page3?.timeText,
                            timeoutText: timeoutText ?timeoutText: this.pageData.page3?.timeoutText,
                            showScreenSaver: showScreenSaver ?? this.pageData.page3?.showScreenSaver ?? 1,
                        }

                        if(selected_language){
                            this.selected_language = selected_language;
                        }

                    }
                else{
                    const { logo, colorScheme, background, companyLogoImages, backgroundImages } = page3;
                    this.pageData.page3 = {
                        logo: logo ? logo : this.pageData.page3?.logo,
                        colorScheme: colorScheme ? colorScheme : this.pageData.page3?.colorScheme,
                        background: background ? background : this.pageData.page3?.background,
                        companyLogoImages: companyLogoImages ? companyLogoImages : this.pageData.page3?.companyLogoImages ?? [],
                        backgroundImages: backgroundImages ? backgroundImages : this.pageData.page3?.backgroundImages ?? [],
                        showScreenSaver: 1,
                    }
                }
            }
            else if (page4) {
                const { template, templateStyles, templateData } = page4;
                this.pageData.page4 = {
                    template: template ? template : this.pageData.page4?.template,
                    templateStyles: templateStyles ? templateStyles : this.pageData.page4?.templateStyles,
                    templateData: templateData ? templateData : this.pageData.page4?.templateData,

                }
            }
            else if (pageData) {
                const { template, templateStyles, templateData } = pageData;
                this.pageData.page4 = {
                    template: template,
                    templateStyles: templateStyles,
                    templateData: templateData,

                }
            }
            // await this.getDashboardRelatedClientsEmail();
            this.dataFetched = true;
        },
        async saveDraft() {
            const data = {
                page2: this.pageData.page2,
                page3: this.pageData.page3
            }
            if (this.$route.query.id) {
                this.updateTemplate('draft', data);
            }
            else {
                this.createTemplate('draft', data);
            }
        },
        captureRssFeed(data) {
            this.rssFeedsDetails = {...data};
        },
        async completeDashboard() {
            const data = {
                page2: this.pageData.page2,
                page3: this.pageData.page3,
                page4: this.pageData.page4
            }

            let _coreFunctions;
            if (data.page2.coreFunctions) {
                _coreFunctions = data.page2.coreFunctions.map(el => {
                    const { id, function_name, function_type, active } = el;
                    return {
                        id,
                        function_name,
                        function_type,
                        active
                    };
                })
                Object.assign(data.page2, { coreFunctions: _coreFunctions });
            }

            let _thirdPartyFunctions;
            if (data.page2.thirdPartyFunctions) {
                _thirdPartyFunctions = data.page2.thirdPartyFunctions.map(el => {
                    const { id, function_name, function_type, image, active } = el;
                    return {
                        id,
                        function_name,
                        function_type,
                        image,
                        active
                    };
                })
                Object.assign(data.page2, { thirdPartyFunctions: _thirdPartyFunctions });
            }


            let _genericFunctions;
            if (data.page2.genericFunctions) {
                _genericFunctions = data.page2.genericFunctions.map(el => {
                    const { id, function_name, function_type, image, active } = el;
                    return {
                        id,
                        function_name,
                        function_type,
                        image,
                        active
                    };
                })
                Object.assign(data.page2, { genericFunctions: _genericFunctions });
            }

            data.page3 = {
                logo: data?.page3?.logo,
                colorScheme: data?.page3?.colorScheme,
                background: data?.page3?.background,
                companyLogoImages: data?.page3?.companyLogoImages,
                backgroundImages: data?.page3?.backgroundImages,
                digitalSignage:data?.page3?.digitalSignage,
                timeText:data?.page3?.timeText,
                timeoutText:data?.page3?.timeoutText,
                showScreenSaver: Number(data?.page3?.showScreenSaver) ?? 1,
            }

            this.loading = true;
            if (this.$route.query.id) {
                this.updateTemplate('completed', data);
            }
            else {
                this.createTemplate('completed', data);
            }

        },
        async createTemplate(status, data) {
            let templateData, template = 1 ;

            if(this.applicationFrame == 'mobex-health-hub'){
                template = this.current_template == 1 ? 1 : this.current_template == 4 ? 2 : 3;
            }

            if(this.templateType==null)
            {
                templateData = {
                health_care_provider: this.healthCareProvider,
                application: this.applicationFrame,
                template: template,
                data: data,
                template_meta_id: 1,
                dashboardName:this.dashboardName,
                status,
                rssinfo: this.rssFeedsDetails ?? {},
                selected_language: this.selected_language,
            }
        }
        else
        {
            templateData = {
                health_care_provider: this.healthCareProvider,
                application: this.applicationFrame,
                template: template,
                data: data,
                template_meta_id: 1,
                selected_template:this.templateType,
                dashboardName:this.dashboardName,
                status,
                rssinfo: this.rssFeedsDetails ?? {},
                selected_language: this.selected_language,
            }
        }
             

            const endpoint = dashboardBuilder.createTemplate();
            const templateCreatedResponse = await this.$api.post(endpoint, templateData);
            if (!templateCreatedResponse.data.success) {
                throw templateCreatedResponse.data.error;
            }

            let temp = [];
            let temp1 = [];

            if(data?.page4?.template){
                Object.keys(data.page4?.template).forEach(group => {
                    if (group === 'LI') {
                        Object.keys(data.page4.template[group]).forEach(li => {
                            if ( data.page4.template[group][li].elements.length && data.page4.template[group][li].elements[0].id != null) {
                                if(data.page4.template[group][li].elements[0].function_name == 'Dashboard'){
                                    temp1.push(data.page4.template[group][li].elements[0].id)
                                }
                                else{
                                    temp.push(data.page4.template[group][li].elements[0].id)
                                }
                            }
                        });
                    }
                    if (group === 'GSI') {
                        Object.keys(data.page4.template[group]).forEach(gsi => {
                            if ( data.page4.template[group][gsi].elements.length && data.page4.template[group][gsi].elements[0].id != null) {
                                if(data.page4.template[group][gsi].elements[0].function_name == 'Dashboard'){
                                    temp1.push(data.page4.template[group][gsi].elements[0].id)
                                }
                                else{
                                    temp.push(data.page4.template[group][gsi].elements[0].id)
                                }
                            }
                        });
                    }
                });
            }

            const endpoint1 = memberProfile.changeActiveStatus();
            const res = await this.$api.post(endpoint1,{ids:temp, embeddedids:temp1});
            if (!res.data.success) {
                throw res.data.error;
            }


            let ids = [];

            data.page3.companyLogoImages.forEach((item)=>{
                ids.push(item.id)
            })

            data.page3.backgroundImages.forEach((item)=>{
                ids.push(item.id)
            })

            if(this.applicationFrame=='kiosk'){
                data?.page3?.digitalSignage.forEach((item)=>{
                    if(item.type=='dashboard_builder_digital_signage_image'){
                        ids.push(item.id)
                    }
                })                
            }

            const endpoint2 = dashboardBuilder.changeActiveStatus();
            const result = await this.$api.post(endpoint2,{ids});

            if (!result.data.success) {
                throw result.data.error;
            }

            this.$toasted.success('Template saved successfully');

            this.loading = false;

            this.$router.push('/dashboard-builder');

        },
        async getLastTemplateId(){
            try{
                const lastidendpoint = dashboardBuilder.getLastTemplateId();
                const res = await this.$api.get(lastidendpoint);
                if (res.data.success) {
                    this.dashboardId = res.data.response.AUTO_INCREMENT;
                }
            }catch(err){
                console.error(err)
            }
        },
        async deleteUnSaved() {
            const endpoint1 = memberProfile.deleteUnSaved();
            const res = await this.$api.put(endpoint1);
            if (!res.data.success) {
                throw res.data.error;
            }
            
            const endpoint2 = dashboardBuilder.deleteUnSaved();
            const res1 = await this.$api.put(endpoint2);
            if (!res1.data.success) {
                throw res1.data.error;
            }
        }
    },
    async created() {

        window.scrollTo(0,0);

        await this.deleteUnSaved();

        if (this.$route.query.id) {
            await this.fetchDashboardData(this.$route.query.id);
        }

        if(!this.$route?.query?.id){
           await this.getLastTemplateId();
        }
        
        this.dataFetched = true;
        this.loading = false;
    },
};
</script>
  
<style>
.icon-div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 99;
}

.icon {
    font-size: 35px;
    font-weight: 800;
    border-radius: 50%;
}

.completed {
    border: 5px solid #5657be;
    background: #5657be;
    color: white;
}

.current {
    border: 5px solid #5657be;
    background: white;
    color: #5657be;
    border-radius: 50%;
}

.current-icon {
    border-radius: 50%;
    background: #5657be;
    font-size: 20px;
}

.upcoming {
    border: 5px solid lightgray;
    background: white;
}

.other-btn {
    background: #FFF;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: #5057C3;
    width: 200px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid 5057C3;
    font-weight: 500;
}

.save-btn {
    background: #5057C3;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: white;
    width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
}
</style>
