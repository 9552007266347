import { API } from 'aws-amplify';
import { sendActivityReminder, sendDashboardBuildNotification, sendDashboardChangeReminder, sendRssBuildNotification} from '../../graphql/mutations';

export const sendActivityReminderNotification = async(familyId, contactId) => {
    const { data, errors } = await API.graphql({ query: sendActivityReminder, variables: { familyId, contactId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const sendReminderDashboardChangeNotification = async(familyId, contactId) => {
    const { data, errors } = await API.graphql({ query: sendDashboardChangeReminder, variables: { familyId, contactId }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const dashboardBuildNotification = async( emails,application ) => {
    const { data, errors } = await API.graphql({ query: sendDashboardBuildNotification, variables: { emails,application }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const rssBuildNotification = async( emails ) => {
    const { data, errors } = await API.graphql({ query: sendRssBuildNotification, variables: { emails }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}
