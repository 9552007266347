<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1 class="px-5 py-3 page-heading"
                style="background: #F1F7FF; font-weight: bold; border-radius: 14px 14px 0 0;">Select Module For Application
            </h1>
            <div class="d-flex flex-wrap p-4" style="gap: .5em;">
                <ProfileFunctionCard v-for="coreFunction in coreFunctionList" :key="coreFunction.id"
                    :profileFunction="coreFunction" @onToggle="coreFunctionToggle($event)" />
            </div>
            <div class="d-flex justify-content-center" v-if="coreFunctionList.length">
                <hr style="width: 95%;">
            </div>
            <div class="d-grid p-4" v-if="genericModuleList.length">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <span class="app-launcher-label">Generic Modules</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: .5em;">
                    <ProfileFunctionCard v-for="genericFunction in genericModuleList" :key="genericFunction.id"
                        :profileFunction="genericFunction" @onToggle="genericFunctionToggle($event)"/>
                </div>

            </div>
            <div class="d-flex justify-content-center" v-if="genericModuleList.length">
                <hr style="width: 95%;">
            </div>
            <div class="d-grid p-4">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <span class="app-launcher-label">3rd Party app launcher</span>
                        <Toggle id="app-launcher" class="toggle-switch" labelClass="switch" v-model="openLauncherModal"
                            spanClass="slider" />
                    </div>
                    <LauncherModal v-if="openLauncherModal" :open="openLauncherModal" @close="close"
                        :coreFunctionList="thirdPartyApps" @onSaveThirdPartyApplication="onSaveThirdPartyApplication"  
                        @onDeleteThirdParty="onDeleteThirdParty" :showSaveSetting="showSaveSetting" :applicationFrame="applicationFrame" />
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: .5em;">
                    <ProfileFunctionCard v-for="thirdPartyApp in thirdPartyApps" :key="thirdPartyApp.id"
                        :profileFunction="thirdPartyApp" :coreFunctionList="thirdPartyApps"
                        @onToggle="thirdPartyFunctionToggle($event)" />
                </div>
            </div>
            <div v-if="this.applicationFrame === 'kiosk'">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%;">
                </div>
                <div class="d-grid p-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <span class="app-launcher-label">App Download Button</span>
                            <Toggle class="toggle-switch" labelClass="switch" v-model="appDownload"
                                spanClass="slider" @input="appDownloadButtonToggle"/>
                        </div>
                    </div>
                </div>
            </div>
            <span class="m-3" style="color: red; font-weight: bold;">{{ errors.modules }}</span>
            <div class="third-party-app-delete-modal">
                <AlertDeleteThirdPartyApp
                    v-if="showThirdPartyDeleteAlert"
                    :open="showThirdPartyDeleteAlert"
                    @cancel="showThirdPartyDeleteAlert = false;openLauncherModal = true"
                    @handleDelete="handleThirdPartyDelete">
                </AlertDeleteThirdPartyApp>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
import ProfileFunctionCard from '../../components/company/member-profiles/ProfileFunctionCard.vue';
import LauncherModal from '../../components/company/member-profiles/LauncherModal.vue';
import AlertDeleteThirdPartyApp from '../../components/company/member-profiles/DeleteThirdPartyAlert.vue';
import Loading from '@/components/general/loading/loading.vue';
import { memberProfile } from '@/util/apiRequests';

export default {
    name: "SelectModules",
    props: {
        errors: {
            type: Object
        },
        pageData: {
            type: Object
        },
        applicationFrame: {
            type: String
        },
        current_template: {
            type: Number
        }
    },
    components: {
        ProfileFunctionCard,
        AlertDeleteThirdPartyApp,
        LauncherModal,
        Loading
    },
    data() {
        return {
            coreFunctionList: [],
            genericModuleList: [],
            openLauncherModal: false,
            showSaveSetting: false,
            thirdPartyApps: [],
            showThirdPartyDeleteAlert: false,
            thirdPartyFunctionIdToDelete: null,
            appDownload: false,
            loading: true,
        };
    },
    methods: {
        async getCoreFunctions() {
            try {
    
                const endpoint = memberProfile.getFunction('core', this.applicationFrame);
                const fetchFunctionResponse = await this.$api.get(endpoint);
                if (!fetchFunctionResponse.data.success) {
                    throw fetchFunctionResponse.data.error;
                }
                let { data } = fetchFunctionResponse.data;
                this.coreFunctionList = data.map(el => {
                    delete el.created_at;
                    delete el.updated_at;
                    delete el.deleted;
                    return el;
                })
                this.coreFunctionList.forEach(el => el.active = el.by_default_selected ? 1 : 0);

                if(this.applicationFrame=='mobex-health-hub'){
                    let MHH_new_template_modules = ['Scheduling', 'Care Team', 'Games', 'Medication', 'Web Links', 'Content & Education', "Let's Connect" ];
                    if( this.current_template == 1){
                        this.coreFunctionList = this.coreFunctionList.filter(item => (item.function_name!='Games'));
                    }
                    else if(this.current_template == 4){
                        this.coreFunctionList = this.coreFunctionList.filter(item => MHH_new_template_modules.includes(item.function_name));
                    }
                    else{
                        this.coreFunctionList = this.coreFunctionList.filter(item => (item.function_name!='Medication') && MHH_new_template_modules.includes(item.function_name));
                    }
                }
                // Emiting default selected modules
                return true;
            } catch (e) {
                const errorMesage = e ? e : 'Failed to get tablet functions !';
                this.$toasted.error(errorMesage);
            }
        },
        async getGenericWidgets() {
            try {

                const endpoint = memberProfile.getFunction('generic', this.applicationFrame);
                const fetchFunctionResponse = await this.$api.get(endpoint);
                if (!fetchFunctionResponse.data.success) {
                    throw fetchFunctionResponse.data.error;
                }
                let { data } = fetchFunctionResponse.data;
                this.genericModuleList = data.map(el => {
                    delete el.created_at;
                    delete el.updated_at;
                    delete el.deleted;
                    return el;
                })
                this.genericModuleList.forEach(el => el.active = el.by_default_selected ? 1 : 0);
                
                this.genericModuleList.forEach((item) => {
                    if(item.function_name == 'Video Link'){
                        item.name = 'Video';
                    } 
                    else if(item.function_name == 'PDF Link'){
                        item.name = 'PDF';
                    } 
                    else if(item.function_name == 'Dashboard Page'){
                        item.name = '3 Layer Module';
                    }
                    else{
                        item.name = item.function_name;
                    }
                })

                if(this.applicationFrame=='mobex-health-hub'){
                    if(this.current_template == 1){
                        this.genericModuleList = this.genericModuleList.filter(el => !['Dashboard','Call Button'].includes(el.function_name));
                    }
                    else if(this.current_template == 4){
                        this.genericModuleList = this.genericModuleList.filter(el => el.function_name=='Dashboard');
                    }
                    else{
                        this.genericModuleList = this.genericModuleList.filter(el => el.function_name=='Dashboard' || el.function_name=='Call Button');
                    }
                }
                return true;
            } catch (e) {
                const errorMesage = e ? e : 'Failed to get tablet functions !';
                this.$toasted.error(errorMesage);
            }
        },
        coreFunctionToggle(event) {
            this.coreFunctionList = this.coreFunctionList.map(el => {
                if (event.profileFunction.id === el.id) {
                    el.active = event.value ? 1 : 0;
                }
                return el;
            })
            let _coreFunctionList = this.coreFunctionList.filter(el => el.active);
            this.$emit('captureValues', { 'page2': { 'coreFunctions': _coreFunctionList } });
        },
        genericFunctionToggle(event) {
            this.genericModuleList = this.genericModuleList.map(el => {
                if (event.profileFunction.id === el.id) {
                    el.active = event.value ? 1 : 0;
                }
                return el;
            })
            let _genericFunctionList = this.genericModuleList.filter(el => el.active);
            this.$emit('captureValues', { 'page2': { 'genericFunctions': _genericFunctionList } });
        },
        onSaveThirdPartyApplication(selectedThirdPartyApplications) {
            this.thirdPartyApps = selectedThirdPartyApplications.filter(el => el.active == 1).map(el => {
                delete el.created_at;
                delete el.deleted;
                delete el.updated_at;
                return el;
            })
            this.thirdPartyApps
            this.$emit('captureValues', { 'page2': { 'thirdPartyFunctions': this.thirdPartyApps } });
        },
        thirdPartyFunctionToggle(event) {
            let index = this.thirdPartyApps.findIndex(el => el.id == event.profileFunction.id);
            this.thirdPartyApps.splice(index, 1);
            this.$emit('captureValues', { 'page2': { 'thirdPartyFunctions': this.thirdPartyApps } });
        },
        onDeleteThirdParty(thirdPartyFunctionId) {
                this.openLauncherModal = false;
                this.showThirdPartyDeleteAlert = true;
                this.thirdPartyFunctionIdToDelete = thirdPartyFunctionId;
            },
        close() {
            this.openLauncherModal = false;
        },
        async handleThirdPartyDelete() {
            try {
                this.showThirdPartyDeleteAlert = false;
                this.showLoader = true;

                // HTTP call for delete third party application
                const endpoint = memberProfile.deleteFunction(this.thirdPartyFunctionIdToDelete);
                const deleteThirdPartyAppResponse = await this.$api.delete(endpoint);
                if (!deleteThirdPartyAppResponse.data.success) {
                    throw deleteThirdPartyAppResponse.data.Message;
                }
    
                this.$toasted.success('Application Deleted successfully !');

            } catch (e) {
                const errorMessage = e ? e : 'Failed to delete application !';
                this.$toasted.error(errorMessage);
            } finally {
                this.showLoader = false;
                this.openLauncherModal = true;
            }
        },
        appDownloadButtonToggle() {
            this.$emit('captureValues', { 'page2': { 'enableQR': this.appDownload === true ? 1 : 0}});
            },
    },
    watch: {
    },
    async created() {
        window.scrollTo(0,0);
        this.appDownload = this.pageData?.page2?.enableQR === 1 ? true : false;
        await this.getCoreFunctions();
        await this.getGenericWidgets();

        if (this.pageData.page2 && this.pageData.page2.coreFunctions && this.pageData.page2.genericFunctions) {
            if (this.pageData.page2.thirdPartyFunctions) {
                this.thirdPartyApps = this.pageData.page2.thirdPartyFunctions ? this.pageData.page2.thirdPartyFunctions : [];
            }
            if (this.pageData.page2.coreFunctions) {
                let coreFunctionIds = this.pageData.page2.coreFunctions.map( el => el.id );
                    this.coreFunctionList = this.coreFunctionList.map( el => {
                        if( coreFunctionIds.includes( el.id ) ) {
                            el.active = 1;
                        }
                        else {
                            el.active = 0;
                        }
                        return el;
                    } )
            }
            if (this.pageData.page2.genericFunctions) {
                if(this.applicationFrame=='mobex-health-hub'){
                    if(this.current_template == 1){
                        this.pageData.page2.genericFunctions = this.pageData.page2.genericFunctions.filter(el => el.function_name!='Dashboard');
                    }
                    else if(this.current_template == 4){
                        this.pageData.page2.genericFunctions = this.pageData.page2.genericFunctions.filter(el => el.function_name=='Dashboard');
                    }
                    else{
                        this.pageData.page2.genericFunctions = this.pageData.page2.genericFunctions.filter(el => el.function_name=='Dashboard' || el.function_name=='Call Button');
                    }
                }

                let genericFunctionIds = this.pageData.page2.genericFunctions.map(el => el.id);
                this.genericModuleList = this.genericModuleList.map(el => {
                    if (genericFunctionIds.includes(el.id)) {
                        el.active = 1;
                    }
                    else {
                        el.active = 0;
                    }
                    return el;
                })
            }
        }
        else {
            this.$emit('captureValues', { 'page2': { 'coreFunctions': this.coreFunctionList.filter(el => el.by_default_selected)}});
            this.$emit('captureValues', { 'page2': { 'genericFunctions': this.genericModuleList.filter(el => el.by_default_selected)}});
           
        }
        this.loading = false;
    },
};
</script>