<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal" style="overflow:scroll">
        <div class="modal-list-third-party-app" v-if="showAppListing">
            <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                <span id="target" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                    role="button" @click="cancel">close</span>
            </ejs-tooltip>
            <div class="preview-body">
                <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer">
                        <div :style="pageData.page4.templateStyles.bgImageContainer" style="background-color: #E2E2E2;">
                            <!-- <img class="" :style="pageData.page4.templateStyles.bgImage" :src="pageData.page4.templateStyles.bgImageUrl" /> -->
                        </div>
                        <div
                            style="position: absolute; top: 0; left: 0; width:100%;height:10rem;background: #484CBE;border-radius: 0 0 5rem 5rem;">
                            <div
                                style="height:4.3rem; width: 100%; left: 0px;  text-align: center;display: flex;  align-items: center; ">
                                <div class="d-flex w-10 justify-content-start">
                                    <div class="d-flex justify-content-start" style="margin-left: 15px;">
                                        <img src="@/assets/px/drag-and-drop/menu-icon.svg" alt=""
                                            style="width:30px; height:30px;filter: invert(1) grayscale(100%) brightness(200%)">
                                    </div>
                                </div>
                                <div class="w-80 d-flex mx-2" style="color:white;font-weight: 600;font-size: 25px;">
                                    Dashboard</div>
                                <div class="d-flex w-10" style="margin-right: 2rem;">
                                    <img src="@/assets/px/header-icons-images/notification.svg" alt=""
                                        style="height: 25px;width:25px;filter: invert(1) grayscale(100%) brightness(200%);" />
                                </div>
                            </div>
                            <div
                                style="font-weight: 700;color: white;display: flex;justify-content: center;font-size: 25px;margin-top: 10px;align-items: center;gap: 10px;">
                                Hello, Anni Sara
                                <img src="@/assets/px/header-icons-images/hello.svg" alt=""
                                    style="width: 40px;height: 40px;" />
                            </div>
                        </div>
                        <div class='t-m-c'
                            :style="pageData.page4.templateStyles.templateMainContainer + 'margin-top: 5rem'">
                            <div class='u-l-c' :style="pageData.page4.templateStyles.upperLayoutContainer">
                                <div v-for="(item) in Object.entries(pageData.page4.template.LI)"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + item[1].style + 'height: 10rem !important; width: 11rem !important; border-radius:2.5rem !important;height: 10rem;width: 11rem; border-radius: 2.5rem;'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list"
                                        :list="pageData.page4.template['LI'][item[0]].elements">
                                        <div :class="value.class" class='module'
                                            v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                            :key="value.name">
                                            <div class='widget-img'
                                                :style="(value.function_type == 'generic' && pageData.page4.template['LI'][item[0]].title.length == 0) ? 'height: 100%' : 'height: 7rem'">
                                                <img :src="value.image" alt="">
                                            </div>
                                            <div class="widget-title" style="">
                                                <p
                                                    v-if="value.function_type == 'generic' && pageData.page4.template['LI'][item[0]].title">
                                                    {{ pageData.page4.template['LI'][item[0]].title }}</p>
                                                <p v-if="value.function_type == '3rdParty'">{{ value.function_name }}</p>
                                            </div>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div v-if="rssActive" class='g-l-c'
                                style="z-index: 20;background-color: rgb(255, 255, 255);border-radius: 2.5rem;color: rgb(81, 85, 195);margin-top: 2rem;">
                                <div v-for="(item) in Object.entries(pageData.page4.template.GSI)"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + 'min-height:14rem;position: unset !important; margin: auto;border-radius:2.5rem'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list"
                                        :list="pageData.page4.template['GSI'][item[0]].elements" style="min-height:14rem">
                                        <div :class="value.class"
                                            v-for="value in pageData.page4.template['GSI'][item[0]].elements"
                                            :key="value.name"
                                            style="position: relative; text-align: -webkit-center; height: 100%;">
                                            <img src="@/assets/photos/RSS Feed.png" alt="" style="border-radius: 2.5rem;">
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import draggable from "vuedraggable";
import { dashboardBuilder } from '@/util/apiRequests';

export default {
    name: 'PreviewModalStarKid',
    components: { NoButtonModal, draggable },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        selected: {
            type: String,

        },
        pageData: {
            type: Object,
            required: true
        },
        applicationFrame: {
            type: String,
            required: true
        }

    },
    data() {
        return {
            showAppListing: true,
            showLoader: false,
            rssActive: false,
        };
    },
    methods: {
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;
            this.$emit('close');
        },
    },
    async created() {

        if (this.pageData?.page2?.genericFunctions) {
            this.rssActive = this.pageData?.page2?.genericFunctions?.filter((item) => item.function_name == 'RSS Feed').length != 0;
        }

    }
};
</script>
<style scoped>
.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.preview-body {
    position: relative;
    overflow-y: scroll;
}

.module {
    position: relative;
    text-align: -webkit-center;
    height: 100%;
}

.widget-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-img>img {
    max-height: 80%;
    max-width: 80%;
}

.widget-title>p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: medium;
    text-align: center;
}
</style>