<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal" style="overflow:scroll">

        <div class="modal-list-third-party-app" v-if="showAppListing">
            
                <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                    <span id="target" class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                        role="button" @click="cancel">close</span>
                </ejs-tooltip>
           
            <div class="preview-body">
                <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer">
                        <div :style="pageData.page4.templateStyles.bgImageContainer">
                            <img class="" :style="pageData.page4.templateStyles.bgImage" :src="pageData.page4.templateStyles.bgImageUrl" />
                        </div>
                        <div style="position: absolute; top: 15px; left: 35px; right: 35px;">
                            <div
                                style="height:4.3rem; width: 100%; background-color: #FFFFFFD9;  left: 0px; text-align: center; border-radius: 0.5rem; display: flex;  align-items: center; ">

                                <div class="d-flex w-10 justify-content-start">
                                    <div class="d-flex justify-content-start" style="margin-left: 15px;">
                                        <img src="@/assets/px/drag-and-drop/menu-icon.svg" alt=""
                                            style="width:30px; height:30px;">

                                    </div>
                                </div>
                                <div class="d-flex w-80 justify-content-left" style="padding-left: 10px;">
                                    <img :src="logo" class="d-flex" alt="" style=" max-height: 30px; max-width: 150px;">
                                </div>
                                <div class="d-flex w-10"></div>
                            </div>
                        </div>
                        <div class='t-m-c' :style="pageData.page4.templateStyles.templateMainContainer">

                            <div class='u-l-c' :style="pageData.page4.templateStyles.upperLayoutContainer">
                                <div v-for="(item) in Object.entries(pageData.page4.template.LI)"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + item[1].style + 'height: 10rem !important; width: 11rem !important; border-radius:2.5rem !important;'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list" :list="pageData.page4.template['LI'][item[0]].elements">
                                        <div :class="value.class" class='module' v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                            :key="value.name">
                                            <div class='widget-img' :style="(value.function_type=='generic' && pageData.page4.template['LI'][item[0]].title.length==0) ? 'height: 100%': 'height: 7rem'">
                                                <img v-if="value.function_name=='Lumeca' && pageData.page4.template['LI'][item[0]].title.length==0" :src="value.image" alt="" style="margin: auto;width:7rem">
                                                <img v-else :src="value.image" alt="" > 
                                            </div>
                                            <div class="widget-title" style="">
                                                <p v-if="value.function_type == 'generic' && pageData.page4.template['LI'][item[0]].title">{{ pageData.page4.template['LI'][item[0]].title }}</p>
                                                <p v-if="value.function_type == '3rdParty'">{{value.function_name }}</p>
                                            </div>
                                        </div>
                                    </draggable>
                                </div>
                            </div>

                            <div v-if="rssActive" class='g-l-c' style="z-index: 20;background-color: rgb(255, 255, 255);border-radius: 2.5rem;color: rgb(81, 85, 195);margin-top: 2rem;">
                                <div v-for="(item) in Object.entries(pageData.page4.template.GSI)" 
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + 'min-height:14rem;position: unset !important; margin: auto;border-radius:2.5rem'"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list" :list="pageData.page4.template['GSI'][item[0]].elements"
                                     style="min-height:14rem">
                                        <div :class="value.class" v-for="value in pageData.page4.template['GSI'][item[0]].elements"
                                            :key="value.name" style="position: relative; text-align: -webkit-center; height: 100%;">
                                            <img src="@/assets/photos/RSS Feed.png" alt="" style="border-radius: 2.5rem;">
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import draggable from "vuedraggable";
import { dashboardBuilder } from '@/util/apiRequests';

export default {
    name: 'PreviewModalKiosk',
    components: { NoButtonModal, draggable },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        selected: {
            type: String,

        },
        pageData: {
            type: Object,
            required: true
        },
        applicationFrame:{
            type:String,
            required:true
        }

    },
    data() {
        return {
            showAppListing: true,
            showLoader: false,
            logo: null,
            rssActive: false,
        };
    },
    methods: {
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;

            this.$emit('close');
        },
    },
   async created() {

        if (this.pageData?.page3?.logo?.fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [this.pageData?.page3?.logo?.fileId]
            }
            let response = await this.$api.post(endpoint, requestData);
            this.logo = response?.data?.data[0].image;

        }

        if(this.pageData?.page2?.genericFunctions){
            this.rssActive = this.pageData?.page2?.genericFunctions?.filter((item)=> item.function_name == 'RSS Feed').length != 0;
        }

    }
};
</script>
<style scoped>


.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.img-loader-main {
    min-height: 30px;
}

.img-loader {
    height: .9em;
    width: .9em;
    font-weight: 600;
}

.img-loader-text {
    font-size: .8em;
    font-weight: 600;
    color: var(--base-theme-color);
}

/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}



.preview-body{
    position: relative;
    overflow-y: scroll;
}
.module{
    position: relative; 
    text-align: -webkit-center; 
    height: 100%;
}
.widget-img{
    display: flex; 
    align-items: center; 
    justify-content: center;
}
.widget-img>img{
    max-height: 80%;
    max-width: 80%;
}
.widget-title>p{
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: medium;
}
</style>