<template>
    <div class="bg-white w-100 rounded-4">
        <div class="d-flex w-100 justify-content-start py-4" v-if="this.applicationFrame == 'kiosk'">
            <div @click="handletemplateType(templateTypes[1])" class="d-grid w-25 justify-content-center" style="
                    border-radius: 30px;
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                    margin-inline: 20px;
                    cursor: pointer;
                ">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Mobex CDE App'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Mobex CDE App'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-2">
                    <img src="@/assets/px/drag-and-drop/dashboard2.png" alt="" class="img" />
                </div>
                <div class="text-center py-4">
                    <span>Mobex CDE App</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-100 justify-content-start py-4" v-if="this.applicationFrame == 'mobex-health-hub'">
            <div @click="handletemplateType(templateTypes[0])" class="d-grid w-35 justify-content-center" style="
                    border-radius: 30px;
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                    margin-inline: 20px;
                    cursor: pointer;
                ">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Mobex Health Hub'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Mobex Health Hub'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-4 mx-2">
                    <img src="@/assets/px/drag-and-drop/frame4.png" alt="" class="l-img" />
                </div>
                <div class="text-center py-4">
                    <span>Mobex Health Hub</span>
                </div>
            </div>

            <div @click="handletemplateType(templateTypes[3])" class="d-grid w-35 justify-content-center" style="
                    border-radius: 30px;
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                    margin-inline: 20px;
                    cursor: pointer;
                ">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Mobex Health Hub FC'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Mobex Health Hub FC'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-4 mx-2">
                    <img src="@/assets/px/drag-and-drop/dasboardMHH2.png" alt="" class="l-img" />
                </div>
                <div class="text-center py-4">
                    <span>MHH New</span>
                </div>
            </div>

            <div @click="handletemplateType(templateTypes[5])" class="d-grid w-35 justify-content-center" style="
            border-radius: 30px;
            background: #fff;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
            margin-inline: 20px;
            cursor: pointer;">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Mobex Health Hub Call Button'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Mobex Health Hub Call Button'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-4 mx-2">
                    <img src="@/assets/px/drag-and-drop/dasboardMHH3.png" alt="" class="l-img" />
                </div>
                <div class="text-center py-4">
                    <span>MHH Call Button</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-100 justify-content-start py-4" v-if="this.applicationFrame == 'family-connect'">
            <div @click="handletemplateType(templateTypes[2])" class="d-grid w-35 justify-content-center" style="
                    border-radius: 30px;
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                    margin-inline: 20px;
                    cursor: pointer;
                ">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Family Connect'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Family Connect'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-4 mx-2">
                    <img src="@/assets/px/drag-and-drop/frame5.png" alt="" class="l-img" />
                </div>
                <div class="text-center py-4">
                    <span>Family Connect</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-100 justify-content-start py-4" v-if="this.applicationFrame == 'star-kid'">
            <div @click="handletemplateType(templateTypes[4])" class="d-grid w-25 justify-content-center" style="
                    border-radius: 30px;
                    background: #fff;
                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                    margin-inline: 20px;
                    cursor: pointer;
                ">
                <div class="d-flex justify-content-center my-4">
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData == null || templateTypeData.name != 'Star Kid'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        radio_button_unchecked
                    </span>
                    <span class="material-symbols-outlined"
                        v-if="templateTypeData != null && templateTypeData.name == 'Star Kid'"
                        style="font-weight: bold; font-size: 30px; color: #5158c3">
                        check_circle
                    </span>
                </div>
                <div class="d-flex justify-content-center my-2">
                    <img src="@/assets/px/drag-and-drop/starkid.png" alt="" class="img" />
                </div>
                <div class="text-center py-4">
                    <span>Star Kid App</span>
                </div>
            </div>
        </div>
        <span v-if="errors.templateType" class="px-4 my-4" style="color: red; font-weight: bold">{{ errors.templateType}}</span>

        <div class="px-4 mt-4">
            <h1>Enter Dashboard Name*</h1>
        </div>

        <Input type="text" name="Dashboard Name" class="field-container px-4 mb-4" style="max-width: 30rem"
            input_style="text-transform: capitalize;" v-model="dashboardname" inputClass="input-add-new-client"
            labelClass="class-label" @input="handleDashboardName" :required="true" />
        <span v-if="errors.dashboardName" class="px-4 my-4" style="color: red; font-weight: bold">{{
            errors.dashboardName }}</span>
    </div>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
export default {
    name: 'TemplateDetails',
    components: { NoButtonModal },
    props: {
        errors: {
            type: Object,
        },
        pageData: {
            type: Object,
        },
        templateType: {
            type: Object,
        },
        applicationFrame: {
            type: String,
        },
        dashboardName: {
            type: String,
        },
    },

    data() {
        return {
            templateTypes: [
                { id: '1', name: 'Mobex Health Hub' },
                { id: '2', name: 'Mobex CDE App' },
                { id: '3', name: 'Family Connect' },
                { id: '4', name: 'Mobex Health Hub FC' },
                { id: '5', name: 'Star Kid' },
                { id: '6', name: 'Mobex Health Hub Call Button' },
            ],
            templateTypeData: null,
            dashboardname: null,
        };
    },
    methods: {
        handletemplateType(e) {
            this.templateTypeData = e;
            this.errors.templateType = null;
            this.$emit('capturetemplateName', { page1: { templateType: e, dashboardName: this.dashboardname } });
        },
        handleDashboardName(e) {
            this.errors.dashboardName = null;
            this.$emit('capturetemplateName', {
                page1: { dashboardName: this.dashboardname, templateType: this.templateTypeData },
            });
            // if (this.dashboardname == null) {
            //     this.$toasted.error('Please enter Dashboard name!');
            // } else {
            //     this.$emit('close');
            // }
        },
    },
    created() {
        this.templateTypeData = this.templateType;
        this.dashboardname = this.dashboardName;
    },
};
</script>

<style scoped>
@media only screen and (max-width: 2000px) {
    .img {
        width: 200px;
        height: 350px;
    }

    .l-img {
        width: 320px;
        height: 230px;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {
    .img {
        width: 150px;
        height: 250px;
    }

    .l-img {
        width: 280px;
        height: 200px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .img {
        width: 130px;
        height: 230px;
    }

    .l-img {
        width: 230px;
        height: 180px;
    }
}

@media only screen and (max-width: 1200px) {
    .img {
        width: 100px;
        height: 200px;
    }

    .l-img {
        width: 180px;
        height: 100px;
    }
}
</style>
