<template>
    <div class="page-body rss-feed-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <div class="w-30 d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header">Link RSS Feed</span>
                    </div>
                </div>

                <div v-if="!loading">
                    <div class="d-flex px-5 w-80 align-items-center pt-2">
                        <label style="font-size:1rem;" for="sorting">
                            Use External Link
                        </label>
                        <Toggle id="sorting" class="toggle-switch" :labelClass="'switch'" :spanClass="'slider'"
                            v-model="rssDetails.isExternalUrl" @input="handleToggle($event)" />
                    </div>

                    <div class="m-4 py-4" style="min-height: 10rem;">
                        <div v-if="showExternalLink" class="d-flex flex-column" style="gap:20px">
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="feedtitle">
                                    Feed Title*
                                </label>
                                <input class="w-70" type="text" name="feedtitle" id="feedtitle"
                                    placeholder="Enter Feed Title" v-model="rssDetails.feedtitle" />
                            </div>
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="url">
                                    Feed URL*
                                </label>
                                <input class="w-70" type="text" name="url" id="url" placeholder="Enter Feed URL"
                                    v-model="rssDetails.url" />
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="rss">
                                Select Feed*
                            </label>
                            <Multiselect id="rss" track-by="id" label="feedtitle" class="w-70"
                                placeholder="Search item..." v-model="selected_rss" :options="rss_list"
                                :searchable="true" :allow-empty="false" :show-labels="false" />
                        </div>
                    </div>

                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="saveRssFeed">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 25rem;">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>

        </NoButtonModal>
    </div>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import { rssFeeds } from '@/util/apiRequests';

export default {
    name: 'RssFeedEditModal',
    components: { NoButtonModal },
    data() {
        return {
            loading: true,
            showExternalLink: false,
            selected_rss: '',
            rssDetails: {
                feedtitle: '',
                url: '',
                isExternalUrl: false,
            },
            rss_list: [],

        };
    },
    props: {
        rssFeedsDetails: {
            type: Object,
        },
    },
    methods: {
        async getRssList() {
            try {
                let list = await this.$api.get(rssFeeds.getRssList());
                let data = list.data.info;
                this.rss_list = data;
                if (!this.rssDetails.isExternalUrl && this.rssDetails?.id) {
                    this.selected_rss = this.rss_list.filter((item) => item.id == this.rssDetails?.id);
                }
            }
            catch (err) {
                this.$toasted.error("Failed to fetched RSS Items");
            }
        },
        saveRssFeed() {
            if (this.rssDetails.isExternalUrl && (this.rssDetails.url == '' || this.rssDetails.feedtitle == '')) {
                return this.$toasted.error('Please fill all required fields');
            }
            if (!this.rssDetails.isExternalUrl && this.selected_rss == '') {
                return this.$toasted.error('Please select any feed');
            }
            if (!this.rssDetails.isExternalUrl) {
                this.rssDetails = {
                    id: this.selected_rss.id,
                    isExternalUrl: false,
                }
            }
            else {
                this.rssDetails = {
                    feedtitle: this.rssDetails.feedtitle,
                    url: this.rssDetails.url,
                    isExternalUrl: true,
                }
            }
            this.$emit('captureRssFeed', this.rssDetails);
            this.$emit('cancel');
        },
        handleToggle(e) {
            this.showExternalLink = e;
        }
    },
    async created() {
        if (this.rssFeedsDetails && Object.entries(this.rssFeedsDetails).length) {
            this.rssDetails = {
                ...this.rssFeedsDetails,
                isExternalUrl: this.rssFeedsDetails.isExternalUrl ? true : false,
            };
            this.showExternalLink = this.rssDetails.isExternalUrl;
        }
        await this.getRssList();
        this.loading = false;
    },
};
</script>

<style scoped>
.modal-content-header {
    font-size: 18px;
    color: #2E3343;
    margin: 0 0 20px;
    background-color: #F1F7FF;
}

.btn-save {
    border-radius: 10px;
    background: #5155c3 !important;
    box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
}

button.no-bg:hover {
    background: none;
}

.btn-cancel,
.btn-cancel:hover {
    border-radius: 10px;
    border: 2px solid #ac2734;
}
</style>

<style>
.rss-feed-modal .modal__content {
    padding: 0;
    border-radius: 10px;
    min-height: fit-content;
}

.rss-feed-modal .class-label {
    color: #666c6d;
    font-size: 1rem;
    font-weight: 600;
}
</style>
